<template>
  <div>
    <button class="btn-save" :class="{ 'is-save': isSave }" v-if="userIsLogged" @click="toggleSave(name)">
      <template v-if="isSave">
        <p>{{ $t('generic.saved') }}</p>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.453"
          height="19.143" viewBox="0 0 12.453 19.143">
          <defs>
            <clipPath id="a">
              <rect width="12.453" height="19.143" fill="#338545" />
            </clipPath>
          </defs>
          <g clip-path="url(#a)">
            <path
              d="M6.23,14.795a3.131,3.131,0,0,1-.206.27q-1.8,1.809-3.607,3.615a1.374,1.374,0,0,1-1.911.14A1.318,1.318,0,0,1,0,17.764Q0,9.923,0,2.083A2.071,2.071,0,0,1,2.1,0C3.446,0,4.794,0,6.141,0c1.391,0,2.781,0,4.172,0a2.078,2.078,0,0,1,2.119,1.779,2.252,2.252,0,0,1,.02.388q0,7.754,0,15.508a1.388,1.388,0,0,1-2.417,1Q8.231,16.873,6.43,15.063a2.81,2.81,0,0,1-.2-.269"
              transform="translate(0 0)" fill="#338545" />
          </g>
        </svg>
      </template>
      <template v-else>
        <p>{{ $t('generic.save') }}</p>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.453"
          height="19.142" viewBox="0 0 12.453 19.142">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_2194" data-name="Rectangle 2194" width="12.453" height="19.142" fill="#fff" />
            </clipPath>
          </defs>
          <g id="Group_1864" data-name="Group 1864" clip-path="url(#clip-path)">
            <path id="Path_2920" data-name="Path 2920"
              d="M6.23,14.794a3.121,3.121,0,0,1-.206.27q-1.8,1.809-3.607,3.614a1.374,1.374,0,0,1-1.911.14A1.318,1.318,0,0,1,0,17.763Q0,9.923,0,2.083A2.071,2.071,0,0,1,2.1,0C3.446,0,4.793,0,6.141,0c1.391,0,2.781,0,4.172,0a2.078,2.078,0,0,1,2.119,1.779,2.247,2.247,0,0,1,.02.388q0,7.754,0,15.507a1.388,1.388,0,0,1-2.416,1Q8.231,16.873,6.43,15.063a2.821,2.821,0,0,1-.2-.269m4.841,2.959v-.237q0-7.669,0-15.337a.7.7,0,0,0-.776-.782q-4.065-.005-8.129,0a.708.708,0,0,0-.782.8q0,7.658,0,15.315c0,.061.007.121.011.182a.506.506,0,0,0,.195-.155q1.787-1.794,3.577-3.585a1.388,1.388,0,0,1,2.117-.007Q8.59,15.254,9.9,16.565c.377.378.751.759,1.174,1.187"
              transform="translate(0 0)" fill="#fff" />
            <path id="Path_2921" data-name="Path 2921"
              d="M61.966,80.124c0-.456,0-.887,0-1.318a.694.694,0,1,1,1.382.006c0,.425,0,.851,0,1.312h.235c.375,0,.749,0,1.124,0a.7.7,0,0,1,.716.684.678.678,0,0,1-.688.706c-.452.015-.905,0-1.387,0,0,.466.008.911-.005,1.355a.893.893,0,0,1-.111.407.675.675,0,0,1-.772.293.7.7,0,0,1-.493-.664c0-.454,0-.907,0-1.388H60.916c-.122,0-.245,0-.367-.007a.7.7,0,0,1,.042-1.39c.446-.007.893,0,1.374,0"
              transform="translate(-56.43 -73.536)" fill="#fff" />
          </g>
        </svg>
      </template>
    </button>
    <button class="btn-save" v-else @click="alertLogin()">
      <p>{{ $t('generic.save') }}</p>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.453" height="19.142"
        viewBox="0 0 12.453 19.142">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_2194" data-name="Rectangle 2194" width="12.453" height="19.142" fill="#fff" />
          </clipPath>
        </defs>
        <g id="Group_1864" data-name="Group 1864" clip-path="url(#clip-path)">
          <path id="Path_2920" data-name="Path 2920"
            d="M6.23,14.794a3.121,3.121,0,0,1-.206.27q-1.8,1.809-3.607,3.614a1.374,1.374,0,0,1-1.911.14A1.318,1.318,0,0,1,0,17.763Q0,9.923,0,2.083A2.071,2.071,0,0,1,2.1,0C3.446,0,4.793,0,6.141,0c1.391,0,2.781,0,4.172,0a2.078,2.078,0,0,1,2.119,1.779,2.247,2.247,0,0,1,.02.388q0,7.754,0,15.507a1.388,1.388,0,0,1-2.416,1Q8.231,16.873,6.43,15.063a2.821,2.821,0,0,1-.2-.269m4.841,2.959v-.237q0-7.669,0-15.337a.7.7,0,0,0-.776-.782q-4.065-.005-8.129,0a.708.708,0,0,0-.782.8q0,7.658,0,15.315c0,.061.007.121.011.182a.506.506,0,0,0,.195-.155q1.787-1.794,3.577-3.585a1.388,1.388,0,0,1,2.117-.007Q8.59,15.254,9.9,16.565c.377.378.751.759,1.174,1.187"
            transform="translate(0 0)" fill="#fff" />
          <path id="Path_2921" data-name="Path 2921"
            d="M61.966,80.124c0-.456,0-.887,0-1.318a.694.694,0,1,1,1.382.006c0,.425,0,.851,0,1.312h.235c.375,0,.749,0,1.124,0a.7.7,0,0,1,.716.684.678.678,0,0,1-.688.706c-.452.015-.905,0-1.387,0,0,.466.008.911-.005,1.355a.893.893,0,0,1-.111.407.675.675,0,0,1-.772.293.7.7,0,0,1-.493-.664c0-.454,0-.907,0-1.388H60.916c-.122,0-.245,0-.367-.007a.7.7,0,0,1,.042-1.39c.446-.007.893,0,1.374,0"
            transform="translate(-56.43 -73.536)" fill="#fff" />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isSave: false,
      title: '',
    }
  },
  props: ['name'],
  computed: {
    ...mapGetters("User", ['userIsLogged']),
    ...mapGetters({
      user: 'User/user',
      userFavorite: 'User/userFavorite',
      publicities: "Publicity/publicities",
    })
  },
  methods: {
    ...mapActions({
      checkFavorite: 'User/checkFavorite',
      saveFavorite: 'User/saveFavorite',
      deleteFavorite: 'User/deleteFavorite',
    }),
    toggleSave(title) {
      console.log("Title passed to toggleSave:", title);
      let objFavorite = { ...this.userFavorite };
      this.checkFavorite(objFavorite).then(resp => {
        if (!resp.data.success) {
          let objSave = { ...objFavorite, title: title };
          this.saveFavorite(objSave).then(() => {
            this.isSave = true;
            this.showSuccessAlert('Produto adicionado aos favoritos'); // Mensagem para adição
          }).catch((error) => {
            console.log('SAVE FAVORITE', error);
            this.showErrorAlert('Erro ao salvar favorito');
          });
        } else {
          let objDel = { access_token: objFavorite.access_token, fav_code: resp.data.fav_code };
          this.deleteFavorite(objDel).then(() => {
            this.isSave = false;
            this.showSuccessAlert('Produto removido dos favoritos'); // Mensagem para remoção
          }).catch((error) => {
            console.log('DELETE FAVORITE', error);
            this.showErrorAlert('Erro ao remover favorito');
          });
        }
      }).catch((error) => {
        console.log('CHECK FAVORITE', error);
        this.showErrorAlert('Erro ao verificar favorito');
      });
    },

    showSuccessAlert(message) {
      this.$fire({
        type: "success",
        title: "Sucesso",
        text: message,
        timer: 3000,
        showConfirmButton: false
      });
    },

    showErrorAlert(message) {
      this.$fire({
        type: "error",
        title: "Erro",
        text: message,
        timer: 3000,
        showConfirmButton: false
      });
    },

    toggleIsSave() {
      let objUser = { ...this.user, link: window.location.href }
      this.$store.dispatch('User/setUserFavorite', objUser).then(() => {
        let objFavorite = { ...this.userFavorite };
        this.checkFavorite(objFavorite).then(resp => {
          this.isSave = !resp.data.success ? false : true;
        }).catch((error) => {
          console.log('CHECK FAVORITE', error);
        });
      });
    },
    alertLogin() {
      this.$fire({
        type: "info",
        title: "Atenção",
        text: "É necessário fazer login para guardar.",
        timer: 3000,
        showConfirmButton: true,
      })
    },
  },
  mounted() {
    if (this.user && Object.values(this.user).length > 0) {
      this.toggleIsSave();
    }
  }
}
</script>

<style lang="scss">
.btn-save {
  all: unset;
  width: 140px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green-medium);
  border: 1px solid var(--green-medium);
  border-radius: 10px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
  transition: 500ms;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  p {
    font-size: 18px;
    font-family: 'Font Regular';
    color: var(--white);
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.is-save {
  background: var(--white);

  p {
    color: var(--green-medium);
  }
}
</style>
