import Communities from "@/views/Communities/views/Communities.vue";
import CommunitiesIntern from "@/views/Communities/views/CommunitiesIntern.vue";

export default [
	{
		path: "/communities/:slug?",
		name: "Communities",
		component: Communities,
	},
	{
		path: "/communities-intern/:slug",
		name: "CommunitiesIntern",
		component: CommunitiesIntern,
	},
]