<template>
  <div class="content-faq container">
    <div class="content-brand">
      <GoToBackButton />
      <div class="content-brand-elements d-none d-lg-block">
        <router-link
          tag="a"
          :to="{ name: 'Home' }"
          class="content-brand-elements-links"
        >
          {{ $t("pages.goToBackButton.start") }}
        </router-link>
        <span> > </span>
        <router-link
          tag="a"
          :to="{ name: 'About' }"
          class="content-brand-elements-links"
        >
          {{ $t("pages.faq.clients") }}
        </router-link>
        <span> > </span>
        <router-link
          tag="a"
          :to="{ name: 'About' }"
          class="content-brand-elements-links"
        >
          {{ $t("pages.faq.title") }}
        </router-link>
      </div>
    </div>
    <div class="content-faq-header" v-if="this.$route.name == 'FaqPartners'">
      <h1>
        {{ $t("pages.faq.freqquestion") }} - {{ $t("pages.faq.partner") }}
      </h1>
    </div>
    <div class="content-faq-header" v-if="this.$route.name == 'FaqClients'">
      <h1>
        {{ $t("pages.faq.freqquestion") }} - {{ $t("pages.faq.clients") }}
      </h1>
    </div>
    <div
      class="content-faq-body"
      v-for="(faqCategory, indexFaqCategory) in listFaqs"
      :key="indexFaqCategory"
    >
      <h2 v-html="faqCategory.name"></h2>
      <div v-for="(faq, indexFaq) in faqCategory.data" :key="indexFaq">
        <FAQ
          :faq="faq"
          :indexFaq="indexFaq"
          :indexFaqCategory="indexFaqCategory"
          :open="faq.open"
          @toggleOpen="toggleOpen"
        />
      </div>
    </div>

    <div class="content-brand">
      <div class="button-return">
        <GoToBackButton />
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from "./components/FaqQuestion.vue";
import axios from "axios";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
  components: {
    FAQ,
    GoToBackButton,
  },
  watch: {
    $route: function () {
      this.getFaqs();
    },
  },
  mounted() {
    this.getFaqs();
  },
  data() {
    return {
      keyApp: 0,
      listFaqs: [],
    };
  },
  methods: {
    getFaqs() {
      if (this.$route.name == "FaqPartners") {
        this.getFaqPartners();
      }
      if (this.$route.name == "FaqClients") {
        this.getFaqClients();
      }
    },
    getFaqPartners() {
      axios
        .get("/api/v1/faqsPartners")
        .then((response) => {
          this.listFaqs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFaqClients() {
      axios
        .get("/api/v1/faqsClients")
        .then((response) => {
          this.listFaqs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleOpen: function (indexFaq, indexFaqCategory) {
      this.keyApp++;
      if (typeof this.listFaqs === "object") {
        this.listFaqs = Object.values(this.listFaqs);
      }
      this.listFaqs = this.listFaqs.map((category, keyCategory) => {
        if (typeof category.data === "object") {
          category.data = Object.values(category.data);
        }
        category.data = category.data.map((faq, keyFaq) => {
          if (keyCategory === indexFaqCategory && keyFaq === indexFaq) {
            faq.open = !faq.open;
          } else {
            faq.open = false;
          }
          return faq;
        });
        return category;
      });
    },
  },
};
</script>





<style lang="scss">
@import "./style.scss";
</style>
