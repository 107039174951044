<template>
  <section class="content-banner">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      controls
      indicators
      background="#fff"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        v-for="(i, index) in banners"
        :key="index"
        class="justify-right"
        :style="{
          backgroundImage: isDesktop
            ? `url('/storage/app/media${i.banner}')`
            : `url('/storage/app/media${i.banner_mobile}')`,
        }"
      >
        <a
          class="carousel-caption-item"
          :href="i.url_carrossel"
          :target="i.target === '1' ? '_blank' : '_self'"
        >
          <div class="carousel-caption-item-div">
            <h1 class="content-text-int-title">
              {{ i.text }}
            </h1>
            <div class="content-text-int-text">
              <p class="content-text-int-text-resume">
                {{ i.subtext }}
              </p>
              <button class="content-text-int-text-btn">
                <router-link tag="a" to="/">
                  <svg
                    class="d-none d-lg-block"
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.346"
                    height="23.691"
                    viewBox="0 0 13.346 23.691"
                  >
                    <g transform="translate(-8.846 11.846) rotate(-45)">
                      <path
                        d="M20.691,0,10.346,10.346ZM0,0,10.346,10.346Z"
                        transform="translate(0 14.631) rotate(-45)"
                        fill="none"
                        stroke="#338545"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                  <svg
                    class="d-lg-none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.571"
                    height="18.142"
                    viewBox="0 0 10.571 18.142"
                  >
                    <g transform="translate(-6.071 9.071) rotate(-45)">
                      <path
                        d="M15.142,0,7.571,7.571ZM0,0,7.571,7.571Z"
                        transform="translate(0 10.707) rotate(-45)"
                        fill="none"
                        stroke="#338545"
                        stroke-linejoin="round"
                        stroke-width="3"
                      />
                    </g>
                  </svg>
                </router-link>
              </button>
            </div>
          </div>
        </a>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
      banners: {},
    };
  },
  computed: {
    ...mapGetters(["isDesktop"]),
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  async mounted() {
    axios.get("/api/v1/company/data").then((resp) => {
      if (!resp.data) {
        return;
      }
      this.banners = resp.data.banners;
      // console.log('BANNERS', this.banners);
    });
  },
};
</script>
<style lang="scss">
.content-banner {
  width: 100%;
  max-height: 673px;
  @media (max-width: 991px) {
    max-height: 570px;
    margin-bottom: 0;
  }
  .carousel {
    max-width: 100%;
    max-height: 673px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .carousel-inner {
    display: flex;
    max-width: 87.03125vw !important;
    @media screen and (max-width: 991px) {
      max-width: 100% !important;
    }
  }
  .carousel-item,
  .carousel-item:active {
    height: 673px;
    width: 100%;
    right: 0;
    border-radius: 20px 0 0 20px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: right center !important;
    @media screen and (max-width: 991px) {
      height: 570px;
      max-width: 100% !important;
      border-radius: 0;
      background-position: center !important;
    }
  }
  .carousel-indicators {
    bottom: 30px !important;
  }
  .carousel-indicators li {
    width: 21px;
    height: 21px;
    border-radius: 7px;
    border: 1px solid var(--white);
    margin: 0px 10px;
    margin-bottom: 70px;
    background: transparent;
    @media screen and (max-width: 991px) {
      border: 1px solid #b7b8b8;
      margin-bottom: 0;
    }
  }
  .carousel-indicators li.active {
    background: var(--white);
    border: 0;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.32);
  }
  .carousel-control-prev {
    height: 180px;
    width: 15%;
    display: flex;
    top: 40%;
    bottom: 40%;
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  .carousel-control-next {
    height: 180px;
    width: 210px;
    display: flex;
    top: 40%;
    bottom: 40%;
    &:hover,
    &:focus {
      background-color: var(--white) !important;
      border-radius: 10px 0 0 10px !important;
    }
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  .carousel-control-next-icon {
    width: 63px;
    height: 130px;
    background-image: url("../../../assets/img/home/next.png");
  }
  .carousel-control-prev-icon {
    width: 63px;
    height: 130px;
    background-image: url("../../../assets/img/home/prev.png");
  }
  .carousel-caption {
    position: initial;
    right: initial;
    bottom: initial;
    left: initial;
    height: 100%;
    width: 100%;
    padding: 0;
    &-item {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      padding-top: 80px;
      padding-left: 10%;
      text-align: left;
      @media (max-width: 991px) {
        padding: 0;
      }
      &-div {
        @media (max-width: 991px) {
          min-height: 315px;
          position: absolute;
          width: 100%;
          bottom: 0;
          padding: 27px 20px 80px 20px;
          background: rgba(243, 248, 244, 0.85);
        }
      }
      @media screen and (max-width: 991px) {
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 50px;
      }
    }
  }
}
.content-text {
  &-int {
    text-align: left;
    padding-top: 5rem;
    width: 78vw;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    @media screen and (max-width: 576px) {
      width: 85%;
    }
    &-title {
      font-size: 4.688rem;
      font-family: "Font Bold", sans-serif;
      font-size: 65px;
      color: var(--green-medium);
      @media screen and (max-width: 991px) {
        font-size: 35px;
      }
    }
    &-text {
      width: 100%;
      @media screen and (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        position: relative;
      }
      &-resume {
        font-size: 40px;
        font-family: "Font Regular", sans-serif;
        color: #112d23;
        max-width: 618px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
          max-width: calc(100% - 60px);
          padding-right: 30px;
          font-size: 25px;
        }
      }
      &-btn {
        width: 82px;
        height: 60px;
        border-radius: 10px;
        background-color: #fff;
        border: none;
        margin-top: 2rem;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.35);
        pointer-events: none;
        &:hover,
        &:focus {
          transition: all 0.5s ease;
          filter: drop-shadow(0 0 10px var(--green-medium));
        }
        @media screen and (max-width: 991px) {
          position: absolute;
          right: 0;
          bottom: 5px;
          width: 60px;
          height: 44px;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.2rem;
  }
}
</style>
