<template>
  <b-modal id="modal-opinion" centered class="modal-opinion">
    <div class="modal-opinion-itens">
      <button
        class="modal-opinion-itens-close"
        @click="$bvModal.hide('modal-opinion')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.78"
          height="21.15"
          viewBox="0 0 18.78 21.15"
        >
          <g
            id="Grupo_1753"
            data-name="Grupo 1753"
            transform="translate(-411.263 -553.556)"
          >
            <g
              id="Grupo_1413"
              data-name="Grupo 1413"
              transform="translate(403.688 564.131) rotate(-45)"
            >
              <path
                id="União_2"
                data-name="União 2"
                d="M18.15,0,9.075,7.779ZM0,0,9.075,7.779Z"
                transform="translate(0 12.834) rotate(-45)"
                fill="none"
                stroke="var( --green-medium)"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
            <g
              id="Grupo_1593"
              data-name="Grupo 1593"
              transform="translate(437.617 564.131) rotate(135)"
            >
              <path
                id="União_2-2"
                data-name="União 2"
                d="M18.15,0,9.075,7.779ZM0,0,9.075,7.779Z"
                transform="translate(0 12.834) rotate(-45)"
                fill="none"
                stroke="var( --green-medium)"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
          </g>
        </svg>
      </button>
      <div class="modal-opinion-itens-body">
        <div class="modal-opinion-itens-body-title">
          <h2>Avaliação de clientes</h2>
        </div>
      </div>
    </div>
    <form @submit.prevent="sendForm()">
      <div class="content-element">
        <b-form-group class="content-element-radio">
          <b-form-radio-group
            id="opinion-radio"
            name="opinion-radio"
            class="content-element-radio-input"
          >
            <b-form-radio
              class="content-element-radio-input"
              id="rate-1"
              v-model="form.rate"
              value="1"
            >
              <label for="rate-1">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.943"
                    height="16.216"
                    viewBox="0 0 16.943 16.216"
                  >
                    <g
                      id="Group_1655"
                      data-name="Group 1655"
                      transform="translate(-1402.057 -400.892)"
                    >
                      <path
                        id="Path_2761"
                        data-name="Path 2761"
                        d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                        transform="translate(1381.558 400.905)"
                        fill="#e69c5a"
                      />
                    </g>
                  </svg>
                </span>
              </label>
            </b-form-radio>
            <b-form-radio
              class="content-element-radio-input"
              id="rate-2"
              v-model="form.rate"
              value="2"
            >
              <label for="rate-2">
                <span v-for="star in 2" :key="star">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.943"
                    height="16.216"
                    viewBox="0 0 16.943 16.216"
                  >
                    <g
                      id="Group_1655"
                      data-name="Group 1655"
                      transform="translate(-1402.057 -400.892)"
                    >
                      <path
                        id="Path_2761"
                        data-name="Path 2761"
                        d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                        transform="translate(1381.558 400.905)"
                        fill="#e69c5a"
                      />
                    </g>
                  </svg>
                </span>
              </label>
            </b-form-radio>
            <b-form-radio
              class="content-element-radio-input"
              id="rate-3"
              v-model="form.rate"
              value="3"
            >
              <label for="rate-3">
                <span v-for="star in 3" :key="star">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.943"
                    height="16.216"
                    viewBox="0 0 16.943 16.216"
                  >
                    <g
                      id="Group_1655"
                      data-name="Group 1655"
                      transform="translate(-1402.057 -400.892)"
                    >
                      <path
                        id="Path_2761"
                        data-name="Path 2761"
                        d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                        transform="translate(1381.558 400.905)"
                        fill="#e69c5a"
                      />
                    </g>
                  </svg>
                </span>
              </label>
            </b-form-radio>
            <b-form-radio
              class="content-element-radio-input"
              id="rate-4"
              v-model="form.rate"
              value="4"
            >
              <label for="rate-4">
                <span v-for="star in 4" :key="star">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.943"
                    height="16.216"
                    viewBox="0 0 16.943 16.216"
                  >
                    <g
                      id="Group_1655"
                      data-name="Group 1655"
                      transform="translate(-1402.057 -400.892)"
                    >
                      <path
                        id="Path_2761"
                        data-name="Path 2761"
                        d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                        transform="translate(1381.558 400.905)"
                        fill="#e69c5a"
                      />
                    </g>
                  </svg>
                </span>
              </label>
            </b-form-radio>
            <b-form-radio
              class="content-element-radio-input"
              id="rate-5"
              v-model="form.evaluation"
              value="5"
            >
              <label for="rate-5">
                <span v-for="star in 5" :key="star">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.943"
                    height="16.216"
                    viewBox="0 0 16.943 16.216"
                  >
                    <g
                      id="Group_1655"
                      data-name="Group 1655"
                      transform="translate(-1402.057 -400.892)"
                    >
                      <path
                        id="Path_2761"
                        data-name="Path 2761"
                        d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                        transform="translate(1381.558 400.905)"
                        fill="#e69c5a"
                      />
                    </g>
                  </svg>
                </span>
              </label>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <div class="modal-opinion-itens-input">
        <textarea placeholder="Nova mensagem" maxlength="280" v-model="form.message"></textarea>
      </div>
      <div class="modal-opinion-itens-btns">
        <button class="btn-send" type="submit" @click="$bvModal.hide('modal-opinion')">Enviar</button>
      </div>
    </form>
  </b-modal>
</template>

<script> 
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        evaluation: "",
        message: "",
        slug: this.$route.params.slug,
				token: this.$store.getters['User/userIsLogged'],
      },
    };
  },
  methods: {
    clearForm() {
      this.form = {
        evaluation: "",
        message: "",
        slug: this.$route.params.slug,
				token: this.$store.getters['User/userIsLogged'],
      }
    },
    sendForm() {
      this.$store.dispatch("setIsLoading", true);
      if(this.$route.name === 'HearthIntern'){
        console.log('FORM OPINION', this.form);
        axios.post("/api/v1/rating/set", this.form)
        .then((response) => {
          this.$store.dispatch("setIsLoading", false);
          if (!response.data.response.success) {
            this.$store.dispatch('setAlert', { 
              type: 'error', 
              title: 'Erro', 
              message: "Por favor, tente novamente."
            });
            return;
          }
          this.$store.dispatch('setAlert', { 
            type: "success", 
            title: "Successo",
            message: "Sua avaliação foi enviada com sucesso!" 
					});
          this.clearForm();
					this.$store.dispatch("Hearth/getHearthIntern", { slug: this.form.slug });
        }).catch((error) => {
					this.$store.dispatch("setIsLoading", false);
					this.$store.dispatch('setAlert', { 
						type: 'error', 
						title: 'Erro', 
						message: error
					});
        });
      }

      if(this.$route.name === 'PartnerLandingPage' || this.$route.name === 'PartnersOther'){
        console.log('FORM OPINION', this.form);
        axios.post("/api/v1/partner/setcomment", this.form)
        .then((response) => {
          this.$store.dispatch("setIsLoading", false);
          if (!response.data.response.success) {
            this.$store.dispatch('setAlert', { 
              type: 'error', 
              title: 'Erro', 
              message: "Por favor, tente novamente."
            });
            return;
          }
          this.$store.dispatch('setAlert', { 
            type: "success", 
            title: "Successo",
            message: "Sua avaliação foi enviada com sucesso!" 
					});
          this.clearForm();
        }).catch((error) => {
					this.$store.dispatch("setIsLoading", false);
					this.$store.dispatch('setAlert', { 
						type: 'error', 
						title: 'Erro', 
						message: error
					});
        });
      } 
    },
  },
	async mounted() {
		// console.log('TOKEN STATE', this.$store.state.token);
		// console.log('TOKEN GETTERS', this.form.token);
	}
};
</script>

<style lang="scss">
#modal-opinion {
  .modal-header,
  .modal-footer {
    display: none;
  }
  .modal-dialog {
    max-width: 945px;
    margin: auto!important;;
    @media screen and (max-width: 991px) {
      max-width: 384px;
    }
  }
  .modal-content {
    border-radius: 0;
    border: 0;
  }
  .modal-body {
    padding: 50px 100px 100px;
    height: 674px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding: 50px 20px;
      height: 754px;
    }
  }
}
.modal-opinion {
  &-itens {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    &-close {
      width: 62px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      transition: 500ms;
      @media (max-width: 991px) {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        svg {
          max-width: 15px;
        }
      }
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
    &-body {
      max-height: 345px;
      overflow-y: auto;
      &-title {
        h2 {
          font-size: 30px;
          font-weight: bold;
          color: var(--green-dark);
          @media (max-width: 991px) {
            font-size: 22px;
          }
        }
      }
      .content-opinion-user {
        padding: 30px 0px;
        border-bottom: 1px solid #b7b8b8;
      }
    }
    &-input {
      padding: 30px 0px;
      @media screen and (max-width: 991px) {
        padding: 40px 0;
      }
      textarea {
        display: block;
        width: 100%;
        height: 245px;
        background: var(--white);
        border: 1px solid #bebebe;
        border-radius: 10px;
        padding-top: 10px;
        padding-left: 20px;
        font-size: 20px;
        font-family: "Font Regular";
        color: var(--green-dark);
        outline: none;
        resize: none;
        @media (max-width: 991px) {
          font-size: 16px;
          height: 150px;
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(135, 135, 135, 0.5);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(135, 135, 135, 0.5);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(135, 135, 135, 0.5);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(135, 135, 135, 0.5);
        }
      }
    }
    &-btns {
      .btn-send {
        width: 122px;
        height: 45px;
        background: var(--green-medium);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        font-size: 18px;
        font-family: "Font Regular";
        color: var(--white);
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--white);
          color: var(--green-medium);
        }
      }
    }
  }
}

form {
  margin-top: 30px;
  @media screen and (max-width: 991px) {
    margin-top: 0px;
  }
  .content-element {
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      margin-bottom: 0px;
    }
  }
  .content-element-radio {
    div:first-of-type(1) {
      width: 100%;
    }
    &-input {
      display: flex !important;
      justify-content: space-between !important;
      height: 36px;
      @media screen and (max-width: 991px) {
        flex-direction: column !important;
        height: auto;
        gap: 25px;
      }
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: 3px;
        opacity: 0;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 40px;
        height: 36px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: "Font Regular";
        color: var(--green-dark);
        margin-bottom: 0px;
        line-height: 1;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100%;
        background: #fff;
        border: 1px solid #bebebe;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 14px;
        height: 14px;
        background: var(--green-medium);
        background-image: none !important;
        position: absolute;
        top: 11px;
        left: 11px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      .custom-control-input {
        width: 40px;
        height: 36px;
        margin-right: 10px;
      }
      .custom-control-inline {
        margin-right: 0px !important;
      }
      .custom-control-label::before,
      .custom-control-label::after {
        width: 36px;
        height: 36px;
      }
      label {
        height: 100%;
        padding-left: 15px;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 0px !important;
        span {
          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
}
</style>