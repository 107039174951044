<template>
    <div class="content-404">
        <div class="logo">
            <img src="../assets/img/bg-geras-construtcion.png" alt="" />
        </div>
        <div class="title">
            <div class="info">
                <h1>
                    {{ $t('pages.offline.text1')}}
                </h1>
                <span
                    >{{ $t('pages.offline.text2')}}</span
                >
                <span>{{ $t('generic.gerasTeam')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.content--offline {
  margin-top: 0px !important;
}
.content-404 {
    background-image: url(../assets/img/bg-construction.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    > .title {
        text-align: left;
        margin-top: 5rem;
        > .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            > h1 {
                color: #fff;
                text-align: center;
                font-family: "Font Regular";
                color: white;
                font-weight: 700;
                font-size: 5rem;
                margin-bottom: 7rem;
            }
            > span {
                font-size: 1.5rem;
                color: #fff;
                text-align: center;
                margin-top: 1rem;
                color: white;
                font-weight: 700;
                font-size: 2rem;
                text-align: left;
                font-family: "Font Regular";
            }
        }
    }
}
</style>
