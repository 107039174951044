<template>
  <div class="content-suggestions container">
    <div class="content-suggestions-header">
      <h2>{{ $t('pages.suggestion.title') }}</h2>
    </div>
    <div class="content-suggestions-body">
      <div class="row content-suggestions-body">
        <div class="col-sm-6 col-xl-3" v-for="(suggestion, suggestionIndex) in hearthSuggestion" :key="suggestionIndex">
          <div class="content-card-suggestions">
            <div class="content-card-suggestions-image">
              <div class="content-card-suggestions-image-bg">
                <div class="content-card-suggestions-image-bg-item"
                  :style="{ backgroundImage: `url('${suggestion.image}')` }"></div>
              </div>
              <div class="content-card-suggestions-image-classification">
                <p>{{ suggestion.rate }}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.943" height="16.216" viewBox="0 0 16.943 16.216">
                  <path
                    d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                    transform="translate(-20.5 0.013)" fill="#fff" />
                </svg>
              </div>
            </div>
            <div class="content-card-suggestions-text">
              <h3>{{ suggestion.title }}</h3>
              <div class="location">
                <svg xmlns="http://www.w3.org/2000/svg" width="12.679" height="16.905" viewBox="0 0 12.679 16.905">
                  <path
                    d="M6.339,3.17a3.17,3.17,0,1,0,3.17,3.17A3.173,3.173,0,0,0,6.339,3.17Zm0,5.283A2.113,2.113,0,1,1,8.452,6.339,2.115,2.115,0,0,1,6.339,8.452ZM6.339,0A6.339,6.339,0,0,0,0,6.339C0,8.9.89,9.609,5.688,16.564a.793.793,0,0,0,1.3,0c4.8-6.955,5.688-7.669,5.688-10.225A6.339,6.339,0,0,0,6.339,0Zm0,15.648C1.74,9,1.057,8.469,1.057,6.339A5.283,5.283,0,0,1,10.075,2.6a5.248,5.248,0,0,1,1.547,3.735C11.622,8.469,10.939,9,6.339,15.648Z"
                    fill="var( --green-medium)" />
                </svg>
                <p>{{ suggestion.city_name }}, {{ suggestion.district_name }}</p>
              </div>
            </div>
            <button class="btn-consult" @click="goToPage(suggestion.slug)">{{ $t('generic.consult') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Suggestion',
  props: {
    hearthSuggestion: {
      type: Array,
      default: () => { },
    },
  },
  methods: {
    goToPage($slug) {
      this.$router.push({ name: 'HearthIntern', params: { slug: $slug } });
      this.$router.go(0);
    },
  },
}
</script>

<style lang="scss" scoped>
.content-suggestions {
  padding: 70px 0px;

  @media (max-width: 991px) {
    padding: 30px 15px 0px 15px;
  }

  &-header {
    text-align: left;
    margin-bottom: 30px;

    h2 {
      font-size: 28px;
      font-family: "Font Bold";
      color: var(--green-medium);
      margin-bottom: 0;

      @media (max-width: 991px) {
        font-size: 20px;
      }
    }
  }

  &-body {
    .content-card-suggestions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      background: var(--white);
      border: 1px solid #e7e7e7;
      border-radius: 10px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
      margin-bottom: 30px;
      transition: 500ms;
      height: 100%;

      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.9;

        .content-card-suggestions-image-bg-item {
          transform: scale(1.03);
        }

        .btn-consult {
          background: var(--green-medium);
          color: var(--white);
        }
      }

      &-image {
        position: relative;

        &-bg {
          width: 100%;
          overflow: hidden;

          &-item {
            width: 100%;
            height: 226px;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: cover !important;
            transition: 500ms;
          }
        }

        &-classification {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 83px;
          height: 36px;
          background: #e69c5a;
          border: 1px solid #e69c5a;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-size: 22px;
            font-family: "Font Bold";
            color: var(--white);
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }

      &-text {
        text-align: left;
        padding: 10px 0px 20px 0px;

        h3 {
          font-size: 18px;
          font-family: "Font Bold";
          color: #555555;
          margin-bottom: 8px;
        }

        .location {
          display: flex;
          align-items: center;

          p {
            font-size: 18px;
            font-family: "Font Regular";
            color: #555555;
            margin-left: 5px;
            margin-bottom: 0;
          }
        }
      }

      .btn-consult {
        width: 160px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        font-size: 22px;
        font-family: "Font Regular";
        color: var(--green-medium);
        margin: 20px auto 0px auto;
        transition: 500ms;

        @media (max-width: 991px) {
          width: 120px;
          height: 40px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
