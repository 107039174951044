
import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import filters from './filters'
import i18n from './plugins/vue-i18n';

Vue.use(filters);

Vue.config.productionTip = false;
Vue.config.devtools = true;

//Mixins
import mixin from '@/mixins/global/mixins.js';

//Plugins
import './plugins/bootstrap-vue'
import './plugins/vue-meta'
import './plugins/social-sharing-vue'
import './plugins/vue-component-style'
import './plugins/moment.vue'
import './plugins/notification-vue'
import './plugins/simple-alert-vue'
import './plugins/skeleton'
import './plugins/vue-scrollto'
import './plugins/axios'

new Vue({
  router,
  store,
  i18n,
  mixins: [mixin],
  render: h => h(App)
}).$mount('#app')
