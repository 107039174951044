
import axios from "axios";
import { baseApiUrl } from "@/global.js";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    hearth: [],
    hearthFilters: [],
    hearthIntern: [],
    hearthSuggestion: [],
    comments: [],
    totalcomment: 0,
  },
  getters: {
    hearth(state) {
      return state.hearth;
    },
    hearthFilters(state) {
      return state.hearthFilters;
    },
    hearthIntern(state) {
      return state.hearthIntern;
    },
    hearthSuggestion(state) {
      return state.hearthSuggestion;
    },
    comments(state) {
      return state.comments;
    },
    totalcomment(state) {
      return state.totalcomment;
    },
  },
  mutations: {
    setHearth(state, hearth) {
      state.hearth = hearth;
    },
    setHearthFilters(state, hearthFilters) {
      state.hearthFilters = hearthFilters;
    },
    setHearthIntern(state, hearthIntern) {
      hearthIntern.founded = moment(hearthIntern.founded).format("YYYY");
      state.hearthIntern = hearthIntern;
    },
    setHearthSuggestion(state, hearthSuggestion) {
      state.hearthSuggestion = hearthSuggestion;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setTotalComment(state, totalcomment) {
      state.totalcomment = totalcomment;
    }
  },
  actions: {
    async getHearthFilters({ commit, dispatch }) {
      dispatch("setIsLoading", true, { root: true });

      axios.get(`${baseApiUrl}/filter/data`).then(resp => {
        // console.log('OPTIONS', resp.data);
        commit("setHearthFilters", resp.data);
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async getHearthBySlug({ dispatch }, slug) {
      dispatch("setIsLoading", true, { root: true });
      return axios.post(`${baseApiUrl}/bannergetid`, slug)
    },
    async searchHearth({ commit, dispatch }, search) {
      dispatch("setIsLoading", true, { root: true });

      await axios.post(`${baseApiUrl}/hearthfilter`, search).then(resp => {
        console.log('HEARTH SEARCH', resp.data);
        if (!resp.data.length) {
          dispatch('setAlert', {
            type: 'warning',
            title: 'Nenhum resultado encontrado',
            message: 'Não encontramos nenhum resultado para sua busca. Tente outros filtros.'
          }, { root: true }
          );
        }
        commit("setHearth", resp.data);
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async getHearthIntern({ commit, dispatch }, slug) {
      dispatch("setIsLoading", true, { root: true });

      await axios.post(`${baseApiUrl}/hearthintern`, slug).then(resp => {
        console.log('HEARTH INTERN', resp.data);
        commit("setHearthIntern", resp.data.hearth);
        commit("setComments", resp.data.comments);
        commit("setTotalComment", resp.data.count_comments);
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async getHearthSuggestion({ commit, dispatch }, slug) {
      dispatch("setIsLoading", true, { root: true });

      await axios.post(`${baseApiUrl}/hearthsuggestion`, slug).then(resp => {
        console.log('HEARTH SUGGESTIONS', resp.data);
        commit("setHearthSuggestion", resp.data);
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
  }
}
