import axios from 'axios';

export default {
async accountLogin(context, user ) {
    return await axios.post('/api/v1/login', user)
      .then(response => {
        if (response.data.user !== undefined) {
          context.commit('setUserLogged', { token: response.data.token, user: response.data.user })
        }
        return response;
      })
      .catch(error => {
        return error;
      })
  },
  updateUserInfo(context){
    return axios.get('/api/v1/userinfo')
      .then(response => {

        context.commit('setUserLogged', { token: response.data.access_token, user: response.data })
        return response;
      })
      .catch(error => {
        return error;
      })
  },
  accountLogout({ commit }) {
    commit('setUserLoggedOut')
    localStorage.removeItem('token')
  },
  updateUserData(context, user) {
    context.commit('setUserData', user)
  },
  setUserFavorite(context, user) {
    context.commit('setUserFavorite', user);
  },
  saveFavorite(context, obj) {
    return axios.post("/api/v1/favorites/create", obj);
  },
  deleteFavorite(context, obj) {
    return axios.post("/api/v1/favorites/delete", obj);
  },
  checkFavorite(context, obj) {
    return axios.post("/api/v1/favorites/check", obj);
  },
}
