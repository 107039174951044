<template>
    <div class="content-default-sociais container" v-if="social && social.length > 0">
        <div class="content-default-sociais-div">
            <div class="row">
                <template v-for="(social, index) in social">
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'facebook'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.256" height="24.75"
                                    viewBox="0 0 13.256 24.75">
                                    <path
                                        d="M35.277,13.922l.687-4.479h-4.3V6.536a2.24,2.24,0,0,1,2.525-2.42h1.954V.3A23.827,23.827,0,0,0,32.677,0c-3.539,0-5.853,2.145-5.853,6.029V9.443H22.89v4.479h3.934V24.75h4.842V13.922Z"
                                        transform="translate(-22.89)" />
                                </svg>
                            </div>
                            <p>Facebook</p>
                        </a>
                    </div>
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'whatsapp'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path
                                        d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                                </svg>
                            </div>
                            <p>Whatsapp</p>
                        </a>
                    </div>
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'instagram'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path
                                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                </svg>
                            </div>
                            <p>Instagram</p>
                        </a>
                    </div>
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'linkedin'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.845" height="24.845"
                                    viewBox="0 0 24.845 24.845">
                                    <path
                                        d="M5.561,24.855H.41V8.267H5.561ZM2.983,6A3,3,0,1,1,5.966,2.993,3.008,3.008,0,0,1,2.983,6ZM24.84,24.855H19.7V16.78c0-1.924-.039-4.392-2.678-4.392-2.678,0-3.088,2.091-3.088,4.254v8.213H8.788V8.267h4.94V10.53H13.8a5.412,5.412,0,0,1,4.874-2.679c5.213,0,6.171,3.433,6.171,7.892v9.112Z"
                                        transform="translate(0 -0.01)" />
                                </svg>
                            </div>
                            <p>linkedin</p>
                        </a>
                    </div>
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'twitter'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32.1" height="26.071"
                                    viewBox="0 0 32.1 26.071">
                                    <path
                                        d="M28.8,54.579c.02.285.02.57.02.855,0,8.7-6.62,18.718-18.718,18.718A18.591,18.591,0,0,1,0,71.2a13.611,13.611,0,0,0,1.589.081A13.175,13.175,0,0,0,9.756,68.47a6.59,6.59,0,0,1-6.151-4.562,8.3,8.3,0,0,0,1.242.1,6.958,6.958,0,0,0,1.731-.224A6.58,6.58,0,0,1,1.3,57.329v-.081a6.626,6.626,0,0,0,2.974.835,6.589,6.589,0,0,1-2.037-8.8,18.7,18.7,0,0,0,13.565,6.884,7.427,7.427,0,0,1-.163-1.507,6.585,6.585,0,0,1,11.386-4.5A12.952,12.952,0,0,0,31.2,48.571,6.561,6.561,0,0,1,28.311,52.2,13.189,13.189,0,0,0,32.1,51.178,14.142,14.142,0,0,1,28.8,54.579Z"
                                        transform="translate(0 -48.082)" />
                                </svg>
                            </div>
                            <p>twitter</p>
                        </a>
                    </div>
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'youtube'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30.471" height="21.425"
                                    viewBox="0 0 30.471 21.425">
                                    <path
                                        d="M44.767,67.352a3.829,3.829,0,0,0-2.694-2.711C39.7,64,30.168,64,30.168,64s-9.528,0-11.9.641a3.829,3.829,0,0,0-2.694,2.711,43.109,43.109,0,0,0,0,14.764,3.772,3.772,0,0,0,2.694,2.668c2.376.641,11.9.641,11.9.641s9.528,0,11.9-.641a3.772,3.772,0,0,0,2.694-2.668,43.109,43.109,0,0,0,0-14.764ZM27.052,79.265V70.2l7.964,4.531Z"
                                        transform="translate(-14.933 -64)" />
                                </svg>
                            </div>
                            <p>youtube</p>
                        </a>
                    </div>
                    <div class="col-6 col-lg-3" v-if="social.social_network === 'tiktok'" :key="index">
                        <a class="content-default-sociais-links" :href="social.url" target="_blank" rel="noopener">
                            <div class="content-default-sociais-links-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">

                                    <defs>
                                    </defs>
                                    <g style=""
                                        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                                        <path
                                            d="M 84.494 22.535 c -5.144 0 -9.891 -1.704 -13.702 -4.579 c -4.371 -3.296 -7.512 -8.13 -8.621 -13.702 C 61.895 2.877 61.748 1.456 61.733 0 H 47.038 v 40.154 l -0.018 21.994 c 0 5.88 -3.829 10.866 -9.137 12.619 c -1.54 0.509 -3.204 0.75 -4.937 0.655 c -2.211 -0.121 -4.283 -0.789 -6.084 -1.866 c -3.833 -2.292 -6.431 -6.451 -6.502 -11.208 c -0.111 -7.435 5.9 -13.496 13.329 -13.496 c 1.467 0 2.875 0.239 4.194 0.674 V 38.552 v -3.945 c -1.391 -0.206 -2.806 -0.313 -4.238 -0.313 c -8.132 0 -15.737 3.38 -21.174 9.47 c -4.109 4.602 -6.574 10.473 -6.954 16.63 c -0.498 8.088 2.461 15.776 8.201 21.449 c 0.843 0.833 1.729 1.606 2.655 2.319 C 21.294 87.947 27.31 90 33.646 90 c 1.431 0 2.847 -0.106 4.238 -0.312 c 5.919 -0.877 11.38 -3.586 15.69 -7.847 c 5.296 -5.234 8.222 -12.183 8.253 -19.579 l -0.076 -32.844 c 2.526 1.949 5.289 3.562 8.253 4.813 c 4.611 1.945 9.5 2.931 14.531 2.93 V 26.491 v -3.959 C 84.539 22.535 84.497 22.535 84.494 22.535 L 84.494 22.535 z"
                                            style=""
                                            transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                    </g>
                                </svg>
                            </div>
                            <p>TikTok</p>
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    props: ["social"],
};
</script>
<style lang="scss" scoped>
.content-default {
    &-sociais {
        width: 100%;

        &-div {
            padding: 90px 0px 30px 0px;
            border-top: 1px solid #707070;

            @media (max-width: 991px) {
                padding: 40px 0px;
            }
        }

        &-links {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            @media (max-width: 991px) {
                margin-bottom: 20px;
            }

            &:hover,
            &:focus {
                cursor: pointer;

                .content-default-sociais-links-icon {
                    background: var(--green-medium);

                    svg {
                        fill: var(--white);
                    }
                }
            }

            &-icon {
                width: 62px;
                height: 62px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid var(--green-medium);
                background: var(--white);
                border-radius: 100%;
                margin-right: 17px;
                transition: 500ms;

                svg {
                    fill: var(--green-medium);
                    transition: 500ms;
                    max-width: 30px;
                }

                @media (max-width: 767px) {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;

                    svg {
                        max-width: 20px;
                    }
                }
            }

            p {
                font-size: 24px;
                font-family: "Font Regular";
                color: var(--green-dark);
                text-align: left;
                margin-bottom: 0;

                @media (max-width: 991px) {
                    font-size: 18px;
                    width: calc(100% - 80px);
                }

                @media (max-width: 359px) {
                    width: calc(100% - 70px);
                }
            }
        }
    }
}
</style>
