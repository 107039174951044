
import axios from "axios";
import { baseApiUrl } from "@/global.js";

export default {
	namespaced: true,
	state: {
		filterHeader: [],
		filterDataHeader: {
			target: null,
			slug: null,
			dataByHeader: false,
			city_id: null
		}
	},
	getters: {
		filterHeader(state) {
			return state.filterHeader;
		},
		filterDataHeader(state) {
			return state.filterDataHeader;
		},
	},
	mutations: {
		setFilterHeader(state, filterHeader) {
			state.filterHeader = filterHeader;
		},
		setFilterDataHeader(state, filterDataHeader) {
			state.filterDataHeader = filterDataHeader;
		},
	},
	actions: {
		async setFilterHeader({ dispatch }, objFilter) {
			dispatch("setIsLoading", true, { root: true });
			return axios.post(`${baseApiUrl}/header/search`, objFilter);
		},
		setFilterDataHeader({ commit }, obj) {
			commit('setFilterDataHeader', obj)
		},
	}
}