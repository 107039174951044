
import axios from "axios";
import { baseApiUrl } from "@/global.js";
import FilterStore from "./filterStore";

export default {
	namespaced: true,
	state: {
		products: [],
		productsFilters: [],
		families: [],
	},
	getters: {
		productsFilters(state) {
			return state.productsFilters;
		},
		products(state) {
			return state.products;
		},
		families(state) {
			return state.families;
		},
	},
	mutations: {
		setProductsFilters(state, productsFilters) {
			state.productsFilters = productsFilters;
		},
		setProducts(state, products) {
			state.products = products;
		},
		setFamilies(state, families) {
			state.families = families;
		},
	},
	actions: {
		async getProductsFilters({ commit, dispatch }, slug){
			dispatch("setIsLoading", true, { root: true });

			await axios.post(`${baseApiUrl}/ecommerce/categories`, slug).then(resp => {
				commit("setProductsFilters", resp.data);
				console.log(resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
		
		async getProductsFiltered({ commit, dispatch }, objFilter, family){
			dispatch("setIsLoading", true, { root: true });
			await axios.post(`${baseApiUrl}/ecommerce/productsfiltered`, objFilter, family).then(resp => {
				if (!resp.data.length){
					dispatch('setAlert', { 
							type: 'warning', 
							title: 'Nenhum resultado encontrado', 
							message: 'Não encontramos nenhum resultado para sua busca. Tente outros filtros.' 
						}, { root: true }
					);
				}
				commit("setProducts", resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
		async getFamilies({ commit, dispatch }, slug, partner_id){
			dispatch("setIsLoading", true, { root: true });
			axios.post(`${baseApiUrl}/ecommerce/families`, slug, partner_id).then(resp => {
				commit("setFamilies", resp.data);
				dispatch("setIsLoading", false, { root: true });
			}
			).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		}
	},
	modules: {
		FilterStore,
	}
}