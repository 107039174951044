import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null,
        user: null,
        userFavorite: {
            access_token: null,
            link: null,
        }
    },
    actions,
    getters,
    mutations,
};
