<template>
  <div class="content-community-intern content-main">
    <div class="content-community-intern-itens">
      <div class="content-brand container">
        <GoToBackButton />
        <div class="content-brand-elements d-none d-lg-block">
          <router-link tag="a" :to="{ name: 'Home' }" class="content-brand-elements-links">
            {{ $t("pages.goToBackButton.start") }}
          </router-link>
          <span> > </span>
          <router-link tag="a" :to="{ name: 'Communities' }" class="content-brand-elements-links">
            {{ $t("pages.communit.title") }}
          </router-link>
          <span> > </span>
          <router-link tag="a" :to="{ name: '' }" class="content-brand-elements-links">
            {{ community.name }}
          </router-link>
        </div>
        <template v-if="titleAndDescription">
          <SocialSharing :title="community.name" :description="community.short_description" class="d-lg-none" />
        </template>
      </div>
      <div class="content-community-intern-itens-banner container-lg">
        <div class="content-community-intern-itens-banner-bg" v-if="community.banner && community.banner.path"
          :style="{ background: `url(${community.banner.path})` }">
          <div class="content-community-intern-itens-banner-bg-logo">
            <img class="img-fluid" v-if="community.logo.path" :src="community.logo.path" alt="logo-communities"
              title="logo-communities" />
          </div>
        </div>
        <div class="content-community-intern-itens-banner-description">
          <h1 class="title">{{ community.name }}</h1>
          <div class="location">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.489" height="29.985" viewBox="0 0 22.489 29.985">
              <path
                d="M11.244,5.622a5.622,5.622,0,1,0,5.622,5.622A5.628,5.628,0,0,0,11.244,5.622Zm0,9.37a3.748,3.748,0,1,1,3.748-3.748A3.752,3.752,0,0,1,11.244,14.992ZM11.244,0A11.244,11.244,0,0,0,0,11.244c0,4.534,1.579,5.8,10.089,18.135a1.406,1.406,0,0,0,2.311,0c8.509-12.336,10.089-13.6,10.089-18.135A11.244,11.244,0,0,0,11.244,0Zm0,27.755c-8.158-11.8-9.37-12.734-9.37-16.511a9.37,9.37,0,0,1,16-6.626,9.309,9.309,0,0,1,2.744,6.626C20.614,15.021,19.4,15.957,11.244,27.755Z"
                fill="#112d23" />
            </svg>
            <p>{{ community.concelho }}</p>
          </div>
          <BtnFavorite class="d-lg-none" :name="community.name" />
        </div>
      </div>
      <div class="container d-none d-lg-flex">
        <div class="content-community-intern-itens-save-and-share">
          <BtnFavorite :name="community.name" />
          <template v-if="titleAndDescription">
            <SocialSharing :title="community.name" :description="community.short_description"
              class="d-none d-lg-flex" />
          </template>
        </div>
      </div>
      <TextLineClamp :short_description="community.short_description" />
      <div class="content-community-intern-itens-ads d-none d-lg-block" v-if="publicities.image_fullpage == null">
        <img class="img-fluid container" src="@/assets/img/communities-intern/bg-ads.png" alt="image ads"
          title="image ads" />
      </div>
      <a class="content-community-intern-itens-ads d-none d-lg-block" :href="publicities.image_fullpage_url"
        target="_blank" rel="noopener" v-else>
        <img class="img-fluid container" :src="publicities.image_fullpage.path" alt="image ads" title="image ads" />
      </a>
      <div class="content-community-intern-itens-services container" v-if="
        community &&
        community.servicos &&
        Object.keys(community.servicos).length > 0
      ">
        <div class="content-community-intern-itens-services-div" v-for="(service, index) in community.servicos"
          :key="index">
          <div class="content-community-intern-itens-services-header">
            <h2>{{ index }}</h2>
          </div>
          <div class="content-community-intern-itens-services-body">
            <div class="row">
              <div class="col-6 col-lg-3" v-for="(list, indexTwo) in community.servicos[index]" :key="indexTwo">
                <div class="content-elements" v-if="list.redirect == 'fixed'">
                  <div class="content-elements-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="97.067" height="75.498" viewBox="0 0 97.067 75.498">
                      <path
                        d="M95.761,58.959C90.867,58.981,73.009,60,59.725,72.946A43.3,43.3,0,0,0,48.533,88.932,43.3,43.3,0,0,0,37.342,72.946C24.058,60,6.2,58.981,1.306,58.959A1.282,1.282,0,0,0,0,60.277c.039,4.705,1.2,21.257,14.959,33.586,14.159,13.788,28.182,13.63,33.574,13.63s19.412.16,33.574-13.63C95.865,81.532,97.028,64.982,97.067,60.277A1.282,1.282,0,0,0,95.761,58.959ZM48.53,77.6a46.364,46.364,0,0,1,7.43-8.514A50.434,50.434,0,0,1,66.115,61.47c-2.77-11.881-8.716-22.422-16.3-29.022a2.025,2.025,0,0,0-2.551,0c-7.582,6.6-13.527,17.127-16.3,29a49.545,49.545,0,0,1,9.98,7.481A47.652,47.652,0,0,1,48.53,77.6Z"
                        transform="translate(0 -31.995)" />
                    </svg>
                  </div>
                  <div class="content-elements-text">
                    <p>{{ list.name }}</p>
                  </div>
                </div>
                <a :href="list.url_redirect" target="_blank" rel="noopener" class="content-elements"
                  v-if="list.redirect == 'url'">
                  <div class="content-elements-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="97.067" height="75.498" viewBox="0 0 97.067 75.498">
                      <path
                        d="M95.761,58.959C90.867,58.981,73.009,60,59.725,72.946A43.3,43.3,0,0,0,48.533,88.932,43.3,43.3,0,0,0,37.342,72.946C24.058,60,6.2,58.981,1.306,58.959A1.282,1.282,0,0,0,0,60.277c.039,4.705,1.2,21.257,14.959,33.586,14.159,13.788,28.182,13.63,33.574,13.63s19.412.16,33.574-13.63C95.865,81.532,97.028,64.982,97.067,60.277A1.282,1.282,0,0,0,95.761,58.959ZM48.53,77.6a46.364,46.364,0,0,1,7.43-8.514A50.434,50.434,0,0,1,66.115,61.47c-2.77-11.881-8.716-22.422-16.3-29.022a2.025,2.025,0,0,0-2.551,0c-7.582,6.6-13.527,17.127-16.3,29a49.545,49.545,0,0,1,9.98,7.481A47.652,47.652,0,0,1,48.53,77.6Z"
                        transform="translate(0 -31.995)" />
                    </svg>
                  </div>
                  <div class="content-elements-text">
                    <p>{{ list.name }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-community-intern-itens-category container" v-if="cards1.length > 0">
        <div class="content-community-intern-itens-category-header">
          <h2>{{ $t('generic.categories') }}</h2>
        </div>
        <div class="content-community-intern-itens-category-body">
          <div class="row row-cols-2 content-products-items-grid d-none d-lg-flex">
            <router-link :to="{ name: 'Partners', params: { slug: c.slug, slugb: community.slug }, }"
              class="col content-products-items-grid-element" :class="[index % 3 === 0 ? 'col-lg-7' : 'col-lg-5']"
              v-for="(c, index) in cards1" :key="c.id">
              <div class="content-products-items-grid-element-img" :style="{ backgroundImage: `url('${c.banner}')` }">
                <div class="content-products-items-grid-element-text" :style="{ backgroundColor: `${c.color}af` }">
                  <p>{{ c.title }}</p>
                  <h2>{{ c.subtitle }}</h2>
                </div>
              </div>
            </router-link>
          </div>
          <swiper class="content-products-items-grid d-lg-none" :options="swiperProducts">
            <swiper-slide class="content-products-items-grid-element" v-for="c in cards1" :key="c.id">
              <router-link :to="{
                name: 'Partners',
                params: { slug: c.slug, slugb: community.slug },
              }" class="content-products-items-grid-element-img" :style="{
                backgroundImage: `url('${c.banner}')`,
              }">
                <div class="content-products-items-grid-element-text" :style="{ backgroundColor: `${c.color}af` }">
                  <p>{{ c.title }}</p>
                  <h2>{{ c.subtitle }}</h2>
                </div>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div class="content-community-intern-itens-highlights container" v-if="blogall && blogall.length > 0">
        <div class="content-community-intern-itens-highlights-header">
          <h2>{{ $t("generic.destaq") }}</h2>
          <!-- <router-link
                        tag="a"
                        class="btn-return"
                        :to="{
                            name: 'BlogListSlug',
                            params: { slug: community.partner.slug },
                        }"
                    >
                        ver todos
                    </router-link> -->
        </div>
        <div class="content-community-intern-itens-highlights-body">
          <div class="row">
            <div class="col-12 col-lg-6" v-for="(k, index) in blogall" :key="index">
              <router-link class="content-blog-items-recent-article-item" :to="{
                name: 'BlogDetail',
                params: { slug: k.slug },
              }">
                <div class="content-blog-items-recent-article-item-img">
                  <img :src="k.thumbs" alt="image blog" title="image blog" />
                </div>
                <div class="content-blog-items-recent-article-item-text">
                  <p class="date">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.963" height="19.386" viewBox="0 0 16.963 19.386">
                      <path
                        d="M15.145,2.423H13.328V.454A.456.456,0,0,0,12.874,0h-.3a.456.456,0,0,0-.454.454V2.423H4.847V.454A.456.456,0,0,0,4.392,0h-.3a.456.456,0,0,0-.454.454V2.423H1.817A1.818,1.818,0,0,0,0,4.241V17.569a1.818,1.818,0,0,0,1.817,1.817H15.145a1.818,1.818,0,0,0,1.817-1.817V4.241A1.818,1.818,0,0,0,15.145,2.423ZM1.817,3.635H15.145a.608.608,0,0,1,.606.606V6.058H1.212V4.241A.608.608,0,0,1,1.817,3.635Zm13.328,14.54H1.817a.608.608,0,0,1-.606-.606V7.27h14.54v10.3A.608.608,0,0,1,15.145,18.175ZM5.6,12.116H4.089a.456.456,0,0,1-.454-.454V10.147a.456.456,0,0,1,.454-.454H5.6a.456.456,0,0,1,.454.454v1.515A.456.456,0,0,1,5.6,12.116Zm3.635,0H7.724a.456.456,0,0,1-.454-.454V10.147a.456.456,0,0,1,.454-.454H9.239a.456.456,0,0,1,.454.454v1.515A.456.456,0,0,1,9.239,12.116Zm3.635,0H11.359a.456.456,0,0,1-.454-.454V10.147a.456.456,0,0,1,.454-.454h1.515a.456.456,0,0,1,.454.454v1.515A.456.456,0,0,1,12.874,12.116ZM9.239,15.751H7.724A.456.456,0,0,1,7.27,15.3V13.782a.456.456,0,0,1,.454-.454H9.239a.456.456,0,0,1,.454.454V15.3A.456.456,0,0,1,9.239,15.751Zm-3.635,0H4.089a.456.456,0,0,1-.454-.454V13.782a.456.456,0,0,1,.454-.454H5.6a.456.456,0,0,1,.454.454V15.3A.456.456,0,0,1,5.6,15.751Zm7.27,0H11.359A.456.456,0,0,1,10.9,15.3V13.782a.456.456,0,0,1,.454-.454h1.515a.456.456,0,0,1,.454.454V15.3A.456.456,0,0,1,12.874,15.751Z"
                        fill="#338545" />
                    </svg>
                    <span>{{ k.created_at }}</span>
                  </p>
                  <h4 class="line-clamp">{{ k.title }}</h4>
                  <p class="line-clamp">
                    {{ k.short_description }}
                  </p>
                </div>
                <div class="content-blog-items-recent-article-item-btn">
                  <router-link tag="button" :to="{
                    name: 'BlogDetail',
                    params: { slug: k.slug },
                  }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.034" height="17.069" viewBox="0 0 10.034 17.069">
                      <g id="Grupo_1413" data-name="Grupo 1413" transform="translate(-5.534 8.534) rotate(-45)">
                        <path id="União_2" data-name="União 2" d="M14.069,0,7.034,7.034ZM0,0,7.034,7.034Z"
                          transform="translate(0 9.948) rotate(-45)" fill="none" stroke="var( --green-medium)"
                          stroke-linejoin="round" stroke-width="3" />
                      </g>
                    </svg>
                  </router-link>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <template v-if="this.community.social_media">
        <SocialMedia :social="socialNetworks" />
      </template>
      <div class="container">
        <div class="content-brand d-flex align-flex-start">
          <GoToBackButton />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapActions, mapGetters } from "vuex";
import TextLineClamp from "@/components/textLineClamp/TextLineClamp";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";
import BtnFavorite from "@/components/btnFavorite/Index";
import SocialSharing from "@/components/socialSharing/SocialSharing.vue";
import SocialMedia from "@/components/socialSharing/SocialMedia";

export default {
  data() {
    return {
      community: [],
      cards1: [],
      blogall: {},
      slug: this.$route.params.slug,
      configbanner: {
        position: 4,
        limit: 4,
      },
      swiperProducts: {
        slidesPerView: 1.3,
        spaceBetween: 20,
        loop: false,
        speed: 800,
        watchOverflow: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          768: {
            slidesPerView: 1.3,
          },
          991: {
            slidesPerView: 1.3,
          },
        },
      },
      numberArticles: 4,
      page: "community",
      titleAndDescription: false,
    };
  },
  metaInfo() {
    const metaData = this.community;
    return {
      title: metaData.name,
      titleTemplate: "%s | Geras",
      meta: [
        {
          hid: "description",
          name: "description",
          content: metaData.short_description
            ? metaData.short_description.slice(0, 100).replace(/<\/?p>/g, "")
            : "",
        },
        {
          hid: "og:title",
          name: "og:title",
          content: metaData.name,
        },
        {
          hid: "og:description",
          name: "og:description",
          content: metaData.short_description
            ? metaData.short_description.slice(0, 100).replace(/<\/?p>/g, "")
            : "",
        },
        {
          hid: "og:url",
          name: "og:url",
          content: window.location.href,
        },
        {
          hid: "og:image",
          name: "og:image",
          content: metaData.banner?.path,
        },
        {
          hid: "og:type",
          name: "og:type",
          content: "website",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: metaData.name,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: metaData.short_description
            ? metaData.short_description.slice(0, 100).replace(/<\/?p>/g, "")
            : "",
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content: metaData.banner?.path,
        },
        {
          hid: "twitter:url",
          name: "twitter:url",
          content: window.location.href,
        },
        {
          hid: "twitter:card",
          name: "twitter:card",
          content: "summary",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    TextLineClamp,
    GoToBackButton,
    BtnFavorite,
    SocialSharing,
    SocialMedia,
  },
  computed: {
    ...mapGetters("User", ["userIsLogged"]),
    ...mapGetters({
      user: "User/user",
      userFavorite: "User/userFavorite",
      publicities: "Publicity/publicities",
    }),
    socialNetworks() {
      if (this.community.social_media.length > 0) {
        return this.community.social_media.map((item) => {
          return {
            social_network: item.rede_social,
            url: item.url,
          };
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      saveFavorite: "User/saveFavorite",
      deleteFavorite: "User/deleteFavorite",
      checkFavorite: "User/checkFavorite",
    }),
    formatDate(date) {
      return moment(date).format("YYYY");
    },
    formatDateBlog(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    postCommunityIntern() {
      axios
        .post("/api/v1/community/intern", { slug: this.slug })
        .then((response) => {
          console.log("response", response);
          this.community = { ...response.data };
          this.titleAndDescription = true;
          const obj = {
            slug: this.community.slug,
            offset: this.numberArticles,
            page: this.page,
          };
          axios
            .post("/api/v1/blog/", obj)
            .then((resp) => {
              this.blogall = resp.data;
            })
            .catch((error) => {
              // this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // troca os elementos
      }
      return array;
    },
    // getBannerList() {
    //   axios.post("/api/v1/bannerlist", this.configbanner).then((resp) => {
    //     if (!resp.data) {
    //       return;
    //     }
    //     this.data = resp.data;
    //     for (let i = 0; i < this.data.length; i++) {
    //       if (this.data[i].position == "4") {
    //         this.cards1.push(this.data[i]);
    //       }
    //     }
    //   });
    // },
    getBannerList() {
      const requestData = {
        ...this.configbanner,
        slug: this.slug
      };

      axios.post("/api/v1/bannerlistcomm", requestData).then((resp) => {
        if (!resp.data) {
          return;
        }
        this.data = this.shuffleArray(resp.data);
        this.cards1 = this.data.slice(0, 4); // Limita a quatro banners e usa os dados embaralhados
      }).catch((error) => {
        console.error("Erro ao obter banners:", error);
      });
    },
  },
  mounted() {
    this.postCommunityIntern();
    this.getBannerList();
    this.$store.dispatch("Publicity/getPublicities", this.$route.name);
  },
};
</script>

<style lang="scss">
.content-community-intern {
  margin-top: 170px;
  padding-top: 40px;
  padding-bottom: 60px;

  @media (max-width: 991px) {
    margin-top: 62px;
    padding-top: 30px;
    padding-bottom: 25px;
  }

  &-itens {
    .content-brand {
      @media (max-width: 991px) {
        justify-content: space-between;
      }
    }

    &-banner {
      @media (max-width: 991px) {
        padding: 0 !important;
      }

      &-bg {
        width: 100%;
        height: 450px;
        position: relative;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        border-radius: 20px 20px 0px 0px;

        @media (max-width: 991px) {
          border-radius: 0;
        }

        &-logo {
          position: absolute;
          bottom: -70px;
          right: 30px;
          padding: 35px;
          background: var(--white);
          border-radius: 10px;

          @media (max-width: 991px) {
            bottom: -30px;
          }
        }
      }

      &-description {
        padding: 30px 40px;
        text-align: left;
        background: #f2f2f2;
        border-radius: 0px 0px 20px 20px;

        @media (max-width: 991px) {
          padding: 65px 40px 45px 40px;
          border-radius: 0;
        }

        .title {
          font-size: 34px;
          font-family: "Font Bold";
          color: var(--green-dark);
          margin-bottom: 30px;

          @media (max-width: 991px) {
            font-size: 24px;
          }
        }

        .location {
          display: flex;
          align-items: center;

          @media (max-width: 991px) {
            margin-bottom: 20px;
          }

          svg {
            @media (max-width: 991px) {
              max-width: 16px;
            }
          }

          p {
            font-size: 24px;
            font-family: "Font Regular";
            color: var(--green-dark);
            margin-left: 10px;
            margin-bottom: 0;

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }
      }
    }

    &-save-and-share {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin: 30px 0;
      width: 100%;
    }

    .btn-share {
      all: unset;
      background: 0;
      border: 0;
      outline: none;
    }

    &-ads {
      background-color: #f2f2f2;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-services {
      padding-top: 75px;

      @media (max-width: 991px) {
        padding-top: 70px;
      }

      &-header {
        text-align: left;
        margin-bottom: 75px;

        @media (max-width: 991px) {
          margin-bottom: 50px;
        }

        h2 {
          font-size: 40px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 0;

          @media (max-width: 991px) {
            font-size: 28px;
          }
        }
      }

      &-body {
        .content-elements {
          display: block;
          text-align: center;
          margin-bottom: 100px;
          transition: 500ms;

          @media (max-width: 991px) {
            margin-bottom: 45px;
          }

          &:hover,
          &:focus {
            .content-elements-icon {
              background: var(--green-medium);

              svg {
                fill: var(--white);
              }
            }
          }

          &-icon {
            width: 200px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--white);
            border: 1px solid var(--green-medium);
            border-radius: 100%;
            margin: 0 auto 40px auto;
            transition: 500ms;

            @media (max-width: 991px) {
              width: 172px;
              height: 172px;
              margin: 0 auto 20px auto;

              svg {
                max-width: 83px;
              }
            }

            @media (max-width: 400px) {
              width: 120px;
              height: 120px;

              svg {
                max-width: 60px;
              }
            }

            svg {
              fill: var(--green-medium);
              transition: 500ms;
            }
          }

          &-text {
            p {
              font-size: 24px;
              font-family: "Font Regular";
              color: var(--green-dark);
              margin-bottom: 0;

              @media (max-width: 991px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    &-category {
      padding-bottom: 100px;
      padding-top: 50px;

      @media (max-width: 991px) {
        padding-bottom: 70px;
      }

      &-header {
        text-align: left;
        margin-bottom: 45px;

        h2 {
          font-size: 40px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 0;

          @media (max-width: 991px) {
            font-size: 28px;
          }
        }
      }

      &-body {
        .content-products-items-grid {
          padding-right: 0;
          align-items: center;
          padding: 0;

          @media screen and (max-width: 1200px) {
            width: 100%;
            margin: 0;
          }

          @media screen and (max-width: 991px) {
            width: 100%;
            height: auto;
            margin: 0 !important;
            display: flex;
            flex-direction: row;
          }

          &-element {
            width: 100%;
            border: none;
            background-color: transparent !important;
            height: 280px;
            margin-bottom: 25px;

            &-img {
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
            }

            &-text {
              display: flex;
              flex-direction: column;
              text-align: left;
              width: 100%;
              height: 100%;
              color: #ffffff;
              border-radius: 10px;
              padding: 30px 25px;

              p {
                font-family: "Font Regular";
                font-size: 23px;

                @media (max-width: 576px) {
                  font-size: 20px;
                }
              }

              h2 {
                font-family: "Font Bold";
                font-size: 33px;

                @media (max-width: 576px) {
                  font-size: 28px;
                }
              }

              &:hover,
              &:focus {
                cursor: pointer;
                background-color: #ffffffe3 !important;
                color: #7c997d;
                border: #7c997d solid 1px;
              }
            }
          }
        }
      }
    }

    &-highlights {
      padding-bottom: 100px;

      @media (max-width: 991px) {
        padding-bottom: 25px;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 45px;

        h2 {
          font-size: 40px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 0;

          @media (max-width: 991px) {
            font-size: 28px;
          }
        }

        .btn-return {
          width: 127px;
          height: 47px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--white);
          border: 1px solid var(--green-medium);
          border-radius: 10px;
          font-size: 18px;
          font-family: "Font Bold";
          color: var(--green-medium);
          transition: 500ms;

          &:hover,
          &:focus {
            cursor: pointer;
            background: var(--green-medium);
            color: var(--white);
          }
        }
      }

      &-body {
        .content-blog-items-recent-article-item {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 60px;
          transition: 500ms;

          @media (max-width: 991px) {
            margin-bottom: 25px;
          }

          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }

          &-text {
            color: #112d23;
            text-align: start;
            padding: 0 1rem 0 2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 50px);

            h4 {
              font-size: 24px;
              font-family: "Font Bold";
              margin-bottom: 1rem;

              @media screen and (max-width: 991px) {
                font-size: 20px;
              }
            }

            p {
              max-width: calc(100% - 50px);
              font-size: 18px;
              font-family: "Font Regular";
              padding-bottom: 0;
              margin-bottom: 0;

              @media screen and (max-width: 991px) {
                font-size: 18px;
                text-align: justify;
              }
            }

            .line-clamp {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .date {
              margin-bottom: 10px;

              span {
                font-size: 18px;
                font-family: "Font Regular";
                color: var(--green-dark);
                margin-left: 10px;
              }
            }
          }

          &-btn {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            position: absolute;
            right: 0;
            bottom: 0;

            button {
              background-color: #ffffff;
              width: 56px;
              height: 41px;
              color: #fff;
              border: 1px solid var(--green-medium);
              border-radius: 10px;

              &:hover,
              &:focus {
                transition: all 0.5s ease;
                filter: drop-shadow(0 0 10px var(--green-medium));
              }

              @media screen and (max-width: 991px) {
                position: absolute;
                bottom: 0;
                right: 1rem;
                width: 40px;
                height: 29px;
                border-radius: 7px;

                svg {
                  width: 10px;
                  height: 10px;
                }
              }
            }

            svg {
              @media screen and (max-width: 576px) {
                max-width: 7px;
                height: 15px;
              }
            }
          }

          &-img {
            img {
              border-radius: 10px;
              width: 170px;
              height: 170px;
              object-fit: cover;

              @media (max-width: 991px) {
                width: 112px;
                height: 112px;
              }
            }
          }
        }
      }
    }

    .container {
      .content-brand {
        .btn-return {
          margin: 0 auto 0 0;
        }
      }
    }
  }
}
</style>
