<template>
  <form class="content-form-request" id="form-request" @submit.prevent="formObject()">
    <div class="content-form-request-element container">
      <div class="content-form-request-header">
        <h2>{{ $t('pages.hearth.submitorder') }}</h2>
      </div>
      <div class="content-form-request-body">
        <div class="content-element">
          <b-form-group class="content-element-radio" v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-1" v-model="order.custom.tipo_estadia"
              :aria-describedby="ariaDescribedby" name="radio-group-1" class="content-element-radio-input">
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby"
                id="estadia-temporaria" value="estadia_temporaria">
                <label for="estadia-temporaria">{{ $t('pages.hearth.temporary') }}</label>
              </b-form-radio>
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby"
                id="estadia-permanente" value="estadia_permanente">
                <label for="estadia-permanente">{{ $t('pages.hearth.permanent') }}</label>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="content-form-request-info"
      v-if="this.order.custom.tipo_estadia == 'estadia_temporaria' || this.order.custom.tipo_estadia == 'estadia_permanente'">
      <div class="content-form-request-body">
        <div class="content-element">
          <div class="row">
            <div class="col-lg-6">
              <div class="content-element-input-date">
                <input type="date" name="data_entrada" v-model="data_checkin"
                  @change="changeInputDate($event, 'check-in')" class="form-control" required="required" />
                <svg xmlns="http://www.w3.org/2000/svg" width="27.002" height="30.859" viewBox="0 0 27.002 30.859">
                  <path
                    d="M24.109,3.857H21.216V.723A.725.725,0,0,0,20.492,0H20.01a.725.725,0,0,0-.723.723V3.857H7.715V.723A.725.725,0,0,0,6.991,0H6.509a.725.725,0,0,0-.723.723V3.857H2.893A2.894,2.894,0,0,0,0,6.75V27.966a2.894,2.894,0,0,0,2.893,2.893H24.109A2.894,2.894,0,0,0,27,27.966V6.75A2.894,2.894,0,0,0,24.109,3.857ZM2.893,5.786H24.109a.967.967,0,0,1,.964.964V9.643H1.929V6.75A.967.967,0,0,1,2.893,5.786ZM24.109,28.93H2.893a.967.967,0,0,1-.964-.964V11.572H25.073V27.966A.967.967,0,0,1,24.109,28.93ZM8.92,19.287H6.509a.725.725,0,0,1-.723-.723V16.153a.725.725,0,0,1,.723-.723H8.92a.725.725,0,0,1,.723.723v2.411A.725.725,0,0,1,8.92,19.287Zm5.786,0H12.3a.725.725,0,0,1-.723-.723V16.153a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723v2.411A.725.725,0,0,1,14.706,19.287Zm5.786,0H18.081a.725.725,0,0,1-.723-.723V16.153a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723v2.411A.725.725,0,0,1,20.492,19.287Zm-5.786,5.786H12.3a.725.725,0,0,1-.723-.723V21.939a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723V24.35A.725.725,0,0,1,14.706,25.073Zm-5.786,0H6.509a.725.725,0,0,1-.723-.723V21.939a.725.725,0,0,1,.723-.723H8.92a.725.725,0,0,1,.723.723V24.35A.725.725,0,0,1,8.92,25.073Zm11.572,0H18.081a.725.725,0,0,1-.723-.723V21.939a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723V24.35A.725.725,0,0,1,20.492,25.073Z"
                    fill="var( --green-medium)" />
                </svg>
              </div>
            </div>
          </div>
          <div class="row" v-if="this.order.custom.tipo_estadia == 'estadia_temporaria'">
            <div class="col-lg-6">
              <div class="content-element-input-date">
                <input type="date" name="data_entrada" v-model="data_checkout"
                  @change="changeInputDate($event, 'check-out')" class="form-control" value="teste"
                  required="required" />
                <svg xmlns="http://www.w3.org/2000/svg" width="27.002" height="30.859" viewBox="0 0 27.002 30.859">
                  <path
                    d="M24.109,3.857H21.216V.723A.725.725,0,0,0,20.492,0H20.01a.725.725,0,0,0-.723.723V3.857H7.715V.723A.725.725,0,0,0,6.991,0H6.509a.725.725,0,0,0-.723.723V3.857H2.893A2.894,2.894,0,0,0,0,6.75V27.966a2.894,2.894,0,0,0,2.893,2.893H24.109A2.894,2.894,0,0,0,27,27.966V6.75A2.894,2.894,0,0,0,24.109,3.857ZM2.893,5.786H24.109a.967.967,0,0,1,.964.964V9.643H1.929V6.75A.967.967,0,0,1,2.893,5.786ZM24.109,28.93H2.893a.967.967,0,0,1-.964-.964V11.572H25.073V27.966A.967.967,0,0,1,24.109,28.93ZM8.92,19.287H6.509a.725.725,0,0,1-.723-.723V16.153a.725.725,0,0,1,.723-.723H8.92a.725.725,0,0,1,.723.723v2.411A.725.725,0,0,1,8.92,19.287Zm5.786,0H12.3a.725.725,0,0,1-.723-.723V16.153a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723v2.411A.725.725,0,0,1,14.706,19.287Zm5.786,0H18.081a.725.725,0,0,1-.723-.723V16.153a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723v2.411A.725.725,0,0,1,20.492,19.287Zm-5.786,5.786H12.3a.725.725,0,0,1-.723-.723V21.939a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723V24.35A.725.725,0,0,1,14.706,25.073Zm-5.786,0H6.509a.725.725,0,0,1-.723-.723V21.939a.725.725,0,0,1,.723-.723H8.92a.725.725,0,0,1,.723.723V24.35A.725.725,0,0,1,8.92,25.073Zm11.572,0H18.081a.725.725,0,0,1-.723-.723V21.939a.725.725,0,0,1,.723-.723h2.411a.725.725,0,0,1,.723.723V24.35A.725.725,0,0,1,20.492,25.073Z"
                    fill="var( --green-medium)" />
                </svg>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="content-element-select">
                <b-form-select v-model="order.custom.tipo_quarto">
                  <b-form-select-option :value="''" disabled>{{ $t('pages.hearth.typebed') }}*</b-form-select-option>
                  <b-form-select-option v-for="list in bedroom" :key="list.id" :value="list.id">{{ list.name
                    }}</b-form-select-option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="content-element">
          <b-form-group class="content-element-radio" v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-2" v-model="order.custom.tipo_pessoa"
              :aria-describedby="ariaDescribedby" name="radio-group-2" class="content-element-radio-input">
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="sou-utente"
                value="sou_utente">
                <label for="sou-utente">{{ $t('pages.hearth.user') }}</label>
              </b-form-radio>
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="sou-cuidador"
                value="sou_cuidador">
                <label for="sou-cuidador">{{ $t('pages.hearth.caregiver') }}</label>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="content-element">
          <div class="row">
            <div class="col-lg-12">
              <div class="content-element-input">
                <input type="text" name="nome" placeholder="Nome e apelido*" v-model="order.name" />
              </div>
            </div>
          </div>
          <div class="row">
            <template v-if="order.custom.tipo_pessoa === 'sou_cuidador'">
              <div class="col-lg-8">
                <div class="content-element-input">
                  <input type="text" name="nome_utente" placeholder="Nome do utente*"
                    v-model="order.custom.nome_utente" />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col-lg-8">
                <div class="content-element-input">
                  <input type="email" name="email" value="" placeholder="Email*" v-model="order.email" />
                </div>
              </div>
            </template>
            <div class="col-lg-4">
              <div class="content-element-input">
                <input type="text" name="idade_utente" placeholder="Idade utente*"
                  v-model="order.custom.idade_utente" />
              </div>
            </div>
          </div>
        </div>
        <div class="content-element" v-if="order.custom.tipo_pessoa === 'sou_cuidador'">
          <div class="row">
            <div class="col-lg-6">
              <div class="content-element-select">
                <b-form-select v-model="order.custom.parentesco">
                  <template #first>
                    <b-form-select-option :value="null" disabled>Selecione</b-form-select-option>
                  </template>
                  <b-form-select-option value="Pai/Mae">{{ $t('pages.hearth.fathermother') }}</b-form-select-option>
                  <b-form-select-option value="Filho(a)">{{ $t('pages.hearth.son') }}(a)</b-form-select-option>
                  <b-form-select-option value="Primo(a)">{{ $t('pages.hearth.cousin') }}</b-form-select-option>
                  <b-form-select-option value="Neto(a)">{{ $t('pages.hearth.grandson') }}(a)</b-form-select-option>
                  <b-form-select-option value="Tio(a)">{{ $t('pages.hearth.uncle') }}(a)</b-form-select-option>
                </b-form-select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-element-input">
                <input type="email" name="email" value="" placeholder="Email*" v-model="order.email" />
              </div>
            </div>
          </div>
        </div>
        <div class="content-element">
          <div class="row">
            <div class="col-lg-6">
              <div class="content-element-input">
                <input type="text" name="telemovel" value="" placeholder="Telefone*" v-model="order.phone" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-element-input">
                <input type="text" name="morada" value="" placeholder="Localidade*" v-model="order.morada" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-form-request-description"
      v-if="this.order.custom.tipo_estadia == 'estadia_temporaria' || this.order.custom.tipo_estadia == 'estadia_permanente'">
      <div class="content-form-request-header">
        <h2>{{ $t('pages.hearth.description') }}</h2>
      </div>
      <div class="content-form-request-body">
        <div class="content-element">
          <div class="content-element-text">
            <p>{{ $t('pages.hearth.situation') }}</p>
          </div>
          <div class="content-element-input">
            <input type="text" name="saude" value="" placeholder="" v-model="order.custom.saude" />
          </div>
        </div>
        <div class="content-element">
          <div class="content-element-text">
            <p>{{ $t('pages.hearth.capacity') }}</p>
          </div>
          <div class="content-element">
            <b-form-group class="content-element-radio" v-slot="{ ariaDescribedby }">
              <b-form-radio-group id="radio-group-3" v-model="order.custom.capacidade_cognitiva"
                :aria-describedby="ariaDescribedby" name="radio-group-3" class="content-element-radio-input">
                <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="item1"
                  value="Boa">
                  <label for="item1">{{ $t('pages.hearth.good') }}</label>
                </b-form-radio>
                <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="item2"
                  value="Média">
                  <label for="item2">{{ $t('pages.hearth.media') }}</label>
                </b-form-radio>
                <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="item3"
                  value="Baixa">
                  <label for="Baixa">{{ $t('pages.hearth.low') }}</label>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <div class="content-element">
          <div class="content-element-text">
            <p>
              {{ $t('pages.hearth.autonomo') }}
            </p>
          </div>
          <b-form-group class="content-element-radio" v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-4" v-model="order.custom.utente_apoio"
              :aria-describedby="ariaDescribedby" name="radio-group-4" class="content-element-radio-input">
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="autonomo"
                value="autonomo">
                <label for="autonomo"> {{ $t('pages.hearth.autonomous') }}</label>
              </b-form-radio>
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby"
                id="necessita-de-apoio" value="necessita_de_apoio">
                <label for="necessita-de-apoio">{{ $t('pages.hearth.requiresupport') }}</label>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="content-element">
          <div class="content-element-text">
            <p>{{ $t('pages.hearth.hospitalized') }}</p>
          </div>
          <b-form-group class="content-element-radio" v-slot="{ ariaDescribedby }">
            <b-form-radio-group id="radio-group-5" v-model="order.custom.utente_hospitalizado"
              :aria-describedby="ariaDescribedby" name="radio-group-5" class="content-element-radio-input">
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="sim"
                value="sim">
                <label for="sim">{{ $t('generic.yes') }}</label>
              </b-form-radio>
              <b-form-radio class="content-element-radio-input" :aria-describedby="ariaDescribedby" id="nao"
                value="nao">
                <label for="nao">{{ $t('generic.no') }}</label>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="content-element">
          <div class="content-element-text">
            <p>{{ $t('pages.hearth.desccontact') }} </p>
          </div>
          <div class="content-element-input">
            <textarea name="mensagem" value="" v-model="order.mensagem"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="content-form-request-details">
      <div class="content-form-request-details-text">
        <p class="title">{{ $t('generic.date') }}</p>
        <p class="text">
          <template v-if="this.order.custom.tipo_estadia == 'estadia_temporaria'">
            <template v-if="data_checkin">
              {{ formatDate(data_checkin) }}
            </template>
            -
            <template v-if="data_checkout">
              {{ formatDate(data_checkout) }}
            </template>
          </template>
          <template v-if="this.order.custom.tipo_estadia == 'estadia_permanente'">
            <template v-if="data_checkin">
              {{ formatDate(data_checkin) }}
            </template>
            <template v-else> - </template>
          </template>
          <template v-else-if="!this.order.custom.tipo_estadia">
            -
          </template>
        </p>
      </div>
      <div class="content-form-request-details-text">
        <p class="title">{{ $t('pages.hearth.prices') }}</p>
        <p class="text">{{ hearthIntern.price | money }}</p>
      </div>
    </div>
    <div class="content-form-request-terms"
      v-if="this.order.custom.tipo_estadia == 'estadia_temporaria' || this.order.custom.tipo_estadia == 'estadia_permanente'">
      <div class="content-input-check">
        <input type="checkbox" name="terms-contact" id="terms-contact" value="true" unchecked-value="false" required
          v-model="order.custom.acepted_terms_contact" />
        <label for="terms-contact">
          {{ $t('pages.hearth.acceptterm') }}
        </label>
      </div>
      <div class="content-input-check">
        <input type="checkbox" name="terms" unchecked-value="false" required />
        <label>{{ $t('pages.hearth.readterm') }}
          <router-link tag="a" :to="{
    name: 'Politica',
    params: {
      slug: 'termos-e-condicoes-de-vendautilizacao',
    },
  }">
            {{ $t('pages.hearth.termandcondition') }}</router-link>
          {{ $t('pages.product.and') }}
          <router-link tag="a" :to="{
    name: 'Politica',
    params: { slug: 'politica-de-privacidade' },
  }">{{ $t('pages.hearth.privacypolitic') }}</router-link></label>
      </div>
      <div class="content-input-check">
        <input type="checkbox" name="terms-contents" id="terms-contents" value="true" unchecked-value="false"
          v-model="order.custom.acepted_terms_email" />
        <label for="terms-contents">{{ $t('pages.hearth.acceptmail') }}</label>
      </div>
      <div class="content-recaptcha">
        <vue-recaptcha ref="recaptcha" sitekey="6Ld-tb8pAAAAAAzuDktnRGysHO5ixbqA5F-jqwhl" />
      </div>
    </div>

    <div class="content-form-request-recaptch"
      v-if="this.order.custom.tipo_estadia == 'estadia_temporaria' || this.order.custom.tipo_estadia == 'estadia_permanente'">
    </div>
    <div class="content-form-request-btns"
      v-if="this.order.custom.tipo_estadia == 'estadia_temporaria' || this.order.custom.tipo_estadia == 'estadia_permanente'">
      <button class="btn-send uncheck" v-if="userIsLogged">{{ $t('pages.hearth.submitorder') }}</button>
      <button class="btn-send check" type="button" v-else @click="checkIsLogged()">{{ $t('pages.hearth.submitorder')
        }}</button>
      <button class="btn-clean-form" @click.prevent="clearForm()">
        {{ $t('pages.hearth.clearform') }}
      </button>
    </div>
  </form>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";
import moment from "moment";
export default {
  data() {
    return {
      order: {
        name: "",
        email: "",
        phone: "",
        morada: "",
        mensagem: "",
        nickname: "",
        origin: "lares",
        partner_id: this.partner_id,
        slug: this.slug,
        access_token: this.$store.getters["User/userIsLogged"],
        custom: {
          tipo_estadia: "",
          data_checkin: "",
          data_checkout: "",
          tipo_quarto: '',
          tipo_pessoa: "",
          nome_utente: "",
          idade_utente: "",
          parentesco: null,
          saude: "",
          capacidade_cognitiva: "",
          utente_apoio: "",
          utente_hospitalizado: "",
          acepted_terms_contact: false,
          acepted_terms_email: false,
        },
      },
      data_checkin: "",
      data_checkout: "",
      bedroom: [],
    };
  },
  components: {
    VueRecaptcha,
  },
  computed: {
    ...mapGetters("User", ["userIsLogged"]),
  },
  props: ["hearthIntern", "slug"],
  methods: {
    checkIsLogged() {
      this.setStorage();
      this.$fire({
        type: "info",
        title: "Atenção",
        text: "Para submeter um pedido de estadia, é necessário estar logado",
        timer: 3000,
        showConfirmButton: true,
      });
      this.$router.push({
        name: 'Login',
        params: {
          page: this.$route.path,
        }
      })
    },
    formObject() {
      this.$store.dispatch("setIsLoading", true);
      this.order.access_token = this.userIsLogged;
      // console.log('TOKEN', this.order.access_token);
      axios
        .post("/api/v1/setlead", this.order)
        .then((response) => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.response.success) {
            this.$fire({
              type: "success",
              title: "Sucesso",
              text: response.data.response.message.text,
              timer: 3000,
            });
            this.clearForm();
          } else {
            this.$fire({
              type: "error",
              title: "Erro",
              text: response.data.response.message.text,
              timer: 3000,
            });
            this.$router.push({ name: "Login" });
          }
        })
        .catch((error) => {
          this.$store.dispatch("setIsLoading", false);
          this.$fire({
            type: "error",
            title: "Erro",
            text: error.response.data.message,
            timer: 5000,
          }).then(() => {
            this.$router.push({ name: "Login" });
          });
        });
    },
    clearForm() {
      if (sessionStorage.getItem('order')) {
        sessionStorage.removeItem('order');
      }
      for (let i in this.order) {
        if (i === 'custom') {
          this.order.custom = {
            tipo_estadia: "",
            data_checkin: "",
            data_checkout: "",
            tipo_quarto: '',
            tipo_pessoa: "",
            nome_utente: "",
            idade_utente: "",
            parentesco: null,
            saude: "",
            capacidade_cognitiva: "",
            utente_apoio: "",
            utente_hospitalizado: "",
            acepted_terms_contact: false,
            acepted_terms_email: false,
          };
        } else if (i !== 'origin' && i !== 'custom' && i !== 'slug') {
          this.order[i] = "";
        }
      }
      this.data_checkin = "";
      this.data_checkout = "";
    },
    getBedroom() {
      axios
        .get("/api/v1/bedroom")
        .then((response) => {

          // console.log('bedroom', response.data);
          this.bedroom = response.data;
        }).catch(error => {
          console.log(error);
        })
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    setStorage() {
      sessionStorage.setItem('order', JSON.stringify(this.order));
    },
    changeInputDate($event, type) {
      if (type === 'check-in') {
        this.data_checkin = $event.target.value;
        this.order.custom.data_checkin = this.formatDate(this.data_checkin)
      }
      if (type === 'check-out') {
        this.data_checkout = $event.target.value;
        this.order.custom.data_checkout = this.formatDate(this.data_checkout)
      }
    }
  },
  mounted() {
    if (sessionStorage.getItem('order')) {
      let order = JSON.parse(sessionStorage.getItem('order') || '{}');
      this.order = order;

      // FORMAT DATE YYYY-MM-DD
      if (this.order.custom.data_checkin) {
        this.data_checkin = this.order.custom.data_checkin.split('/').reverse().join('-');
      }
      if (this.order.custom.data_checkout) {
        this.data_checkout = this.order.custom.data_checkout.split('/').reverse().join('-');
      }
    }
    this.getBedroom();
  },
};
</script>
<style lang="scss">
.content-form-request {
  padding: 60px 30px;
  background: #f3f8f4;

  @media (max-width: 991px) {
    padding: 30px 20px;
  }

  &-header {
    text-align: left;
    margin-bottom: 40px;

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }

    h2 {
      font-size: 28px;
      font-family: "Font Bold";
      color: var(--green-medium);
      margin-bottom: 0px;

      @media (max-width: 991px) {
        font-size: 20px;
      }
    }
  }

  &-body {
    .content-element {
      &-radio {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        @media (max-width: 991px) {
          flex-wrap: wrap;
          margin-bottom: 30px;
        }

        &-input {
          &:not(:last-child) {
            margin-right: 47px;

            @media (max-width: 991px) {
              margin-right: 20px;
            }
          }

          @media (max-width: 991px) {
            margin-bottom: 10px;
          }

          [type="radio"]:checked,
          [type="radio"]:not(:checked) {
            position: absolute;
            left: 3px;
            opacity: 0;
          }

          [type="radio"]:checked+label,
          [type="radio"]:not(:checked)+label {
            position: relative;
            padding-left: 40px;
            height: 30px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 20px;
            font-family: "Font Regular";
            color: var(--green-dark);
            margin-bottom: 0px;
            line-height: 1;

            @media (max-width: 991px) {
              padding-left: 30px;
              height: 20px;
              font-size: 16px;
            }
          }

          [type="radio"]:checked+label:before,
          [type="radio"]:not(:checked)+label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background: #fff;
            border: 1px solid #bebebe;

            @media (max-width: 991px) {
              width: 20px;
              height: 20px;
            }
          }

          [type="radio"]:checked+label:after,
          [type="radio"]:not(:checked)+label:after {
            content: "";
            width: 14px;
            height: 14px;
            background: var(--green-medium);
            position: absolute;
            top: 8px;
            left: 8px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;

            @media (max-width: 991px) {
              width: 12px;
              height: 12px;
              top: 4px;
              left: 4px;
            }
          }

          [type="radio"]:not(:checked)+label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
          }

          [type="radio"]:checked+label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }

      &-input-date {
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        @media (max-width: 991px) {
          margin-bottom: 30px;
        }

        input {
          width: 100%;
          height: 50px;
          background: var(--white);
          border: 1px solid #bebebe;
          border-radius: 10px;
          padding-left: 20px;
          font-size: 20px;
          font-family: "Font Regular";
          color: var(--green-dark);
          outline: none;

          @media (max-width: 991px) {
            height: 40px;
            font-size: 16px;
            border-radius: 5px;
            padding-left: 15px;
          }

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #b7b8b8;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #b7b8b8;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #b7b8b8;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: #b7b8b8;
          }

          // &::-webkit-inner-spin-button,
          // &::-webkit-calendar-picker-indicator {
          //     display: none;
          //     -webkit-appearance: none;
          //     opacity: 0;
          // }
        }

        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          background: #fff;
          max-width: 20px;
          pointer-events: none;

          @media (max-width: 991px) {
            right: 5px;
          }
        }
      }

      &-input {
        width: 100%;
        margin-bottom: 40px;

        @media (max-width: 991px) {
          margin-bottom: 30px;
        }

        input {
          width: 100%;
          height: 50px;
          background: var(--white);
          border: 1px solid #bebebe;
          border-radius: 10px;
          padding-left: 20px;
          font-size: 20px;
          font-family: "Font Regular";
          color: var(--green-dark);
          outline: none;

          @media (max-width: 991px) {
            height: 40px;
            font-size: 16px;
            border-radius: 5px;
            padding-left: 15px;
          }

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #b7b8b8;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #b7b8b8;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #b7b8b8;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: #b7b8b8;
          }
        }

        textarea {
          width: 100%;
          min-height: 210px;
          display: block;
          background: var(--white);
          border: 1px solid #bebebe;
          border-radius: 10px;
          padding-top: 20px;
          padding-left: 20px;
          font-size: 20px;
          font-family: "Font Regular";
          color: var(--green-dark);
          outline: none;
          resize: none;

          @media (max-width: 991px) {
            min-height: 150px;
            font-size: 16px;
            border-radius: 5px;
            padding-left: 15px;
          }

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #b7b8b8;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #b7b8b8;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #b7b8b8;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: #b7b8b8;
          }
        }
      }

      &-select {
        position: relative;
        width: 100%;
        margin-bottom: 40px;

        @media (max-width: 991px) {
          margin-bottom: 30px;
        }

        select {
          width: 100%;
          height: 50px;
          background: var(--white) url("../../../assets/img/hearth/polygon.png") right 0.75rem center/20px 15px no-repeat !important;
          border: 1px solid #bebebe;
          border-radius: 10px;
          padding-left: 20px;
          font-size: 20px;
          font-family: "Font Regular";
          color: var(--green-dark);
          outline: none;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          @media (max-width: 991px) {
            height: 40px;
            font-size: 16px;
            border-radius: 5px;
            padding-left: 15px;
          }
        }

        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          max-width: 15px;
          pointer-events: none;
        }
      }

      &-text {
        text-align: left;
        margin-bottom: 10px;

        @media (max-width: 991px) {
          margin-bottom: 20px;
        }

        p {
          font-size: 20px;
          font-family: "Font Regular";
          color: #555555;
          margin-bottom: 0;

          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
      }
    }
  }

  &-details {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    background: var(--green-medium);
    border-radius: 10px;
    margin-bottom: 45px;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 30px;
    }

    &-text {
      text-align: left;

      &:first-child {
        margin-right: 100px;

        @media (max-width: 991px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .title {
        font-size: 18px;
        font-family: "Font Regular";
        color: var(--white);
        margin-bottom: 0;

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }

      .text {
        font-size: 30px;
        font-family: "Font Bold";
        color: var(--white);
        margin-bottom: 0;

        @media (max-width: 991px) {
          font-size: 20px;
        }
      }
    }
  }

  &-recaptch {
    margin: 45px 0px;

    @media (max-width: 991px) {
      margin: 30px 0px;
    }
  }

  &-btns {
    .btn-send {
      width: 250px;
      height: 50px;
      background: var(--green-medium);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-family: "Font Regular";
      color: var(--white);
      margin: 0 auto 30px auto;
      transition: 500ms;

      @media (max-width: 991px) {
        width: 200px;
        height: 40px;
        font-size: 20px;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        background: var(--white);
        color: var(--green-medium);
      }
    }

    .btn-clean-form {
      font-size: 18px;
      font-family: "Font Regular";
      color: #b7b8b8;
      background: 0;
      border: 0;
      padding: 0;
      text-decoration: underline;
      text-decoration-color: #b7b8b8;
      transition: 500ms;

      @media (max-width: 991px) {
        font-size: 16px;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
}
</style>
