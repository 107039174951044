<template>
  <section class="content-service">
    <div class="fluid-container content-service-left">
      <div class="content-service-left-int container">
        <div class="content-service-left-int-text">
          <h3 class="content-service-left-int-text-title">
            {{ text1.title }}
          </h3>
          <p class="content-service-left-int-text-description">
            {{ text1.subtitle }}
          </p>
        </div>
        <div
          class="row row-cols-2 content-service-left-int-grid d-none d-lg-flex"
        >
          <router-link
            tag="a"
            class="col content-service-left-int-grid-int"
            v-for="c in cards3"
            :key="c.id"
            :to="{ name: 'Hearth', params: { slug: c.slug } }"
          >
            <div class="content-service-left-int-grid-int-text">
              <p>{{ c.title }}</p>
            </div>
            <img class="img-fluid" :src="`${c.banner}`" alt="" />
          </router-link>
        </div>
        <swiper
          :options="swiperProducts"
          class="content-service-left-int-grid d-lg-none"
        >
          <swiper-slide
            v-for="c in cards3"
            :key="c.id"
            class="content-service-left-int-grid-int"
          >
            <router-link
              tag="a"
              class="content-service-left-int-grid-int"
              :to="{ name: 'Hearth', params: { slug: c.slug } }"
            >
              <img class="img-fluid" :src="`${c.banner}`" alt="" />
              <div class="content-service-left-int-grid-int-text">
                <p>{{ c.title }}</p>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="fluid-container content-service-right">
      <div class="content-service-right-int container">
        <div
          class="row row-cols-2 content-service-right-int-grid d-none d-lg-flex"
        >
          <router-link
            tag="a"
            class="col content-service-right-int-grid-int"
            v-for="i in cards4"
            :key="i.id"
            :to="{ name: 'Communities', params: { slug: i.slug } }"
          >
            <div class="content-service-right-int-grid-int-text">
              <p>{{ i.title }}</p>
            </div>
            <img class="img-fluid" :src="`${i.banner}`" alt="" />
          </router-link>
        </div>
        <swiper
          :options="swiperProducts"
          class="content-service-right-int-grid d-lg-none"
        >
          <swiper-slide
            v-for="i in cards4"
            :key="i.id"
            class="content-service-right-int-grid-int"
          >
            <router-link
              tag="a"
              class="content-service-right-int-grid-int"
              :to="{ name: 'Communities', params: { slug: i.slug } }"
            >
              <div class="content-service-right-int-grid-int-text">
                <p>{{ i.title }}</p>
              </div>
              <img class="img-fluid" :src="`${i.banner}`" alt="" />
            </router-link>
          </swiper-slide>
        </swiper>

        <div class="content-service-right-int-text">
          <h3 class="content-service-right-int-text-title">
            {{ text2.title }}
          </h3>
          <p class="content-service-right-int-text-description">
            {{ text2.subtitle }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      cards3: [],
      cards4: [],
      text: [],
      text1: [],
      text2: [],
      swiperProducts: {
        slidesPerView: "auto",
        spaceBetween: 20,
        loop: false,
        speed: 800,
        watchOverflow: true,
        centeredSlides: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          992: {
            spaceBetween: 10,
          },
        },
      },
    };
  },
  async mounted() {
    axios.post("/api/v1/bannerlist").then((resp) => {
      if (!resp.data) {
        return;
      }
      this.data = resp.data[2];
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].position == "3") {
          this.cards3.push(this.data[i]);
        }
      }
      this.data2 = resp.data[3];
      for (let i = 0; i < this.data2.length; i++) {
        if (this.data2[i].position == "4") {
          this.cards4.push(this.data2[i]);
        }
      }
    });
    axios
      .get("/api/v1/alltexts")
      .then((response) => {
        if (!response.data) {
          return;
        }
        this.text = response.data;
        for (let i = 0; i < this.text.length; i++) {
          if (this.text[i].widget == 1) {
            this.text1 = this.text[i];
          }
          if (this.text[i].widget == 2) {
            this.text2 = this.text[i];
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.content-service-left {
  margin: 9rem 0;
  padding-top: 1rem;
  height: 683px;
  background-image: url("../../../assets/img/home/worker-consoling.png");
  background-repeat: no-repeat;
  background-position: left top;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1200px) {
    margin: 3rem 0;
  }
  @media screen and (max-width: 991px) {
    background-size: cover;
    margin: 0;
    height: 350px;
  }
  @media screen and (min-width: 1921px) {
    background-size: cover;
  }
  &-int {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 2rem;
    color: #fff;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      position: relative;
      justify-content: flex-start;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    &-text {
      text-align: left;
      max-width: 363px;
      padding-right: 0.5rem;
      @media screen and (max-width: 1300px) {
        max-width: 300px;
      }
      @media screen and (max-width: 991px) {
        max-width: 100%;
        padding: 3rem 0;
      }

      &-title {
        font-size: 50px;
        font-family: "Font Bold", sans-serif;
        margin-bottom: 3rem;
        @media screen and (max-width: 1300px) {
          font-size: 30px;
        }
      }
      &-description {
        align-items: left;
        font-size: 30px;
        font-family: "Font Regular", sans-serif;
        @media screen and (max-width: 1300px) {
          font-size: 25px;
        }
        @media screen and (max-width: 991px) {
          font-size: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    &-grid {
      @media screen and (max-width: 991px) {
        max-width: 100%;
        width: 100%;
        position: absolute;
        bottom: -100px;
        padding-left: 30px;
      }
      &-int {
        position: relative;
        color: #fff;
        padding-bottom: 25px;

        @media screen and (max-width: 991px) {
          max-width: 268px;
          height: 210px;
          position: relative;
          padding-bottom: 0;
        }

        &:hover,
        &:focus {
          color: #1b9e88;
          img {
            border: #1b9e88 solid 1px;
            background-color: #fff;
          }
        }
        img {
          background-color: #1b9e88;
          border-radius: 20px;
        }
        &-text {
          @media screen and (max-width: 991px) {
            position: absolute;
            top: -70px;
            left: 20px;
          }
        }
        p {
          position: absolute;
          text-align: left;
          font-size: 40px;
          padding: 30px;
          font-family: "Font Bold", sans-serif;
          @media screen and (max-width: 1300px) {
            font-size: 38px;
          }
          @media screen and (max-width: 991px) {
            font-size: 30px;
            padding: 0;
          }
        }
      }
    }
  }
}
.content-service-right {
  padding-top: 1rem;
  height: 683px;
  background-image: url("../../../assets/img/home/aerial-view-village.png");
  background-repeat: no-repeat;
  background-position: right top;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 991px) {
    margin: 145px 0 155px 0;
    height: 350px;
    background-size: cover;
  }
  @media screen and (min-width: 1921px) {
    background-size: cover;
  }
  &-int {
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 991px) {
      flex-direction: column-reverse;
      position: relative;
      justify-content: flex-end;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    &-text {
      color: #ffffff;
      text-align: left;
      max-width: 363px;
      padding-left: 3rem;
      @media screen and (max-width: 1300px) {
        max-width: 300px;
      }
      @media screen and (max-width: 991px) {
        max-width: 100%;
        padding: 3rem 0;
        margin: 0;
      }
      &-title {
        font-size: 50px;
        font-family: "Font Bold", sans-serif;
        margin-bottom: 3rem;
        @media screen and (max-width: 1300px) {
          font-size: 30px;
        }
      }
      &-description {
        align-items: left;
        font-size: 30px;
        font-family: "Font Regular", sans-serif;
        @media screen and (max-width: 1300px) {
          font-size: 25px;
        }
        @media screen and (max-width: 991px) {
          font-size: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    &-grid {
      @media screen and (max-width: 991px) {
        max-width: 100%;
        width: 100%;
        position: absolute;
        bottom: -100px;
        padding-left: 30px;
      }
      &-int {
        position: relative;
        color: #fff;
        padding-bottom: 25px;

        @media screen and (max-width: 991px) {
          max-width: 268px;
          height: 210px;
          position: relative;
          padding-bottom: 0;
        }

        &:hover,
        &:focus {
          color: #b49053;
          img {
            background-image: url("../../../assets/img/home/tree2.png");
            border: #b49053 solid 1px;
            background-color: #ffffff;
          }
        }
        img {
          background-color: #b49053;
          border-radius: 20px;
        }
        &-text {
          @media screen and (max-width: 991px) {
            position: absolute;
            top: -95px;
            width: 100%;
          }
        }
        p {
          position: absolute;
          text-align: left;
          font-size: 40px;
          padding: 30px;
          font-family: "Font Bold", sans-serif;
          @media screen and (max-width: 1300px) {
            font-size: 38px;
          }
          @media screen and (max-width: 991px) {
            padding: 20px;
            width: 100%;
            font-size: 30px;
            top: 10px;
          }
        }
      }
    }
  }
}
</style>
