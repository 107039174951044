<template>
  <aside
    class="aside-menu d-lg-none"
    :class="{ 'aside-menu-active': menuOpen }"
  >
    <div class="aside-menu-itens">
      <div class="aside-menu-itens-header">
        <div class="aside-menu-itens-collapse">
          <button class="aside-menu-itens-collapse-btn" v-b-toggle.collapse-1>
            <p>PT<span> (português)</span></p>
            <div>
              <svg
                width="30"
                height="30"
                aria-hidden="true"
                focusable="false"
                data-prefix="fal"
                data-icon="angle-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 256 512"
              >
                <path
                  d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                  class=""
                ></path>
              </svg>
            </div>
          </button>
          <b-collapse id="collapse-1">
            <div class="card-body">
              <a class="card-body-links" href="#"> Português (PT-BR) </a>
              <a class="card-body-links" href="#"> Português (PT-PT) </a>
            </div>
          </b-collapse>
        </div>
        <div class="content-menu">
          <a class="content-menu-item btn-menu" @click="setMenuOpen(false)">
            <!-- <span class="active"></span> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="24"
              viewBox="0 0 11 24"
            >
              <g transform="translate(-75.582 -17.632)">
                <text
                  transform="translate(75.582 36.632)"
                  fill="#338545"
                  font-size="18"
                  font-family="OpenSans-Regular, Open Sans"
                >
                  <tspan x="0" y="0">X</tspan>
                </text>
              </g>
            </svg>
          </a>
        </div>
      </div>
      <div class="aside-menu-itens-links">
        <router-link
          tag="a"
          :to="{ name: 'About' }"
          @click.native="setMenuOpen(false)"
          class="links"
        >
          <p>Quem somos</p>
        </router-link>
        <router-link
          tag="a"
          :to="{ name: 'Hearth' }"
          @click.native="setMenuOpen(false)"
          class="links"
        >
          <p>Booking</p>
        </router-link>
        <router-link
          tag="a"
          :to="{ name: 'Communities' }"
          @click.native="setMenuOpen(false)"
          class="links"
        >
          <p>Comunidades</p>
        </router-link>
        <router-link
          tag="a"
          :to="{ name: 'BlogList' }"
          @click.native="setMenuOpen(false)"
          class="links"
        >
          <p>Blog</p>
        </router-link>
      </div>
      <!--- BTN AND MENU CHAT -->
      <!-- <template :class="{ 'aside-menu-active': menuOpen }">
      <button class="btn-chat" @click="activeChat = !activeChat" v-if="userIsLogged">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="47.602" height="45.458" viewBox="0 0 47.602 45.458"><defs><clipPath id="a"><rect width="47.602" height="45.458" fill="#fff"/></clipPath></defs><g transform="translate(-8.118 -20.595)"><g transform="translate(8.118 20.595)" clip-path="url(#a)"><path d="M3.932,36.991a3.964,3.964,0,0,1-1.518-.306A3.846,3.846,0,0,1,0,33.074V3.907A3.911,3.911,0,0,1,3.907,0H37.9a3.911,3.911,0,0,1,3.907,3.907V27.413A3.911,3.911,0,0,1,37.9,31.32H12.031a2.055,2.055,0,0,0-1.465.609l-3.892,3.9a3.853,3.853,0,0,1-2.742,1.159M3.907,1.839A2.071,2.071,0,0,0,1.839,3.907V33.074a2.068,2.068,0,0,0,3.533,1.46l3.892-3.9a3.882,3.882,0,0,1,2.767-1.149H37.9a2.071,2.071,0,0,0,2.068-2.068V3.907A2.071,2.071,0,0,0,37.9,1.839Z" fill="#fff"/><path d="M93.154,70.5H92.144V81.71a6.213,6.213,0,0,1-6.205,6.205H62.375v4.636a2.487,2.487,0,0,0,2.487,2.487H86.393a2.487,2.487,0,0,1,1.761.731l3.239,3.25a2.487,2.487,0,0,0,4.248-1.755V72.987A2.487,2.487,0,0,0,93.154,70.5" transform="translate(-48.039 -54.297)" fill="#fff"/><path d="M63.407,46.985H44.673a.919.919,0,1,1,0-1.839H63.407a.919.919,0,1,1,0,1.839" transform="translate(-33.698 -34.77)" fill="#fff"/><path d="M57.358,73.3H44.673a.919.919,0,1,1,0-1.839H57.358a.919.919,0,1,1,0,1.839" transform="translate(-33.698 -55.039)" fill="#fff"/></g></g></svg>
        <p>Ajuda</p>
      </button>
      <div class="content-chat" :class="{'active-chat': activeChat}">
        <button class="content-chat-btn-close" @click="activeChat = !activeChat">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.953" height="20.953" viewBox="0 0 20.953 20.953"><path d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z" transform="translate(-7.523 -7.523)" fill="var( --green-medium)"/></svg>
        </button>
        <div class="content-chat-header">
          <img class="img-fluid" src="@/assets/img/icon-chat.jpg" alt="icon chat" title="icon chat"/>
          <h2>Em que podemos ajudar?</h2>
        </div>
        <form class="content-chat-form">
          <div class="content-input">
            <label>Setor</label>
            <div class="content-input-select">
              <select>
                <option selected disabled>Selecione o Setor</option>
                <option>Setor 1</option>
                <option>Setor 2</option>
                <option>Setor 3</option>
              </select>
              <img class="img-fluid" src="@/assets/img/icons/icon-arrow.png" alt="icon arrow down" title="icon arrow down"/>
            </div>
          </div>
          <div class="content-input">
            <label>Escreva a sua mensagem:</label>
            <textarea placeholder="Mensagem"></textarea>
          </div>
          <button class="btn-send">
            Enviar
          </button>
        </form>
      </div>
    </template> -->
      <!-------------------->
      <div class="content-buttons">
        <template v-if="userIsLogged">
          <router-link
            tag="a"
            :to="{ name: 'MyAccount' }"
            @click.native="setMenuOpen(false)"
            class="content-name-user"
          >
            <p>
              Bem vindo <span>{{ user.name }} {{ user.last_name }}</span>
            </p>
          </router-link>
          <router-link
            tag="a"
            :to="{ name: 'MyAccount' }"
            @click.native="setMenuOpen(false)"
            class="btn-login"
          >
            <p>A sua conta</p>
          </router-link>
          <button
            @click="
              logoff();
              setMenuOpen(false);
            "
            class="btn-login"
          >
            <p>Terminar sessão</p>
          </button>
        </template>
        <template v-else>
          <router-link
            tag="a"
            :to="{ name: 'PartnersInfo' }"
            @click.native="setMenuOpen(false)"
            class="btn-partners"
          >
            <p>Parceiros</p>
          </router-link>
          <router-link
            tag="a"
            :to="{ name: 'Login' }"
            @click.native="setMenuOpen(false)"
            class="btn-login"
          >
            <p>Iniciar sessão</p>
          </router-link>
        </template>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      activeChat: false,
    };
  },
  computed: {
    ...mapGetters(["menuOpen"]),
    ...mapGetters("User", ["userIsLogged", "user"]),
  },
  methods: {
    ...mapActions({
      setMenuOpen: "setMenuOpen",
    }),
    ...mapActions("User", ["accountLogout"]),
    logoff() {
      this.accountLogout()
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  accountLogout() {
    this.logout();
    this.setMenuOpen(false);
  },
};
</script>

<style lang="scss">
.aside-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 90%;
  background: #f3f8f4;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.8s ease-in-out;
  right: -100%;
  pointer-events: none;
  z-index: 1030;
  @media (max-width: 359px) {
    width: 100%;
  }
  &-active {
    right: 0;
    pointer-events: initial;
    .btn-chat {
      right: 0 !important;
      top: 50% !important;
      border-radius: 10px 0 0 10px !important;
      width: 200px !important;
      height: 87px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      gap: 1rem !important;
      background: var(--green-medium) !important;
      svg {
        max-width: 48px !important;
        max-height: 45px !important;
      }
      p {
        font-size: 24px !important;
        font-family: "Font Bold" !important;
      }
    }
  }
  &-itens {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 20px;
      width: 100%;
      img {
        max-width: 120px;
      }
      .content-menu {
        display: flex !important;
        justify-content: flex-end !important;
        &-item {
          width: 47px;
          height: 45px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          text-decoration: none !important;
          background: var(--white);
          border: 1px solid var(--green-medium);
          border-radius: 10px;
          padding-top: 0;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0;
            &::before,
            &::after {
              width: 20px;
            }
          }
        }
      }
    }
    &-links {
      width: 100%;
      margin: 30px 0 100px 0;
      a {
        display: block;
        font-family: "Font Regular";
        color: var(--green-medium) !important;
        font-size: 20px;
        margin-bottom: 0;
        width: 100%;
        padding: 15px 30px;
        transition: 500ms;
        text-decoration: none;
        text-align: left;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      .links {
        display: flex;
        align-items: flex-end;
        p {
          width: 100%;
          font-family: "Font Semibold";
          color: var(--green-dark) !important;
          font-size: 24px;
          margin-bottom: 0;
          text-align: right;
        }
        &-icon {
          height: 56px;
          width: 56px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--main-color2);
          border-radius: 100%;
          margin-left: 10px;
          svg {
            fill: var(--white);
            max-width: 30px;
          }
        }
      }
    }
    .card {
      border: 0;
      background: 0;
      border-radius: 0px;
      &-header {
        border: 0;
        background: 0;
        border-radius: 0px;
        padding: 0;
      }
      .btn-link {
        padding: 15px;
        padding-bottom: 0px;
        font-size: 20px;
        color: var(--green-dark);
        font-family: "Font Bold";
        border: 0;
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: none;
        & > div {
          transition: 500ms;
          margin-left: 10px;
          svg {
            fill: var(--green-medium);
            transition: 500ms;
          }
        }
      }
      [aria-expanded="true"] {
        opacity: 0.8;
        & > div {
          transform: rotate(180deg);
        }
      }
      &-body {
        padding: 0;
        &-links {
          display: flex;
          align-items: center;
          height: 40px;
          width: 100%;
          padding-left: 30px;
          color: var(--green-dark);
          font-size: 14px !important;
          letter-spacing: 1.5px;
          font-family: "Font Light";
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
          }
        }
      }
    }
    &-collapse {
      &-btn {
        font-family: "Font Regular";
        color: var(--green-dark) !important;
        background: 0;
        border: 0;
        font-size: 20px;
        display: block;
        width: 100%;
        transition: 500ms;
        text-align: left;
        display: flex;
        align-items: center;
        padding-left: 10px;
        p {
          padding-bottom: 0;
          margin-bottom: 0;
          font-size: 18px;
          font-family: "Font Bold";
          color: var(--green-medium);
          span {
            font-size: 18px;
            font-family: "Font Regular", sans-serif;
            color: var(--green-dark);
          }
        }

        & > div {
          transition: 500ms;
          margin-left: 7px;
          svg {
            fill: var(--green-medium);
            transition: 500ms;
          }
        }
        &[aria-expanded="true"] {
          opacity: 0.8;
          & > div {
            transform: rotate(180deg);
          }
        }
      }
    }
    .content-name-user {
      display: block;
      text-align: right;
      padding: 15px 30px;
      transition: 500ms;
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.8;
      }
      p {
        font-size: 20px;
        font-family: "Font Regular";
        color: #878787;
        line-height: 1.2;
        max-width: 100%;
        margin-bottom: 0;
        span {
          display: block;
          font-family: "Font Bold";
          color: var(--green-medium);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .btn-partners {
      width: 156px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--white);
      border: 1px solid var(--gray);
      border-radius: 10px;
      margin: 20px 30px;
      transition: 500ms;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.6;
      }
      p {
        font-size: 22px;
        font-family: "Font Regular";
        color: var(--gray);
        margin-bottom: 0;
      }
    }
    .btn-login {
      width: 200px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--gray);
      border: 1px solid var(--gray);
      border-radius: 10px;
      margin: 15px 30px;
      transition: 500ms;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.6;
      }
      p {
        font-size: 22px;
        font-family: "Font Regular";
        color: var(--white);
        margin-bottom: 0;
      }
    }
  }
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  .btn-logout {
    all: unset;
    color: #878787;
    margin-top: 10px;
  }
  .account {
    text-decoration: none;
    color: #878787;
  }
}
.dropdown:hover .dropdown-content {
  display: block;
}
.content-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 50px;
}
</style>
