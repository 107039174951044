import store from "@/store";
import Vue from "vue";

export async function protectedPage(to, from, next) {
  try {
    let user = await store.getters["User/userIsLogged"];
    // validacao expire
    // if(moment().now() > user.date_token_expire_at){
    //   user = null;
    //   localStorage.removeItem("token");
    //
    // return;
    if (typeof user != "undefined" && Object.keys(user).length > 0) {
      next();
    } else {

      Vue.fire({
        type: 'error',
        title: 'Erro',
        text: 'Você precisa estar logado para acessar esta página.',
        timer: 3000,
      });
      next({
        name: "Login",
      });
    }
  } catch (e) {
    Vue.fire({
      type: 'error',
      title: 'Erro',
      text: 'Você precisa estar logado para acessar esta página.',
      timer: 3000,
    });
    next({
      name: "Login",
    });
  }
}
export async function hiddenPageLogin(to, from, next) {
  try {
    let user = await store.getters["User/userIsLogged"];
    // Se o usuário estiver logado, redirecione para "MyAccount"
    if (typeof user != "undefined" && Object.keys(user).length > 0) {
      next({ name: "MyAccount" });
    } else {
      // Se o usuário não estiver logado, prossegue para a página de login ou para a próxima rota pretendida
      next();
    }
  } catch (e) {
    // Em caso de erro, também prossegue para a próxima rota pretendida.
    // Você pode ajustar isso para lidar com erros específicos se necessário.
    next();
  }
}

