<template>
  <div class="content-sugestion-grid-item" >
    <a @click="goToPage(product)" class="content-sugestion-grid-item-link">
      <div class="content-sugestion-grid-item-discount" v-if="product.discount">
        <p>{{ product.discount }}</p>
      </div>
      <div class="content-sugestion-grid-item-image">
        <img class="img-fluid" :src="product.image" alt="product" />
      </div>
      <div class="content-sugestion-grid-item-description">
        <p class="content-sugestion-grid-item-description-title">
          {{ product.name }}
        </p>
        <template v-if="product.iva_promotional_price > 0">
          <h4 class="content-sugestion-grid-item-description-oldprice">
            {{ product.iva_price | money }}
          </h4>
          <h3 class="content-sugestion-grid-item-description-newprice">
            {{ product.iva_promotional_price | money }}
          </h3>
        </template>
        <template v-else>
          <h3 class="content-sugestion-grid-item-description-newprice">
            {{ product.iva_price | money }}
          </h3>
        </template>
      </div>
    </a>
    <template v-if="this.$route.name !== 'PartnerStore'">
      <div class="content-sugestion-grid-item-btn">
        <button class="btn-add-product" @click="goToPage(product)" v-if="
          this.$route.name === 'Home' ||
          this.$route.name === 'PartnerLandingPage'
        ">
          <p>{{ $t("generic.consult") }}</p>
        </button>
        <button class="btn-add-product" @click="addProduct()" v-else>
          <p>{{ $t("pages.product.add") }}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.333" viewBox="0 0 24 21.333">
            <path
              d="M23,2.667H5.387L5.04.816A1,1,0,0,0,4.057,0H.5A.5.5,0,0,0,0,.5V.833a.5.5,0,0,0,.5.5H3.78L6.694,16.873a2.667,2.667,0,1,0,4.282.461h6.049a2.667,2.667,0,1,0,4.168-.578,1,1,0,0,0-.97-.755H7.887l-.5-2.667H21.194a1,1,0,0,0,.979-.8l1.805-8.667A1,1,0,0,0,23,2.667Zm-13,16a1.333,1.333,0,1,1-1.333-1.333A1.335,1.335,0,0,1,10,18.667ZM19.333,20a1.333,1.333,0,1,1,1.333-1.333A1.335,1.335,0,0,1,19.333,20Zm1.59-8H7.137l-1.5-8H22.59Z" />
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["product"],
  data() {
    return {
      access_token: "",
    };
  },
  computed: {
    ...mapGetters("User", ["userIsLogged"]),
    ...mapActions("Cart", ["setCart"]),
  },
  methods: {
    goToPage(product) {
      this.itemId = product.uuid;
      this.slug = product.entity_slug;
      this.$router.push({
        name: "ProductIntern",
        params: { slug: this.slug, product: this.product.slug },
      });
    },
    addProduct() {
      // this.product.quantity = 1;
      this.$store.dispatch("Cart/setCart", {
        product: this.product,
        access_token: this.access_token,
      });
    },
  },
  mounted() {
    this.access_token =
      localStorage.getItem("token") ||
      localStorage.getItem("visitor_token") ||
      null;
  },
};
</script>

<style lang="scss">
.content-sugestion {
  &-grid {
    &-item {
      position: relative;
      background-color: #ffffff;
      border: 1px solid #e7e7e7;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      transition: 500ms;

      @media (max-width: 991px) {
        margin-bottom: 30px;
      }

      &:hover,
      &:focus {
        cursor: pointer;

        .content-sugestion-grid-item-link {
          opacity: 0.8;
        }
      }

      &-image {
        width: 100%;
        height: 226px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-link {
        display: block;
        width: 100%;
        padding: 20px 20px 0px 20px;
        transition: 500ms;
      }

      &-discount {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 110px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e69c5a;
        border: 1px solid #e69c5a;
        border-radius: 10px;
        transition: 500ms;

        p {
          font-size: 20px;
          font-family: "Font Bold";
          color: var(--white);
          margin-bottom: 0px;
        }
      }

      &-description {
        text-align: left;
        padding: 20px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-title {
          font-family: "Font Regular";
          font-size: 18px;
          color: var(--green-dark);
          height: 80px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 10px;
        }

        &-oldprice {
          font-size: 18px;
          font-family: "Font Regular";
          color: #878787;
          text-decoration: line-through;
          margin-bottom: 0;
        }

        &-newprice {
          font-family: "Font Bold";
          font-size: 22px;
          color: var(--green-medium);
          margin-bottom: 0;
        }
      }

      &-btn {
        width: 100%;
        padding: 20px;

        .btn-add-product {
          width: 100%;
          max-width: 194px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--white);
          border: 1px solid var(--green-medium);
          border-radius: 10px;
          margin: 0 auto;
          transition: 500ms;

          p {
            font-size: 22px;
            font-family: "Font Regular";
            color: var(--green-medium);
            margin-right: 11px;
            margin-bottom: 0px;
            transition: 500ms;
          }

          svg {
            fill: var(--green-medium);
            transition: 500ms;
          }

          &:hover,
          &:focus {
            background: var(--green-medium);

            p {
              color: var(--white);
            }

            svg {
              fill: var(--white);
            }
          }
        }
      }
    }
  }
}
</style>
