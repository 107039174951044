export default [
    {
        path: "/partner-store",
        name: "PartnerStore",
        component: () => import(/* webpackChunkName: "Partners" */ "@/views/Partners/views/PartnerStore.vue"),
    },
    {
        path: "/partners-info",
        name: "PartnersInfo",
        component: () => import(/* webpackChunkName: "Partners" */ "@/views/Partners/views/PartnersInfo.vue"),
    }
]