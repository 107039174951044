<template>
  <div class="content-text-default container" v-if="short_description">
    <p id="text-description" style="line-height: 38px" :class="{ 'text-line-clamp': isLineClamp }"
      v-html="short_description"></p>
    <button class="btn-read-more" v-show="btnReadMore" @click="isLineClamp = !isLineClamp">
      <template v-if="isLineClamp">
        {{ $t('generic.viewmore') }}
      </template>
      <template v-else>
        {{ $t('generic.seeLess') }}
      </template>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLineClamp: false,
      btnReadMore: false,
      executedLineClamp: false,
    }
  },
  props: ['short_description'],
  methods: {
    getLineClamp() {
      const el = document.getElementById('text-description');
      if (el) {
        const divHeight = +el.offsetHeight;
        const lineHeight = +el.style.lineHeight.replace('px', '');
        const lines = divHeight / lineHeight;
        if (lines > 5) {
          this.isLineClamp = true;
          this.btnReadMore = true;
        } else {
          this.isLineClamp = false;
          this.btnReadMore = false;
        }
        this.executedLineClamp = true;
      }
    }
  },
  updated() {
    this.$nextTick(() => {
      if (!this.executedLineClamp) {
        this.getLineClamp();
      }
    });
  }
}
</script>

<style lang="scss">
.content-text-default {
  text-align: justify;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    margin-top: 80px;
    margin-bottom: 0;
  }

  p {
    font-size: 24px;
    font-family: 'Font Regular';
    color: var(--gray-dark);
    word-break: break-word;
  }

  .btn-read-more {
    width: 112px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border: 1px solid var(--green-medium);
    border-radius: 10px;
    font-size: 18px;
    font-family: 'Font Regular';
    color: var(--green-medium);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
    margin-top: 15px;
    transition: 500ms;

    &:hover,
    &:focus {
      cursor: pointer;
      background: var(--green-medium);
      color: var(--white);
    }
  }
}

.text-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
