export default {
	userIsLogged(state) {
		return state.token;
	},
	user: state => {
		if (state.user) {
			return state.user;
		}
		const storedUser = localStorage.getItem('user');
		console.log('storedUser', storedUser);
		return (storedUser && storedUser !== 'undefined') ? JSON.parse(storedUser) : {};
	},
	userFavorite(state) {
		return state.userFavorite;
	},
}