<template>
  <div class="btn">
    <div
      v-if="userIsLogged && !cartPage"
      class="floating-cart-button"
      :class="{ disabled: !cartHasItems }"
      @click="toggleCart"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47.6"
        height="42.311"
        viewBox="0 0 47.6 42.311"
      >
        <g
          id="Grupo_2014"
          data-name="Grupo 2014"
          transform="translate(-892.573 -616.156)"
        >
          <path
            id="Caminho_2763"
            data-name="Caminho 2763"
            d="M45.616,5.289H10.684L10,1.618A1.983,1.983,0,0,0,8.046,0H.992A.992.992,0,0,0,0,.992v.661a.992.992,0,0,0,.992.992H7.5l5.779,30.82a5.289,5.289,0,1,0,8.493.913h12a5.289,5.289,0,1,0,8.266-1.146,1.985,1.985,0,0,0-1.924-1.5H15.642l-.992-5.289H42.035a1.983,1.983,0,0,0,1.942-1.579L47.558,7.677A1.983,1.983,0,0,0,45.616,5.289Z"
            transform="translate(892.573 616.156)"
            fill="#fff"
          />
        </g>
      </svg>
      <p>{{ counterItems }} Und</p>
    </div>
    <router-link tag="a" :to="{ name: 'FaqClients' }">
      <div class="btn-help">
        <img src="../../assets/img/help.png" alt="" />
        <p>{{ $t("pages.hearth.help") }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("User", ["userIsLogged"]),
    ...mapGetters("Cart", ["cart"]),
    cartHasItems() {
      return this.cart.quantity > 0;
    },
    counterItems() {
      return this.cart.quantity;
    },
    cartPage() {
      return this.$route.name === "Cart";
    },
  },
  methods: {
    ...mapActions({
      setActiveCart: "setActiveCart",
    }),
    toggleCart() {
      if (this.cartHasItems) {
        this.setActiveCart(true);
      } else {
        console.log("Carrinho vazio");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  gap: 20px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.floating-cart-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #e69c5a;
  width: 130px;
  border-radius: 10px 0 0 10px;
  height: 130px;
  position: fixed;
  bottom: 250px;
  right: 0;
  cursor: pointer;

  @media (max-width: 991px) {
    bottom: 22%;
    width: 80px;
    height: 80px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  p {
    font-size: 24px;
    font-family: "Font Bold";
    color: #fff;
    margin-bottom: 0;
    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
}

.floating-cart-button.disabled {
  display: none;
  cursor: default;
  pointer-events: none;
}

.btn-help {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #338545;
  width: 130px;
  border-radius: 10px 0 0 10px;
  height: 130px;
  position: fixed;
  bottom: 100px;
  right: 0;
  cursor: pointer;
  z-index: 899;

  @media (max-width: 991px) {
    bottom: 10%;
    width: 80px;
    height: 80px;
    padding: 20px 0;
    img {
      width: 30px;
      height: 30px;
    }
  }

  p {
    font-size: 24px;
    font-family: "Font Bold";
    color: #fff;
    margin-bottom: 0;

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
}
</style>
