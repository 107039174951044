import axios from "axios";
import { baseApiUrl } from "@/global.js";

export default {
	namespaced: true,
	state: {
		filterList: [],
	},
	getters: {
		filterList(state) {
			return state.filterList;
		},
	},
	mutations: {
		setFilterList(state, filterList) {
			state.filterList = filterList;
		},
	},
	actions: {
		async setFilterList({ dispatch, commit }, slug, family, subfamily) {
			dispatch("setIsLoading", true, { root: true });
			await axios.post(`${baseApiUrl}/ecommerce/categoriestore`,slug, family, subfamily ).then(resp => {
				commit('setFilterList', resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
	}

}
