<template>
  <div class="content-filter-header" :class="{ 'active-filter': activeFilter }">
    <div class="content-filter-header-itens container">
      <div class="content-elements">
        <div class="content-elements-item" @click="toggleClick({ 'category': isCategory })">
          <button class="content-elements-item-btn">
            {{ $t('pages.hearth.categories') }}
            <svg :style="[
    isCategory
      ? { transform: 'rotate(180deg)' }
      : { transform: 'none' },
  ]" xmlns="http://www.w3.org/2000/svg" width="16" height="8.5" viewBox="0 0 16 8.5">
              <path d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z" transform="translate(0.5 0.5)" fill="none" stroke="#338545"
                stroke-linejoin="round" stroke-width="1" />
            </svg>
          </button>
          <div class="content-elements-item-input">
            <input type="text" ref="inputCategories" name="input-search-categories" v-on:input="inputCategories($event)"
              :value="inputSearchCategory" :placeholder="$t('pages.hearth.search')" />
          </div>
        </div>
        <div class="content-elements-options" :class="{ 'active-filter': isCategory }">
          <div class="content-elements-options-div w-100">
            <div class="content-elements-options-div-title">
              <p>{{ $t('pages.hearth.categories') }}</p>
            </div>
            <div class="content-elements-options-div-body">
              <template v-for="category in categories">
                <template>
                  <button class="content-elements-options-div-btn" :key="category.idCategory"
                    @click=" (inputSearchCategory = category.name, isInputSearchCategory = true, isInputSearchDemand = false, inputSearchDemand = '', inputSearchCounty = ''), (setIsCategory(!isCategory)), filterObject(category)">
                    {{ category.name }}
                  </button>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="content-elements" :class="{ 'desactive-element': !isInputSearchCategory }">
        <div class="content-elements-item" @click="toggleClick({ 'demand': isDemand })">
          <button class="content-elements-item-btn">
            {{ $t('pages.hearth.whatsearch') }}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8.5" viewBox="0 0 16 8.5">
              <path d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z" transform="translate(0.5 0.5)" fill="none" stroke="#338545"
                stroke-linejoin="round" stroke-width="1" />
            </svg>
          </button>
          <div class="content-elements-item-input">
            <input type="text" ref="inputDemand" name="input-search-demand" v-on:input="inputDemand($event)"
              :value="inputSearchDemand" :placeholder="$t('pages.hearth.search')" />
          </div>
        </div>
        <div class="content-elements-options" :class="{ 'active-filter': isDemand }">
          <div class="content-elements-options-div" v-for="demand in demands" :key="demand.id">
            <div class="content-elements-options-div-title">
              <p>{{ demand.name }}</p>
            </div>
            <template v-for="(typeDemand, index) in demand.types">
              <template>
                <button class="content-elements-options-div-btn" :key="index"
                  @click=" (inputSearchDemand = typeDemand.name, isInputSearchDemand = true, inputSearchCounty = ''), (setIsDemand(!isDemand)), filterObjectTwo(typeDemand, demand.type)">
                  {{ typeDemand.name }}
                </button>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div class="content-elements" :class="{ 'desactive-element': !isInputSearchCategory || !isInputSearchDemand }">
        <div class="content-elements-item" @click="toggleClick({ 'county': isCounty })">
          <button class="content-elements-item-btn">
            {{ $t('pages.hearth.county') }}
            <svg :style="[
    isCounty
      ? { transform: 'rotate(180deg)' }
      : { transform: 'none' },
  ]" xmlns="http://www.w3.org/2000/svg" width="16" height="8.5" viewBox="0 0 16 8.5">
              <path d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z" transform="translate(0.5 0.5)" fill="none" stroke="#338545"
                stroke-linejoin="round" stroke-width="1" />
            </svg>
          </button>
          <div class="content-elements-item-input">
            <input type="text" ref="inputCounty" name="input-search-county" v-on:input="inputCounty($event)"
              :value="inputSearchCounty" :placeholder="$t('pages.hearth.search')" />
          </div>
        </div>
        <div class="content-elements-options" :class="{ 'active-filter': isCounty }">
          <div class="content-elements-options-div w-100">
            <div class="content-elements-options-div-title">
              <p>{{ $t('pages.hearth.counties') }}</p>
            </div>
            <div class="content-elements-options-div-body">
              <template v-for="(county, index) in countys">
                <template>
                  <button class="content-elements-options-div-btn" :key="index"
                    @click="(inputSearchCounty = county.name, isInputSearchCounty = true), (setIsCounty(!isCounty)), filterObjectThree(county)">
                    {{ county.name }}
                  </button>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
      <button class="btn-search" @click="sendFilter()" :class="{ 'desactive-element': !isInputSearchCategory }">
        {{ $t('pages.hearth.search') }}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M23.835,21.979l-5.69-5.69a.556.556,0,0,0-.4-.164h-.619a9.748,9.748,0,1,0-1,1v.619a.576.576,0,0,0,.164.4l5.69,5.69a.563.563,0,0,0,.8,0l1.059-1.059A.563.563,0,0,0,23.835,21.979ZM9.75,17.249a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,9.75,17.249Z" />
        </svg>
      </button>
      <div class="div-filter d-lg-none">
        <button class="div-filter-btn-close" @click="setActiveFilter(false)">
          <p>{{ $t('pages.btn.btnClose') }}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15.5" viewBox="0 0 29 15.5">
            <path id="Union_24" data-name="Union 24" d="M27,0,13.5,13.5ZM0,0,13.5,13.5Z"
              transform="translate(28 14.5) rotate(180)" fill="none" stroke="#555" stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MixinFilterHeader from "@/mixins/filterHeader"

export default {
  mixins: [MixinFilterHeader],
  computed: {
    ...mapGetters(["isDesktop", "activeFilter", "isCategory", "isDemand", "isCounty"]),
    ...mapGetters({
      isCategory: 'isCategory',
      isDemand: 'isDemand',
      isCounty: 'isCounty'
    })
  },
  methods: {
    ...mapActions(["setActiveFilter", "setIsCategory", "setIsDemand", "setIsCounty"]),
  },
};
</script>

<style lang="scss">
.content-filter-header {
  width: 100%;
  height: 100px;
  background: #f3f8f4;
  top: 62px;

  @media (max-width: 991px) {
    position: fixed;
    z-index: 1;
    height: 100%;
    padding: 20px 0px;
    display: none;
    transition: 800ms ease-in-out;
  }

  &-itens {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: inherit;
      align-items: flex-start;
    }

    .div-filter {
      width: 100%;
      padding-bottom: 15px;
      margin-bottom: 15px;

      &-btn-close {
        border: none;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        padding-top: 10px;
        text-decoration: none !important;
        background: #f3f8f4;
        padding: 0;
        margin: 20px 0;
        gap: 10px;

        img {
          width: 27px;
          height: 14px;
        }

        p {
          font-size: 18px;
          color: #555;
          font-family: "Font Regular", sans-serif;
          text-align: center;
          margin-bottom: 0px;
        }
      }
    }

    .content-elements {
      position: relative;
      text-align: left;

      &:nth-child(2) {
        .content-elements-options {
          width: 680px;

          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }

      &:nth-child(3) {
        .content-elements-options {
          width: 465px;

          @media (max-width: 991px) {
            width: 100%;
          }

          &-div-btn {

            // width: 167px;
            @media (max-width: 991px) {
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 991px) {
        width: 100%;
        margin-bottom: 20px;
      }

      &-item {
        cursor: pointer;

        &-btn {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 24px;
          font-family: "Font Bold";
          color: var(--green-medium);
          background: 0;
          border: 0;
          padding: 0;
          margin-bottom: 0;

          @media screen and (max-width: 991px) {
            font-size: 30px;
          }

          svg {
            margin-left: 10px;
            transition: 500ms;
          }
        }

        &-input {
          input {
            outline: none;
            border: 0;
            background: 0;
            padding: 0;
            font-size: 18px;
            font-family: "Font Bold";
            color: var(--green-dark);

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: var(--green-dark);
              font-family: "Font Regular";
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: var(--green-dark);
              font-family: "Font Regular";
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: var(--green-dark);
              font-family: "Font Regular";
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: var(--green-dark);
              font-family: "Font Regular";
            }

            @media screen and (max-width: 991px) {
              font-size: 20px;
            }
          }
        }

        &-selected {
          p {
            font-size: 18px;
            font-family: "Font Bold";
            color: var(--green-dark);
            margin-bottom: 0;
          }
        }
      }

      &-options {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        top: 100%;
        max-width: 800px;
        max-height: 305px;
        background: #f3f8f4;
        // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 10px 10px;
        padding-bottom: 25px;
        opacity: 0;
        pointer-events: none;
        z-index: 1;
        overflow-y: auto;
        transition: 500ms;

        @media (max-width: 991px) {
          width: 100%;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }

        &-div {
          display: flex;
          flex-direction: column;
          width: 50%;
          padding-top: 25px;

          @media (max-width: 991px) {
            width: 100%;
          }

          &-title {
            padding: 0px 30px;
            margin-bottom: 10px;

            p {
              font-size: 20px;
              font-family: "Font Regular";
              color: var(--gray);
              margin-bottom: 0;
            }
          }

          &-body {
            column-count: 2;

            @media (max-width: 991px) {
              column-count: initial;
            }
          }

          &-btn {
            font-size: 20px;
            font-family: "Font Regular";
            color: var(--green-dark);
            background: 0;
            border: 0;
            padding: 10px 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            width: 100%;
            transition: 500ms;

            &:hover,
            &:focus {
              background: var(--green);
              color: var(--white);
            }
          }
        }
      }
    }

    .btn-search {
      width: 190px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--green-medium);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      font-size: 25px;
      font-family: "Font Regular";
      color: var(--white);
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      transition: 500ms;

      &:hover,
      &:focus {
        cursor: pointer;
        background: var(--white);
        color: var(--green);

        svg {
          fill: var(--green);
        }
      }

      svg {
        margin-left: 10px;
        fill: var(--white);
        transition: 500ms;
      }

      @media (max-width: 991px) {
        width: 100%;
        max-width: 354px;
        height: 74px;
        border-radius: 15px;
        font-size: 25px;
        margin: 30px auto 0px auto;

        svg {
          max-width: 27px;
        }
      }
    }
  }
}

.active-filter {
  display: flex !important;
  opacity: 1 !important;
  pointer-events: initial !important;
}

.teste {
  position: absolute;
  top: 0;
  right: 90px;
  width: 30px;
  height: 2px;
  background: var(--green-medium);
}

.desactive-element {
  pointer-events: none;
  opacity: 0.8;
}
</style>
