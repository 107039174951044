<template>
  <footer>
    <div class="row content-footer">
      <section class="col-lg-3 content-footer-elements">
        <div class="contacts">
          <div class="content-footer-elements-img">
            <img src="@/assets/img/logo-geras.png" alt="logo" class="img-fluid" />
          </div>
          <template v-if="company.phone && company.phone.length > 0">
            <a class="content-footer-elements-attendance" :href="numberPhone(phone.number_phone)"
              v-for="(phone, index) in company.phone" :key="index">
              <div class="content-footer-elements-attendance-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.827" height="21.827" viewBox="0 0 21.827 21.827">
                  <path id="Path_59" data-name="Path 59"
                    d="M1.025,1.021l4.3-.993A1.336,1.336,0,0,1,6.848.8L8.831,5.425a1.337,1.337,0,0,1-.379,1.556l-2.3,1.88a14.92,14.92,0,0,0,6.8,6.8l1.88-2.3a1.337,1.337,0,0,1,1.556-.379l4.621,1.982a1.33,1.33,0,0,1,.772,1.522L20.8,20.784a1.331,1.331,0,0,1-1.3,1.036,19.5,19.5,0,0,1-19.5-19.5A1.324,1.324,0,0,1,1.025,1.021ZM19.471,20.456l.98-4.246-4.578-1.961-2.536,3.1A16.472,16.472,0,0,1,4.465,8.481l3.1-2.536L5.608,1.366l-4.246.98A18.129,18.129,0,0,0,19.471,20.456Z"
                    transform="translate(0.006 0.006)" fill="#fff" />
                </svg>
                <h5>{{ phone.phone }}</h5>
              </div>
              <div class="content-footer-elements-attendance-item">
                <h2>{{ phone.number_phone }}</h2>
              </div>
              <div class="content-footer-elements-attendance-item">
                <p>{{ phone.hours }}</p>
              </div>
            </a>
          </template>
          <div class="content-footer-elements-email" v-if="company.email">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.035" height="16.526" viewBox="0 0 22.035 16.526">
              <path id="Path_60" data-name="Path 60"
                d="M19.969,64H2.066A2.066,2.066,0,0,0,0,66.066V78.46a2.066,2.066,0,0,0,2.066,2.066h17.9a2.066,2.066,0,0,0,2.066-2.066V66.066A2.066,2.066,0,0,0,19.969,64Zm-17.9,1.377h17.9a.691.691,0,0,1,.689.689v1.782c-.943.8-2.29,1.894-6.481,5.22-.727.577-2.16,1.967-3.159,1.95-1,.017-2.436-1.373-3.159-1.95-4.192-3.327-5.539-4.424-6.481-5.22V66.066A.691.691,0,0,1,2.066,65.377Zm17.9,13.772H2.066a.691.691,0,0,1-.689-.689V69.638c.981.8,2.531,2.049,5.625,4.506.882.706,2.44,2.259,4.015,2.251,1.567.013,3.112-1.528,4.015-2.251,3.094-2.457,4.644-3.7,5.625-4.506V78.46A.691.691,0,0,1,19.969,79.149Z"
                transform="translate(0 -64)" fill="#fff" />
            </svg>
            <p>{{ company.email }}</p>
          </div>
          <div class="content-footer-elements-nl">
            <h4>Newsletter</h4>
            <!-- <form @submit.prevent="sendNl()">
              <div class="input-group content-footer-elements-nl-input">
                <input type="text" class="form-control" placeholder="O seu Email" v-model="nl.email">
              </div>
              <div class="content-check">
                <div class="content-check-checkbox">
                  <div class="content-check-checkbox-terms">
                    <div class="checkbox-warapper-terms">
                      <input class="" type="checkbox" name="terms" v-model="nl.accept_terms" id="terms" hidden />
                      <label for="terms" class="checkmark"></label>
                      <a @click="$bvModal.show('modal-terms-conditions')"
                        >Li e aceito a <span>Privacidade</span> e <span>Tratamento dos Dados</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group content-footer-elements-nl-btn">
                <button type="submit">Subscrever</button>
              </div>
            </form> -->
            <div class="content-footer-elements-nl-btn">
              <router-link tag="button" to="/newsletter">
                <button type="submit">
                  {{ $t("pages.btn.btnSubscribed") }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="social">
          <div class="content-footer-elements-social">
            <h4>{{ $t("pages.footer.socialMedia") }}</h4>
            <div class="content-footer-elements-social-icons">
              <template v-for="(social, index) in company.social_media">
                <a :key="index" :href="social.social_url" rel="noopener" target="_blank">
                  <template v-if="social.social_media === 'facebook'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44.387" height="44.387" viewBox="0 0 44.387 44.387">
                      <g id="Group_1809" data-name="Group 1809" transform="translate(-193 -8285)">
                        <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(193 8285)" fill="none"
                          stroke="#fff" stroke-width="1">
                          <circle cx="22.193" cy="22.193" r="22.193" stroke="none" />
                          <circle cx="22.193" cy="22.193" r="21.693" fill="none" />
                        </g>
                        <path id="Path_61" data-name="Path 61"
                          d="M31.785,10l.494-3.216H29.192V4.693a1.608,1.608,0,0,1,1.813-1.738h1.4V.217A17.11,17.11,0,0,0,29.918,0c-2.542,0-4.2,1.541-4.2,4.329V6.781H22.89V10h2.825v7.775h3.477V10Z"
                          transform="translate(187.545 8298.308)" fill="#fff" />
                      </g>
                    </svg>
                  </template>
                  <template v-if="social.social_media === 'instagram'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44.387" height="44.387" viewBox="0 0 44.387 44.387">
                      <g id="XMLID_13_" transform="translate(13.91 13.023)">
                        <g id="Ellipse_9" data-name="Ellipse 9" transform="translate(-13.91 -13.023)" fill="none"
                          stroke="#fff" stroke-width="1">
                          <circle cx="22.193" cy="22.193" r="22.193" stroke="none" />
                          <circle cx="22.193" cy="22.193" r="21.693" fill="none" />
                        </g>
                        <path id="XMLID_17_"
                          d="M12.677,0h-7.3A5.385,5.385,0,0,0,0,5.379v7.3a5.385,5.385,0,0,0,5.379,5.379h7.3a5.385,5.385,0,0,0,5.379-5.379v-7.3A5.385,5.385,0,0,0,12.677,0ZM16.24,12.677a3.563,3.563,0,0,1-3.563,3.563h-7.3a3.563,3.563,0,0,1-3.563-3.563v-7.3A3.563,3.563,0,0,1,5.379,1.816h7.3A3.563,3.563,0,0,1,16.24,5.379v7.3Z"
                          fill="#fff" />
                        <path id="XMLID_81_"
                          d="M137.67,133a4.67,4.67,0,1,0,4.67,4.67A4.675,4.675,0,0,0,137.67,133Zm0,7.524a2.854,2.854,0,1,1,2.854-2.854A2.854,2.854,0,0,1,137.67,140.524Z"
                          transform="translate(-128.642 -128.642)" fill="#fff" />
                        <circle id="XMLID_83_" cx="1.119" cy="1.119" r="1.119" transform="translate(12.588 3.274)"
                          fill="#fff" />
                      </g>
                    </svg>
                  </template>
                  <template v-if="social.social_media === 'linkedin'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44.387" height="44.387" viewBox="0 0 44.387 44.387">
                      <g id="Group_1810" data-name="Group 1810" transform="translate(-315.916 -8285)">
                        <g id="Ellipse_65" data-name="Ellipse 65" transform="translate(315.916 8285)" fill="none"
                          stroke="#fff" stroke-width="1">
                          <circle cx="22.193" cy="22.193" r="22.193" stroke="none" />
                          <circle cx="22.193" cy="22.193" r="21.693" fill="none" />
                        </g>
                        <path id="Path_2827" data-name="Path 2827"
                          d="M3.993,17.85H.295V5.939h3.7ZM2.142,4.315A2.152,2.152,0,1,1,4.284,2.152,2.16,2.16,0,0,1,2.142,4.315ZM17.837,17.85H14.146v-5.8c0-1.382-.028-3.154-1.923-3.154-1.923,0-2.218,1.5-2.218,3.054v5.9H6.31V5.939H9.858V7.564H9.91a3.887,3.887,0,0,1,3.5-1.923c3.743,0,4.432,2.465,4.432,5.667V17.85Z"
                          transform="translate(330.043 8298.229)" fill="#fff" />
                      </g>
                    </svg>
                  </template>
                  <template v-if="social.social_media === 'youtube'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44.387" height="44.387" viewBox="0 0 44.387 44.387">
                      <g id="XMLID_822_" transform="translate(12.42 -30.636)">
                        <g id="Ellipse_66" data-name="Ellipse 66" transform="translate(-12.42 30.636)" fill="none"
                          stroke="#fff" stroke-width="1">
                          <circle cx="22.193" cy="22.193" r="22.193" stroke="none" />
                          <circle cx="22.193" cy="22.193" r="21.693" fill="none" />
                        </g>
                        <path id="XMLID_823_"
                          d="M18.787,47.1c-.706-.839-2.008-1.181-4.5-1.181H5.258c-2.545,0-3.87.364-4.573,1.257C0,48.045,0,49.328,0,51.1v3.384c0,3.44.813,5.187,5.258,5.187H14.29c2.158,0,3.353-.3,4.127-1.042.793-.759,1.132-2,1.132-4.144V51.1C19.549,49.231,19.5,47.941,18.787,47.1ZM12.55,53.263l-4.1,2.143a.631.631,0,0,1-.923-.559V50.574a.631.631,0,0,1,.921-.56l4.1,2.13a.631.631,0,0,1,0,1.119Z"
                          fill="#fff" />
                      </g>
                    </svg>
                  </template>
                  <template v-if="social.social_media === 'twitter'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44.387" height="44.387" viewBox="0 0 44.387 44.387">
                      <g id="XMLID_826_" transform="translate(14.129 -13.349)">
                        <g id="Ellipse_67" data-name="Ellipse 67" transform="translate(-14.129 13.349)" fill="none"
                          stroke="#fff" stroke-width="1">
                          <circle cx="22.193" cy="22.193" r="22.193" stroke="none" />
                          <circle cx="22.193" cy="22.193" r="21.693" fill="none" />
                        </g>
                        <path id="XMLID_827_"
                          d="M17.434,29.783a6.762,6.762,0,0,1-.863.314,3.814,3.814,0,0,0,.777-1.366.288.288,0,0,0-.421-.335,6.791,6.791,0,0,1-2.007.793,3.832,3.832,0,0,0-6.511,2.737,3.907,3.907,0,0,0,.031.495,9.805,9.805,0,0,1-6.73-3.57.288.288,0,0,0-.472.037A3.836,3.836,0,0,0,1.632,33.3a3.244,3.244,0,0,1-.513-.229.288.288,0,0,0-.427.245c0,.017,0,.034,0,.051a3.843,3.843,0,0,0,1.875,3.294c-.1-.01-.195-.024-.291-.042a.288.288,0,0,0-.328.37,3.829,3.829,0,0,0,2.806,2.567,6.776,6.776,0,0,1-3.622,1.034,6.929,6.929,0,0,1-.811-.048.288.288,0,0,0-.189.528A10.329,10.329,0,0,0,5.715,42.7a9.936,9.936,0,0,0,7.7-3.381,10.717,10.717,0,0,0,2.668-6.985c0-.105,0-.211,0-.317a7.445,7.445,0,0,0,1.713-1.815.288.288,0,0,0-.356-.423Z"
                          transform="translate(0)" fill="#fff" />
                      </g>
                    </svg>
                  </template>
                  <template v-if="social.social_media === 'tiktok'">
                    <svg id="changeColor" fill="#DC7633" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" width="200" zoomAndPan="magnify"
                      viewBox="0 0 375 374.9999" height="200" version="1.0">
                      <defs>
                        <path id="pathAttribute"
                          d="M 11.972656 11.972656 L 359.222656 11.972656 L 359.222656 359.222656 L 11.972656 359.222656 Z M 11.972656 11.972656 "
                          fill="#ffffff"></path>
                      </defs>
                      <g>
                        <path id="pathAttribute"
                          d="M 185.597656 359.222656 C 89.675781 359.222656 11.972656 280.984375 11.972656 185.597656 C 11.972656 90.210938 89.675781 11.972656 185.597656 11.972656 C 281.519531 11.972656 359.222656 89.675781 359.222656 185.597656 C 359.222656 281.519531 280.984375 359.222656 185.597656 359.222656 Z M 185.597656 22.691406 C 95.570312 22.691406 22.691406 95.570312 22.691406 185.597656 C 22.691406 275.625 95.570312 348.503906 185.597656 348.503906 C 275.625 348.503906 348.503906 275.625 348.503906 185.597656 C 348.503906 95.570312 275.089844 22.691406 185.597656 22.691406 Z M 185.597656 22.691406 "
                          fill-opacity="1" fill-rule="nonzero" fill="#ffffff"></path>
                      </g>
                      <g id="inner-icon" transform="translate(85, 75)">
                        <svg id="IconChangeColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                          height="199.8" width="199.8">
                          <path
                            d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                            id="mainIconPathAttribute" fill="#ffffff"></path>
                        </svg>
                      </g>
                    </svg>
                  </template>
                </a>
              </template>
            </div>
          </div>
        </div>
      </section>
      <section class="col-lg-9 content-footer-columns">
        <div class="row content-footer-columns-item">
          <div class="col-lg-4 content-footer-columns-item-itens">
            <div class="content-footer-columns-item-itens-links">
              <template v-if="about.length">
                <!-- <h4>{{ about[0].categoria }}</h4> -->
                <h4>{{ $t("pages.footer.about") }}</h4>
                <div v-for="(a, index) in about" :key="index">
                  <a v-if="a.url_sitio !== ''" rel="noopener" :target="a.url" :href="a.url_sitio">
                    <p>{{ a.name }}</p>
                  </a>
                  <a v-else-if="a.url === '/politic' || a.url === '/carrers'" @click="goToPage(a.url, a.slug)">
                    <p>{{ a.name }}</p>
                  </a>
                  <router-link v-else tag="a" :to="a.url">
                    <p>{{ a.name }}</p>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <h4>{{ $t("pages.footer.about") }}</h4>
                <div>
                  <router-link tag="a" to="/about">
                    <p>{{ $t("pages.footer.upon") }}</p>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="col-lg-4 content-footer-columns-item-itens">
            <div class="content-footer-columns-item-itens-links">
              <template v-if="suport.length">
                <!-- <h4>{{ suport[0].categoria }}</h4> -->
                <h4>{{ $t("pages.footer.customerSupport") }}</h4>
                <div v-for="(s, index) in suport" :key="index">
                  <a v-if="s.url_sitio !== ''" rel="noopener" :target="s.url" :href="s.url_sitio">
                    <p>{{ s.name }}</p>
                  </a>
                  <a v-else-if="s.url === '/politic' || s.url === '/carrers'" @click="goToPage(s.url, s.slug)">
                    <p>{{ s.name }}</p>
                  </a>
                  <router-link v-else tag="a" :to="s.url">
                    <p>{{ s.name }}</p>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <h4>{{ $t("pages.footer.customerSupport") }}</h4>
                <div>
                  <router-link tag="a" to="/">
                    <p>Suporte</p>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
          <div class="col-lg-4 content-footer-columns-item-itens">
            <div class="content-footer-columns-item-itens-links">
              <template v-if="payment.length">
                <h4>{{ $t("pages.footer.paymentMethods") }}</h4>
                <!-- <h4>{{ payment[0].categoria }}</h4> -->
                <div v-for="(c, index) in payment" :key="index">
                  <a v-if="c.url_sitio !== ''" rel="noopener" :target="c.url" :href="c.url_sitio">
                    <p>{{ c.name }}</p>
                  </a>
                  <a v-else-if="c.url === '/politic' || c.url === '/carrers'" @click="goToPage(c.url, c.slug)">
                    <p>{{ c.name }}</p>
                  </a>
                  <router-link v-else tag="a" :to="c.url">
                    <p>{{ c.name }}</p>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <h4>{{ $t("pages.footer.paymentMethods") }}</h4>
                <div>
                  <router-link tag="a" to="/">
                    <p>{{ $t("pages.footer.payament") }}</p>
                  </router-link>
                </div>
              </template>
            </div>
            <div class="content-footer-columns-item-itens-links">
              <template v-if="opinion.length">
                <!-- <h4>{{ opinion[0].categoria }}</h4> -->
                <h4>{{ $t("pages.footer.opinion") }}</h4>
                <div v-for="(c, index) in opinion" :key="index">
                  <a v-if="c.url_sitio !== ''" rel="noopener" :target="c.url" :href="c.url_sitio">
                    <p>{{ c.name }}</p>
                  </a>
                  <a v-else-if="c.url === '/politic' || c.url === '/carrers'" @click="goToPage(c.url, c.slug)">
                    <p>{{ c.name }}</p>
                  </a>
                  <router-link v-else tag="a" :to="c.url">
                    <p>{{ c.name }}</p>
                  </router-link>
                </div>
              </template>
              <template v-else>
                <h4>{{ $t("pages.footer.opinion") }}</h4>
                <div>
                  <router-link tag="a" to="/">
                    <p>Opinião</p>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="content-footer-columns-item-info" v-if="company.description">
          <p>{{ company.description }}</p>
        </div>
      </section>
    </div>
  </footer>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      nl: {
        email: "",
        accept_terms: false,
      },
      about: [],
      suport: [],
      payment: [],
      opinion: [],
      company: [],
      options: [],
    };
  },
  methods: {
    sendNl() {
      console.log(this.nl);
    },
    numberPhone(number) {
      number = number.replace(/\s/g, "");
      number = `tel:${number}`;
      return number;
    },
    goToPage(url, slug) {
      if (url === "/politic") {
        this.$router.push({ name: "Politica", params: { slug: slug } });
      } else {
        this.$router.push({ name: "Carrers", params: { slug: slug } });
      }
      this.$router.go(0);
      // window.location.reload()
    },
  },
  async mounted() {
    axios.get("/api/v1/company/data").then((resp) => {
      if (resp && resp.data) {
        this.company = resp.data;

        if (resp.data.footer) {
          this.options = resp.data.footer;
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].categoria == "Sobre o Geras") {
              this.about.push(this.options[i]);
            }
            if (this.options[i].categoria == "Apoio ao Cliente") {
              this.suport.push(this.options[i]);
            }
            if (this.options[i].categoria == "Métodos de Pagamento") {
              this.payment.push(this.options[i]);
            }
            if (this.options[i].categoria == "Opinião") {
              this.opinion.push(this.options[i]);
            }
          }
        }
      }
    });
  },
};
</script>
<style lang="scss">
footer {
  width: 100%;

  @media screen and (max-width: 1500px) {
    height: auto;
  }
}

.content-footer {
  margin: 0 !important;
  background-color: #555555;

  section:first-child {
    background-color: #707070;
  }

  &-elements {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 9rem 5rem 8rem 9rem !important;

    @media screen and (max-width: 1600px) {
      padding-left: 7rem !important;
    }

    @media screen and (max-width: 1300px) {
      padding-left: 5rem !important;
    }

    @media screen and (max-width: 1100px) {
      padding-right: 3rem !important;
    }

    @media screen and (max-width: 991px) {
      padding: 8rem 3rem !important;
      display: flex;
      align-items: flex-start;
    }

    .contacts {
      display: flex;
      flex-direction: column;
      gap: 4rem;

      @media screen and (max-width: 991px) {
        align-items: flex-start;
        margin-bottom: 137px;
      }
    }

    &-img {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-attendance {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: var(--white) !important;
      gap: 1rem;
      transition: 500ms;

      &:hover,
      &:focus {
        opacity: 0.8;
        color: var(--white);
      }

      &-item {
        display: flex;
        align-items: center;
        gap: 1rem;

        h5 {
          margin: 0;
          font-family: "Font Regular", sans-serif;
          font-size: 22px;

          @media screen and (max-width: 1500px) {
            font-size: 18px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 14px;
          }

          @media screen and (max-width: 991px) {
            font-size: 22px;
          }
        }

        h2 {
          margin: 0;
          font-family: "Font Bold", sans-serif;
          font-size: 30px;

          @media screen and (max-width: 1500px) {
            font-size: 24px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 18px;
          }

          @media screen and (max-width: 991px) {
            font-size: 30px;
          }
        }

        p {
          margin: 0;
          font-family: "Font Regular", sans-serif;
          font-size: 18px;

          @media screen and (max-width: 1500px) {
            font-size: 16px;
          }

          @media screen and (max-width: 1200px) {
            font-size: 12px;
          }

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }
        }
      }
    }

    &-email {
      display: flex;
      justify-content: flex-start;
      color: #fff;
      align-items: center;
      gap: 1rem;

      p {
        margin: 0;
        font-family: "Font Regular", sans-serif;
        font-size: 18px;

        @media screen and (max-width: 1500px) {
          font-size: 16px;
        }

        @media screen and (max-width: 1200px) {
          font-size: 12px;
        }

        @media screen and (max-width: 991px) {
          font-size: 18px;
        }
      }
    }

    &-nl {
      h4 {
        font-family: "Font Bold", sans-serif;
        text-transform: uppercase;
        font-size: 24px;
        text-align: left;
        color: #fff;

        @media screen and (max-width: 1500px) {
          font-size: 20px;
        }

        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }

        @media screen and (max-width: 991px) {
          font-size: 24px;
          text-transform: none;
          margin-bottom: 12px;
        }
      }

      &-input {
        margin: 1rem 0;

        input {
          width: 100%;
          height: 65px;
          border: 1px solid #fff;
          border-radius: 10px;
          padding: 0 1rem;
          font-family: "Font Regular", sans-serif;
          font-size: 18px;
          background-color: #fff !important;

          @media screen and (max-width: 1500px) {
            font-size: 16px;
            height: 50px;
          }

          &::placeholder {
            color: #112d23;
          }
        }
      }

      &-btn {
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;

        @media screen and (max-width: 991px) {
          justify-content: left;
        }

        button {
          width: 134px;
          height: 45px;
          background-color: var(--green-medium) !important;
          border: var(--green-medium) solid 1px;
          border-radius: 10px;
          color: var(--white);
          font-size: 18px;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);

          p {
            font-family: "Font Regular", sans-serif;
            font-size: 18px;
            margin: 0;
          }

          &:hover,
          &:focus {
            background-color: #fff !important;
            color: var(--green-medium);
            border: var(--green-medium) solid 1px;
          }
        }
      }
    }

    .social {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 100px;

      @media (max-width: 991px) {
        margin-top: 0px;
      }
    }

    &-social {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;

      @media screen and (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        max-width: 380px;
      }

      h4 {
        font-family: "Font Bold", sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        text-align: left;
        color: #fff;

        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }

        @media screen and (max-width: 991px) {
          text-transform: none;
          margin-bottom: 20px;
        }
      }

      &-icons {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin: 1rem 0;

        @media (max-width: 991px) {
          gap: 20px;
          margin: 0;
        }

        svg {
          width: 45px;
          height: 45px;
          fill: #000;

          @media screen and (max-width: 1200px) {
            width: 35px;
            height: 35px;
          }

          @media screen and (max-width: 991px) {
            width: 45px;
            height: 45px;
          }

          &:hover,
          &:focus {
            fill: var(--green-medium);

            g {
              fill: #555555;
            }
          }
        }
      }
    }
  }

  &-columns {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 9rem 9rem 8rem 9rem !important;

    @media screen and (max-width: 1200px) {
      padding: 9rem 5rem !important;
    }

    @media screen and (max-width: 991px) {
      padding: 3.3rem 3rem 8rem 3rem !important;
      align-items: flex-start;
    }

    &-item {
      height: 100%;

      @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4rem;
      }

      &-itens {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        gap: 3rem;

        &-links {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
          gap: 2rem;

          @media screen and (max-width: 991px) {
            align-items: flex-start;
            justify-content: center;
            text-align: left;
            gap: 1.5rem;
          }

          h4 {
            margin: 0;
            font-family: "Font Bold", sans-serif;
            font-size: 24px;
            color: #fff;
            text-transform: uppercase;

            @media screen and (max-width: 1200px) {
              font-size: 18px;
            }

            @media screen and (max-width: 991px) {
              font-size: 24px;
              margin-bottom: 1rem;
            }
          }

          a {
            color: var(--white) !important;
            text-decoration: none;
            transition: 500ms;

            &:hover,
            &:focus {
              cursor: pointer;
              color: #bbc2bd !important;
            }

            p {
              margin: 0;
              font-family: "Font Regular", sans-serif;
              font-size: 18px;

              @media screen and (max-width: 1200px) {
                font-size: 14px;
              }

              @media screen and (max-width: 991px) {
                line-height: 24px;
                font-size: 18px;
              }
            }
          }
        }
      }

      &-info {
        color: var(--white);
        font-size: 18px;
        font-family: "Font Regular", sans-serif;
        text-align: left;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-check {
  &-checkbox {
    &-terms {
      .checkbox-warapper-terms {
        display: flex;
        justify-content: flex-start;

        @media (max-width: 991px) {
          margin-top: 20px;
          justify-content: center;
        }

        label {
          border: solid 1px #bebebe;
          margin: 0;
        }

        span {
          text-decoration: underline;
          cursor: pointer;
        }

        a {
          margin-left: 1rem;
          font-family: "Font Regular", sans-serif;
          font-size: 18px;
          color: #fff !important;
          text-align: left;
          margin-bottom: 0;

          @media (max-width: 1200px) {
            font-size: 16px;
          }

          @media screen and (max-width: 576px) {
            font-size: 14px;
          }
        }

        .checkmark {
          min-width: 30px;
          width: 30px;
          position: relative;
          height: 30px;
          background-color: #fff;
          border-radius: 5px;
          transition: background-color 0.5s;

          @media (max-width: 1200px) {
            min-width: 25px;
            max-height: 25px;
            width: 25px;
            height: 25px;
          }

          @media screen and (max-width: 991px) {
            min-width: 20px;
            max-height: 20px;
            width: 20px;
            height: 20px;
          }

          &::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 20px;
            border-right: 5px solid #fff;
            border-bottom: 5px solid #fff;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(40deg) scale(2);
            opacity: 0;
            transition: all 0.5s;
            pointer-events: none;

            @media (max-width: 991px) {
              width: 10px;
              height: 20px;
            }
          }
        }

        #terms:checked~.checkmark {
          background-color: #08bb68;

          &::after {
            opacity: 1;
            transform: translate(-50%, -50%) rotate(40deg) scale(1);
          }
        }
      }
    }
  }
}

.carousel-indicators {
  bottom: 2rem !important;
}
</style>
