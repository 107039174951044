
import axios from "axios";
import { baseApiUrl } from "@/global.js";

export default {
	namespaced: true,
	state: {
		publicities: [],
	},
	getters: {
		publicities(state) {
			return state.publicities;
		},
	},
	mutations: {
		setPublicities(state, publicities) {
			state.publicities = publicities;
		},
	},
	actions: {
		async getPublicities({ commit, dispatch }, pageName) {
			dispatch("setIsLoading", true, { root: true });
			axios.post(`${baseApiUrl}/publicities`, { 'page_name': pageName }).then(resp => {
				// console.log('PUBLICITIES', resp.data);
				commit('setPublicities', resp.data[0]);
				dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
		},
	}
}
