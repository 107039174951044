<template>
  <section class="content-products">
    <div class="content-products-items container">
      <div class="row row-cols-2 content-products-items-grid d-none d-lg-flex">
        <router-link
          :to="{ name: 'Partners', params: { slug: c.slug } }"
          class="col content-products-items-grid-element"
          v-for="c in cards1"
          :key="c.id"
        >
          <div
            class="content-products-items-grid-element-img"
            :style="{ backgroundImage: `url('${c.banner}')` }"
          >
            <div
              class="content-products-items-grid-element-text"
              :style="{ backgroundColor: `${c.color}af` }"
            >
              <p>{{ c.title }}</p>
              <h2>{{ c.subtitle }}</h2>
            </div>
          </div>
        </router-link>
      </div>

      <swiper
        class="content-products-items-grid d-lg-none"
        :options="swiperProducts"
      >
        <swiper-slide
          class="content-products-items-grid-element"
          v-for="c in cards1"
          :key="c.id"
        >
          <router-link
            :to="{ name: 'Partners', params: { slug: c.slug } }"
            class="content-products-items-grid-element-img"
            :style="{ backgroundImage: `url('${c.banner}')` }"
          >
            <div
              class="content-products-items-grid-element-text"
              :style="{ backgroundColor: `${c.color}af` }"
            >
              <p>{{ c.title }}</p>
              <h2>{{ c.subtitle }}</h2>
            </div>
          </router-link>
        </swiper-slide>
      </swiper>
      <div
        class="content-products-items-right"
        v-if="publicities.image_halfpage == null"
      >
        <img
          class="img-fluid"
          src="@/assets/img/home/edp-comercial.png"
          alt=""
        />
      </div>
      <a
        :href="publicities.image_halfpage_url"
        target="_blank"
        rel="noopener"
        class="content-products-items-right"
        v-else
      >
        <img class="img-fluid" :src="publicities.image_halfpage.path" alt="" />
      </a>
    </div>
    <div class="others-services container d-none d-lg-flex">
      <template v-if="showLess">
        <div class="row row-cols-4 others-services-items">
          <list-products-link
            v-for="i in cards2.slice(0, 4)"
            :key="i.id"
            :obj="i"
          />
        </div>
        <div class="others-services-more">
          <button class="others-services-more-btn" @click="showLess = false">
            {{ $t("generic.viewmore") }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="15.143"
              viewBox="0 0 26 15.143"
            >
              <path
                id="União_13"
                data-name="União 13"
                d="M24,0,12,13.143ZM0,0,12,13.143Z"
                transform="translate(1 1)"
                fill="none"
                stroke="var( --green-medium)"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="row row-cols-4 others-services-items">
          <list-products-link v-for="i in cards2" :key="i.id" :obj="i" />
        </div>
        <div class="others-services-more">
          <button class="others-services-more-btn" @click="showLess = true">
            {{ $t("generic.seeLess") }}
            <svg
              style="transform: rotate(180deg)"
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="15.143"
              viewBox="0 0 26 15.143"
            >
              <path
                id="União_13"
                data-name="União 13"
                d="M24,0,12,13.143ZM0,0,12,13.143Z"
                transform="translate(1 1)"
                fill="none"
                stroke="var( --green-medium)"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </button>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";
import ListProductsLink from "./ListProductsLink.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ListProductsLink,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      cards1: [],
      cards2: [],
      showLess: true,
      configbanner: {
        position: null,
        limit: null,
      },
      swiperProducts: {
        slidesPerView: 1.4,
        spaceBetween: 22,
        loop: false,
        speed: 800,
        watchOverflow: true,
        centeredSlides: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          768: {
            slidesPerView: 1.6,
          },
          992: {
            slidesPerView: 1.3,
            spaceBetween: 15,
          },
        },
      },
      data1: [],
      data2: [],
    };
  },
  computed: {
    ...mapGetters({
      publicities: "Publicity/publicities",
    }),
  },

  async mounted() {
    this.$store.dispatch("Publicity/getPublicities", this.$route.name);
    axios.post("/api/v1/bannerlist", this.configbanner).then((resp) => {
      if (resp && resp.data) {
        this.data1 = resp.data[0];
        for (let i = 0; i < this.data1.length; i++) {
          if (this.data1[i].position == "1") {
            this.cards1.push(this.data1[i]);
          }
        }
        this.data2 = resp.data[1];
        for (let i = 0; i < this.data2.length; i++) {
          if (this.data2[i].position == "2") {
            this.cards2.push(this.data2[i]);
          }
        }
      }
    });
  },
};
</script>
<style lang="scss">
.content-products {
  height: auto;

  .content-products-items-grid-element:first-child {
    @media (min-width: 992px) {
      flex: 0 0 62%;
      max-width: 62%;
    }
  }

  .content-products-items-grid-element:nth-child(2) {
    @media (min-width: 992px) {
      flex: 0 0 38%;
      max-width: 38%;
    }
  }

  .content-products-items {
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 0;
    height: auto;

    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 720px;
    }

    &-right {
      display: flex;
      min-width: 363px;
      height: 587px;
      background-color: #f2f2f2;
      align-items: center;
      justify-content: center;
      align-content: center;
      padding: 0.5rem;

      @media screen and (max-width: 1200px) {
        height: auto;
        min-width: auto;
        margin: 0.8rem 0 1rem 0.5rem;
      }

      @media screen and (max-width: 991px) {
        width: 354px;
        height: 587px;
        min-width: auto;
        margin: 2rem 0 5px 0;
        border-radius: 10px;
      }
    }

    &-grid {
      padding-right: 0;
      align-items: center;
      padding: 0;
      margin-right: 10px !important;

      @media screen and (max-width: 1200px) {
        width: 100%;
        margin: 0;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
        height: auto;
        margin: 0 !important;
        display: flex;
        flex-direction: row;
      }

      &-element {
        width: 100%;
        border: none;
        background-color: transparent !important;
        height: 280px;
        padding-right: 14px !important;
        margin-bottom: 25px;

        @media screen and (max-width: 991px) {
          padding-right: 0 !important;
        }

        &-img {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          @media screen and (max-width: 991px) {
            height: 280px;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 100%;
          height: 100%;
          color: #ffffff;
          border-radius: 10px;
          padding: 25px;

          p {
            font-family: "Font Regular", sans-serif;
            font-size: 23px;
            margin-bottom: 15px;
          }

          h2 {
            font-family: "Font Bold", sans-serif;
            font-size: 33px;
            @media (min-width: 992px) {
              font-size: 28px;
            }
          }

          &:hover,
          &:focus {
            cursor: pointer;
            background-color: #ffffffe3 !important;
            color: #7c997d;
            border: #7c997d solid 1px;
          }
        }
      }
    }
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  .others-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: auto;

    @media screen and (max-width: 1200px) {
      padding: 0;
    }

    @media screen and (max-width: 991px) {
      padding: 1rem;
      align-items: center;
      height: auto;
    }

    @media screen and (max-width: 576px) {
      align-items: center;
      height: auto;
    }

    &-items {
      height: auto;
      justify-content: center;
      align-items: center;
      padding: 0;
      display: flex;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding-left: 7px !important;
      padding-right: 7px !important;

      @media screen and (max-width: 991px) {
        height: auto;
      }

      @media screen and (max-width: 576px) {
        height: auto;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 1rem;
      }

      &-element {
        width: 100%;
        border: none;
        background-color: transparent !important;
        height: 100%;
        margin-bottom: 25px !important;

        @media screen and (max-width: 991px) {
          flex: 0 0 50% !important;
          max-width: 50% !important;
          margin-bottom: 2rem;
          height: auto;
        }

        &-img {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 280px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;

          @media screen and (max-width: 991px) {
            height: 220px;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          text-align: left;
          width: 100%;
          height: 100%;
          color: #ffffff;
          border-radius: 10px;
          padding: 30px 20px;

          p {
            font-family: "Font Regular", sans-serif;
            font-size: 23px;
            color: #878787;

            @media screen and (max-width: 991px) {
              font-size: 18px;
            }
          }

          h2 {
            font-family: "Font Bold", sans-serif;
            font-size: 30px;

            @media screen and (max-width: 1200px) {
              font-size: 25px;
            }

            @media screen and (max-width: 576px) {
              font-size: 20px;
            }
          }

          &:hover,
          &:focus {
            cursor: pointer;
            background-color: #ffffff2f !important;
            color: #7c997d;
            border: #7c997d solid 1px;
          }
        }

        &-int {
          padding: 0 0.5rem;

          @media screen and (max-width: 991px) {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0.5rem;
          }
        }
      }
    }

    &-more {
      margin-top: 15px;
      display: flex;
      justify-content: center;

      @media screen and (max-width: 991px) {
        margin-top: 2rem;
      }

      @media screen and (max-width: 576px) {
        margin-top: 0;
      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 194px;
        height: 60px;
        font-family: "Font Regular";
        font-size: 22px;
        color: var(--green-medium);
        background-color: #ffffff;
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }

        svg {
          margin-left: 20px;
          display: flex;

          @media screen and (max-width: 768px) {
            width: 1rem;
            height: 1rem;
            margin-left: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
