<template>
  <div class="content-satisfactions">
    <div class="btn-back container">
      <div class="content-brand">
        <GoToBackButton />
        <div class="content-brand-elements d-none d-lg-block">
          <router-link tag="a" :to="{ name: 'Home' }" class="content-brand-elements-links">
            {{ $t('pages.goToBackButton.start') }}
          </router-link>
          <span> > </span>
          <router-link tag="a" :to="{ name: 'Inquérito de satisfação' }" class="content-brand-elements-links">
            {{ $t('pages.inquerito.title') }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="content-satisfactions-questions container">
      <form id="satisfaction" @submit.prevent="sendForm()">
        <div class="content-satisfactions-questions-header">
          <p>{{ $t('pages.inquerito.title') }}</p>
        </div>
        <div class="content-satisfactions-questions-itens">
          <div class="visit">
            <div class="title">
              <p>{{ $t('pages.inquerito.question01.question') }}</p>
            </div>
            <div class="content-satisfactions-questions-body">
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div class="content-input-check-categories">
                    <input id="sim" class="inpcheck" type="radio" value="Sim" name="visit" />
                    <label for="sim"> {{ $t('generic.yes') }} </label>
                  </div>
                </div>
                <div class="col-12 col-lg-2">
                  <div class="content-input-check-categories">
                    <input id="nao" class="inpcheck" type="radio" value="Não" name="visit" />
                    <label for="nao"> {{ $t('generic.no') }} </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="marketplace">
            <div class="title">
              <p>
                {{ $t('pages.inquerito.question02.question') }}
              </p>
            </div>
            <div class="content-satisfactions-questions-body">
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{{ $t('pages.inquerito.question03.awnser1') }}</th>
                    <th>{{ $t('pages.inquerito.question03.awnser2') }}</th>
                    <th>{{ $t('pages.inquerito.question03.awnser3') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="area">
                        <p>{{ $t('pages.inquerito.question03.awnser4') }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="professional" class="inpcheck" type="radio" value="Abaixo da expectativa"
                          name="professional" />
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="professional" class="inpcheck" type="radio" value="Cumpre as expectativas"
                          name="professional" />
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="professional" class="inpcheck" type="radio" value="Supera a expectativa"
                          name="professional" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="area">
                        <p>{{ $t('pages.inquerito.question03.awnser5') }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="information" class="inpcheck" type="radio" value="Abaixo da expectativa"
                          name="information" />
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="information" class="inpcheck" type="radio" value="Cumpre as expectativas"
                          name="information" />
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="information" class="inpcheck" type="radio" value="Supera a expectativa"
                          name="information" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="area">
                        <p>{{ $t('pages.inquerito.question03.awnser6') }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="visually" class="inpcheck" type="radio" value="Abaixo da expectativa"
                          name="visually" />
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="visually" class="inpcheck" type="radio" value="Cumpre as expectativas"
                          name="visually" />
                      </div>
                    </td>
                    <td>
                      <div class="content-input-check-categories">
                        <input id="visually" class="inpcheck" type="radio" value="Supera a expectativa"
                          name="visually" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="visit">
            <div class="title">
              <p>
                {{ $t('pages.inquerito.question04.question') }}
              </p>
            </div>
            <div class="content-satisfactions-questions-body">
              <div class="row">
                <div class="col-12 col-lg-3">
                  <div class="content-input-check-categories">
                    <input id="yesAll" class="inpcheck" type="radio" value="Sim, em tudo" name="acquired" />
                    <label for="yesAll"> {{ $t('pages.inquerito.question04.awnser1') }}</label>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="content-input-check-categories">
                    <input id="inParts" class="inpcheck" type="radio" value="Sim, uma parte." name="acquired" />
                    <label for="inParts">
                      {{ $t('pages.inquerito.question04.awnser2') }}
                    </label>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="content-input-check-categories">
                    <input id="noAll" class="inpcheck" type="radio" value="Não" name="acquired" />
                    <label for="noAll">{{ $t('pages.inquerito.question04.awnser3') }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="visit">
            <div class="title">
              <p>
                {{ $t('pages.inquerito.question05.question') }}
              </p>
            </div>
            <div class="content-satisfactions-questions-body">
              <div class="row">
                <div class="col-12 col-lg-3">
                  <div class="content-input-check-categories">
                    <input id="veryLikely" class="inpcheck" type="radio" value="Muito provável" name="probability" />
                    <label for="veryLikely">
                      {{ $t('pages.inquerito.question05.awnser1') }}
                    </label>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="content-input-check-categories">
                    <input id="Likely" class="inpcheck" type="radio" value="Não" name="probability" />
                    <label for="Likely"> {{ $t('pages.inquerito.question05.awnser2') }}</label>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="content-input-check-categories">
                    <input id="unlikely" class="inpcheck" type="radio" value="Improvável" name="probability" />
                    <label for="unlikely"> {{ $t('pages.inquerito.question05.awnser3') }} </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="questions-input">
            <div class="header">
              <h2>
                {{ $t('pages.inquerito.question06.question') }}
              </h2>
            </div>
            <div class="body">
              <div class="row">
                <div class="col-lg-12">
                  <textarea name="mensagem" :placeholder="$t('pages.form.messageText')"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-recaptcha">
          <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired"
            sitekey="6Ld-tb8pAAAAAAzuDktnRGysHO5ixbqA5F-jqwhl" />
        </div>
        <div class="content-btns">
          <button class="btn-confirm" v-if="userIsLogged">
            {{ $t('pages.btn.btnConfirm') }}
          </button>
          <button class="btn-confirm" type="button" v-else @click="checkIsLogged()">
            {{ $t('pages.btn.btnConfirm') }}
          </button>
        </div>
        <div class="content-brand">
          <GoToBackButton />
        </div>
      </form>
    </div>
  </div>
</template>
<script>
// imports //
import { VueRecaptcha } from "vue-recaptcha";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";
import axios from "axios";
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      quetions: [],
      recaptchaStatus: 'PENDING'
    }
  },
  components: {
    GoToBackButton,
    VueRecaptcha,
  },
  computed: {
    ...mapGetters("User", ["userIsLogged"]),
  },
  methods: {
    onVerify(response) {
      this.recaptchaStatus = 'SUCCESS';
    },
    onExpired() {
      this.recaptchaStatus = 'EXPIRED';
    },
    checkIsLogged() {
      this.$fire({
        type: "info",
        title: "Atenção",
        text: "Para submeter um pedido de estadia, é necessário estar logado",
        timer: 3000,
        showConfirmButton: true,
      });
      this.$router.push({
        name: 'Login',
        params: {
          page: this.$route.path,
        }
      })
    },
    formValidation() {

      if (this.recaptchaStatus == 'PENDING') {
        this.$fire({
          type: "info",
          title: "Atenção",
          text: "Para submeter um pedido de estadia, é necessário validar o reCAPTCHA",
          timer: 3000,
          showConfirmButton: true,
        });
        return false;
      }

      if (this.recaptchaStatus == 'EXPIRED') {
        this.$fire({
          type: "info",
          title: "Atenção",
          text: "O reCAPTCHA expirou, favor validar novamente",
          timer: 3000,
          showConfirmButton: true,
        });
        return false;
      }
      return true;
    },
    sendForm() {
      if (!this.formValidation()) {
        return;
      }

      let myForm = document.getElementById('satisfaction');
      let formData = new FormData(myForm);
      const data = {};
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }
      axios.post("/api/v1/satisfaction/set", { token: this.$route.params.token, awnsers: data }).then((response) => {
        if (response.data.success == true) {
          this.$store.dispatch("setAlert", {
            type: "success",
            title: "Obrigado!",
            message: response.data.message,
          });
          this.$router.push('/');
        } else {
          this.$store.dispatch("setAlert", {
            type: "error",
            title: "Problema!",
            message: response.data.message,
          });
          this.$router.push('/');
        }
      })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    var token = this.$route.params.token;
    if (token) {
      axios.post("/api/v1/satisfaction/validate", { token: this.$route.params.token }).then((response) => {
        if (response.data.no_valid == true) {
          this.$store.dispatch("setAlert", {
            type: "error",
            title: "Erro",
            message: response.data.message,
          });
          this.$router.push('/');
        }
      })
        .catch((error) => {
          console.error(error);
        });
    } else {
      //redireciona para home
    }

  },
};
</script>
<style lang="scss" scoped>
.content-satisfactions {
  >.btn-back {
    margin-top: calc(5rem + var(--marginHeight));

    >.content-brand {
      margin: 0;
    }
  }

  &-questions {
    margin-top: 5rem;

    &-header {
      p {
        font-size: 5rem;
        font-family: "Font Bold";
        color: #112d23;
        text-align: left;
      }
    }

    &-itens {
      margin-top: 6rem;

      >.visit {
        margin-bottom: 8rem;

        .title {
          margin-bottom: 2rem;

          p {
            font-size: 2.8rem;
            font-family: "Font Bold";
            color: #338545;
            text-align: left;
          }
        }
      }

      >.marketplace .title {
        margin-bottom: 4.5rem;

        p {
          font-size: 2.8rem;
          font-family: "Font Bold";
          color: #338545;
          text-align: left;
        }
      }

      >.questions-input {
        >.header {
          text-align: left;

          h2 {
            font-size: 28px;
            font-family: "Font Bold";
            color: var(--green-medium);
            margin-bottom: 30px;

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }

        >.body {
          input {
            width: 100%;
            height: 70px;
            background: var(--white);
            border: 1px solid #bebebe;
            border-radius: 10px;
            font-size: 25px;
            font-family: "Font Regular";
            padding-left: 25px;
            outline: none;
            margin-bottom: 40px;

            @media (max-width: 991px) {
              font-size: 18px;
              margin-bottom: 20px;
            }

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #b7b8b8;
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #b7b8b8;
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: #b7b8b8;
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: #b7b8b8;
            }
          }

          textarea {
            width: 100%;
            min-height: 270px;
            background: var(--white);
            border: 1px solid #bebebe;
            border-radius: 10px;
            font-size: 25px;
            font-family: "Font Regular";
            padding-top: 20px;
            padding-left: 25px;
            outline: none;
            resize: none;
            margin-bottom: 40px;

            @media (max-width: 991px) {
              font-size: 18px;
              min-height: 200px;
              margin-bottom: 20px;
            }

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #b7b8b8;
            }

            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #b7b8b8;
            }

            &:-ms-input-placeholder {
              /* IE 10+ */
              color: #b7b8b8;
            }

            &:-moz-placeholder {
              /* Firefox 18- */
              color: #b7b8b8;
            }
          }
        }
      }
    }

    &-body {
      >.table .content-input-check-categories {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      >.table thead th {
        border: none;
        font-size: 2rem;
        color: #112d23;
        font-family: "Font Regular";
      }

      >.table tbody tr {
        >td {
          border: none;
          font-size: 2rem;
          color: #112d23;
          font-family: "Font Bold";
          text-align: left;

          >.area {
            p {
              margin: 0;
              padding: 2.5rem 0;
            }
          }
        }
      }

      .content-input-check-categories {
        display: flex;
        align-items: center;
        padding: 20px 0px;

        input[type="radio"]:checked:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          background: #104f2d;
          border-radius: 100%;
        }

        input[type="radio"]:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: var(--white);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          border: 0;
        }

        input[type="radio"] {
          position: relative;
          height: 45px;
          width: 45px;
          border-radius: 10px;
          border: 1px solid #bebebe;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: 0 !important;
          margin: 0;
          cursor: pointer;
        }

        label {
          font-size: 20px;
          font-family: "Font Regular";
          color: var(--green-dark);
          width: calc(100% - 65px);
          text-align: left;
          margin-bottom: 0;
          margin-left: 2rem;
          cursor: pointer;

          @media (max-width: 991px) {
            font-size: 18px;
          }
        }
      }
    }

    >form .content-recaptcha {
      margin-bottom: 70px;

      @media (max-width: 991px) {
        margin-bottom: 50px;
      }
    }

    >form .content-btns {
      display: flex;
      flex-direction: column;
      margin-top: 70px;

      @media (max-width: 991px) {
        margin-top: 50px;
      }

      .btn-confirm {
        width: 246px;
        height: 72px;
        background: var(--green-medium);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        font-size: 25px;
        font-family: "Font Regular";
        color: var(--white);
        margin-bottom: 70px;
        transition: 500ms;

        @media (max-width: 991px) {
          margin-bottom: 50px;
        }

        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--white);
          color: var(--green-medium);
        }
      }

      .btn-return {
        width: 114px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        font-size: 22px;
        font-family: "Font Regular";
        color: var(--green-medium);
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--green-medium);
          color: var(--white);
        }

        @media (max-width: 991px) {
          width: 80px;
          height: 35px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
