<template>
  <!-- style="--main-color: initial" -->
  <div id="app" @scroll="handleScroll()">
    <!-- DEFINE HEADER -->
    <template v-if="this.$route.name !== 'Offline'">
      <Header :isScrolled="isScrolled" :isScrolledMobile="isScrolledMobile" />
    </template>
    <!-------------------->

    <main
      class="content-main"
      :class="{ 'content--offline': this.$route.name === 'Offline' }"
    >
      <router-view />
    </main>

    <template v-if="this.$route.name !== 'Offline'">
      <!-- DEFINE MENU -->
      <Menu />
      <!-------------------->

      <!-- DEFINE FOOTER -->
      <Footer />
      <!-------------------->

      <!--- BACKDROP -->
      <div class="backdrop_bar" :class="{ 'd-none': !activeBackdrop }"></div>
      <!-------------------->

      <!--- BTN AND MENU CHAT -->
      <!-- <button class="btn-chat" @click="activeChat = !activeChat" v-if="userIsLogged">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="47.602" height="45.458" viewBox="0 0 47.602 45.458"><defs><clipPath id="a"><rect width="47.602" height="45.458" fill="#fff"/></clipPath></defs><g transform="translate(-8.118 -20.595)"><g transform="translate(8.118 20.595)" clip-path="url(#a)"><path d="M3.932,36.991a3.964,3.964,0,0,1-1.518-.306A3.846,3.846,0,0,1,0,33.074V3.907A3.911,3.911,0,0,1,3.907,0H37.9a3.911,3.911,0,0,1,3.907,3.907V27.413A3.911,3.911,0,0,1,37.9,31.32H12.031a2.055,2.055,0,0,0-1.465.609l-3.892,3.9a3.853,3.853,0,0,1-2.742,1.159M3.907,1.839A2.071,2.071,0,0,0,1.839,3.907V33.074a2.068,2.068,0,0,0,3.533,1.46l3.892-3.9a3.882,3.882,0,0,1,2.767-1.149H37.9a2.071,2.071,0,0,0,2.068-2.068V3.907A2.071,2.071,0,0,0,37.9,1.839Z" fill="#fff"/><path d="M93.154,70.5H92.144V81.71a6.213,6.213,0,0,1-6.205,6.205H62.375v4.636a2.487,2.487,0,0,0,2.487,2.487H86.393a2.487,2.487,0,0,1,1.761.731l3.239,3.25a2.487,2.487,0,0,0,4.248-1.755V72.987A2.487,2.487,0,0,0,93.154,70.5" transform="translate(-48.039 -54.297)" fill="#fff"/><path d="M63.407,46.985H44.673a.919.919,0,1,1,0-1.839H63.407a.919.919,0,1,1,0,1.839" transform="translate(-33.698 -34.77)" fill="#fff"/><path d="M57.358,73.3H44.673a.919.919,0,1,1,0-1.839H57.358a.919.919,0,1,1,0,1.839" transform="translate(-33.698 -55.039)" fill="#fff"/></g></g></svg>
        <p>Ajuda</p>
      </button>
      <div class="content-chat" :class="{'active-chat': activeChat}">
        <button class="content-chat-btn-close" @click="activeChat = !activeChat">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.953" height="20.953" viewBox="0 0 20.953 20.953"><path d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z" transform="translate(-7.523 -7.523)" fill="var( --green-medium)"/></svg>
        </button>
        <div class="content-chat-header">
          <img class="img-fluid" src="@/assets/img/icon-chat.jpg" alt="icon chat" title="icon chat"/>
          <h2>Em que podemos ajudar?</h2>
        </div>
        <form class="content-chat-form">
          <div class="content-input">
            <label>Setor</label>
            <div class="content-input-select">
              <select>
                <option selected disabled>Selecione o Setor</option>
                <option>Setor 1</option>
                <option>Setor 2</option>
                <option>Setor 3</option>
              </select>
              <img class="img-fluid" src="@/assets/img/icons/icon-arrow.png" alt="icon arrow down" title="icon arrow down"/>
            </div>
          </div>
          <div class="content-input">
            <label>Escreva a sua mensagem:</label>
            <textarea placeholder="Mensagem"></textarea>
          </div>
          <button class="btn-send">
            Enviar
          </button>
        </form>
      </div> -->
      <!-------------------->

      <FloatingCartButton />
      <menuCart />

      <!--- MODAL TERMS CONDITIONS AND POLITY PRIVACY -->
      <ModalTermsConditions />
      <ModalPolity />
      <!-------------------->

      <notifications group="foo" />
      <modal-alert
        :key="this.$store.getters.alert.key"
        v-if="this.$store.getters.alert.open"
      ></modal-alert>
    </template>

    <!-- COMPONENT LOADING -->
    <Loading />
    <!------------------------>
  </div>
</template>


<script>
import Header from "@/components/headers/Header.vue";
import Menu from "@/components/menus/Menu.vue";
import Footer from "@/components/footers/Footer.vue";
import Loading from "@/components/loading/Loading.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ModalTermsConditions from "@/components/modals/ModalTermsConditions.vue";
import ModalPolity from "@/components/modals/ModalPolity.vue";
import ModalAlert from "@/components/modals/ModalAlert.vue";
import FloatingCartButton from "@/components/floatingButton/FloatingCartButton.vue";
import menuCart from "@/views/Product/components/MenuCart.vue";

export default {
  data() {
    return {
      isScrolled: false,
      isScrolledMobile: false,
      activeChat: false,
      teste: false,
    };
  },
  metaInfo() {
    return {
      title: "Geras",
    };
  },
  components: {
    Header,
    Footer,
    Loading,
    Menu,
    ModalTermsConditions,
    ModalPolity,
    ModalAlert,
    FloatingCartButton,
    menuCart,
  },
  computed: {
    ...mapGetters(["isDesktop", "activeBackdrop", "activeFilter"]),
    ...mapGetters("User", ["userIsLogged"]),
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("click", this.close);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.close);
  },
  methods: {
    ...mapMutations(["setIsDesktop"]),
    ...mapActions([
      "setActiveFilter",
      "setIsCategory",
      "setIsDemand",
      "setIsCounty",
    ]),
    handleScroll(event) {
      if (event.currentTarget.innerWidth > 992) {
        if (event.currentTarget.scrollY > 100) {
          this.isScrolled = true;
        } else {
          this.isScrolled = false;
        }
      } else {
        if (event.currentTarget.scrollY > 100) {
          this.isScrolledMobile = true;
        } else {
          this.isScrolledMobile = false;
        }
      }
    },
    close(e) {
      var arrayPagesHeader = [
        "HearthIntern",
        "PartnerStore",
        "PartnerLandingPage",
        "BlogList",
        "BlogListSlug",
        "Faq",
        "TermsConditions",
        "BlogList",
        "Newsletter",
        "BlogDetail",
        "Hearth",
        "About",
        "PartnersInfo",
        "Communities",
      ];
      if (this.$route.name === "Home") {
        var sectionSearchHome = document.getElementsByClassName(
          "content-filter-header-home"
        )[0];
        if (sectionSearchHome && !sectionSearchHome.contains(e.target)) {
          this.setIsCategory(false);
          this.setIsDemand(false);
          this.setIsCounty(false);
        }
      } else if (arrayPagesHeader.indexOf(this.$route.name) > -1) {
        var headerHome = document.getElementsByClassName(
          "content-filter-header"
        )[0];
        if (!headerHome.contains(e.target)) {
          this.setIsCategory(false);
          this.setIsDemand(false);
          this.setIsCounty(false);
        }
      }
    },
  },
  mounted() {
    if (window.innerWidth > 992) {
      this.setIsDesktop(true);
    } else {
      this.setIsDesktop(false);
    }
  },
};
</script>
<style lang="scss">
@import "./assets/scss/generic/mixins.scss";
@import "./assets/scss/generic/fonts.scss";
@import "./assets/scss/generic/utilities.scss";

:root {
  --main-color: #cc0000;
  --main-color-light: #f21a35;
  --main-color2: #d9b48f;
  --main-color3: #c6afa0;
  --main-color4: #5e4f47;
  --red: #cc0000 !important;
  --red-2: #f21a35;
  --green-medium: #338545;
  --green-dark: #112d23;
  --white: #fff;
  --black: #000;
  --gray-light: #f6fbfe !important;
  --gray: #878787 !important;
  --gray-dark: #555555 !important;
  --purple: #8286bf !important;
  font-size: 62.5%;
  /* 10px / 16px */
  --marginHeight: 17rem;
}

html,
body {
  margin: 0;
  width: 100%;
  color: #687188;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content-main {
  margin-top: 70px;

  @media (max-width: 991px) {
    margin-top: 62px;
  }
}

.content-main-specific-pages {
  margin-top: 170px;

  @media (max-width: 991px) {
    margin-top: 62px;
  }
}

.content-brand {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .btn-return {
    width: 114px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border: 1px solid var(--green-medium);
    border-radius: 10px;
    font-size: 22px;
    font-family: "Font Regular";
    color: var(--green-medium);
    margin-right: 20px;
    transition: 500ms;

    &:hover,
    &:focus {
      cursor: pointer;
      background: var(--green-medium);
      color: var(--white);
    }

    @media (max-width: 991px) {
      width: 80px;
      height: 35px;
      font-size: 18px;
    }
  }

  &-elements {
    width: calc(100% - 135px);
    text-align: left;

    @media (max-width: 991px) {
      width: calc(100% - 90px);
    }

    &-links {
      font-size: 25px;
      font-family: "Font Semibold";
      color: var(--green-dark);
      margin-bottom: 0;
      transition: 500ms;

      &:hover,
      &:focus {
        cursor: pointer;
        color: var(--green-dark);
        opacity: 0.8;
      }

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }

    span {
      font-size: 25px;
      font-family: "Font Semibold";
      color: var(--green-dark);

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
  }
}

// @media (min-width: 992px){
//   .container, .container-lg, .container-md, .container-sm {
//     max-width: 1100px;
//   }
// }
// @media (min-width: 1200px){
//   .container, .container-lg, .container-md, .container-sm, .container-xl {
//     max-width: 1400px;
//   }
// }
.swiper-button-next,
.swiper-button-prev {
  outline: none !important;

  &::after {
    content: none;
  }
}

.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
  background: 0;
  border: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.backdrop_bar {
  position: fixed;
  z-index: 21;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  transition: 500ms;
}

a {
  text-decoration: none !important;
}

button {
  outline: none !important;
}

.btn-chat {
  position: fixed;
  top: 30%;
  right: 0;
  width: 130px;
  height: 135px;
  background: var(--green-medium);
  border: 1px solid var(--green-medium);
  border-radius: 10px 0px 0px 10px;
  z-index: 18;
  transition: 500ms;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 0.8;
  }

  p {
    font-size: 24px;
    font-family: "Font Bold";
    color: var(--white);
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    top: 25%;
    width: 60px;
    height: 60px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }

    p {
      font-size: 12px;
    }
  }
}

.content-chat {
  position: fixed;
  top: 70px;
  right: 0px;
  transform: translateX(100%);
  padding: 50px 30px 30px 30px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
  max-height: 645px;
  overflow-y: auto;
  z-index: 30;
  pointer-events: none;
  transition: 800ms ease-in-out;

  @media (max-width: 991px) {
    top: 0;
    max-height: 100%;
    height: 100%;
  }

  &-btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: 0;
    border: 0;
    transition: 500ms;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  &-header {
    margin-bottom: 20px;

    h2 {
      font-size: 25px;
      font-family: "Font Bold";
      color: var(--green-medium);
      margin-top: 25px;
      margin-bottom: 0;

      @media (max-width: 991px) {
        font-size: 20px;
      }
    }
  }

  &-form {
    .content-input {
      text-align: left;
      margin-bottom: 30px;

      label {
        font-size: 18px;
        font-family: "Font Regular";
        color: var(--green-dark);
        margin-bottom: 5px;
      }

      &-select {
        position: relative;

        select {
          width: 100%;
          height: 50px;
          background: var(--white);
          border: 1px solid #bebebe;
          border: 1px solid #bebebe;
          border-radius: 10px;
          font-size: 20px;
          font-family: "Font Regular";
          color: var(--green-dark);
          padding: 0px 20px;
          outline: none;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          pointer-events: none;
        }
      }

      textarea {
        width: 100%;
        height: 195px;
        background: var(--white);
        border: 1px solid #bebebe;
        border-radius: 10px;
        font-size: 20px;
        font-family: "Font Regular";
        color: var(--green-dark);
        padding-top: 10px;
        padding-left: 20px;
        outline: none;
        resize: none;

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(135, 135, 135, 0.5);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(135, 135, 135, 0.5);
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(135, 135, 135, 0.5);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(135, 135, 135, 0.5);
        }
      }
    }

    .btn-send {
      width: 122px;
      height: 45px;
      background: var(--green-medium);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      font-size: 18px;
      font-family: "Font Regular";
      color: var(--white);
      transition: 500ms;

      &:hover,
      &:focus {
        cursor: pointer;
        background: var(--white);
        color: var(--green-medium);
      }
    }
  }
}

.active-chat {
  transform: translateX(0);
  right: 30px;
  pointer-events: initial;

  @media (max-width: 991px) {
    right: 0;
  }
}

.content-input-check {
  display: flex;
  margin-bottom: 20px;

  input[type="checkbox"]:checked:before {
    content: "";
    height: 100%;
    width: 100%;
    background: url("~@/assets/img/icons/check.png");
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-color: var(--white);
  }

  input[type="checkbox"]:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0;
  }

  input[type="checkbox"] {
    position: relative;
    height: 45px;
    width: 45px;
    border-radius: 10px;
    border: 1px solid #bebebe;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0 !important;
    margin: 0;
    margin-right: 20px;
  }

  label {
    font-size: 25px;
    font-family: "Font Regular";
    color: var(--green-dark);
    width: calc(100% - 65px);
    text-align: left;
    padding-top: 2px;
    margin-bottom: 0;

    @media (max-width: 991px) {
      font-size: 18px;
    }

    a {
      font-size: 25px;
      font-family: "Font Regular";
      color: var(--green-dark);
      text-decoration: underline !important;
      text-decoration-color: var(--green-dark);
      transition: 500ms;

      @media (max-width: 991px) {
        font-size: 18px;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        opacity: 0.8;
        text-decoration: underline !important;
        text-decoration-color: var(--green-dark);
      }
    }
  }
}

.swal2-actions .swal2-styled.swal2-confirm {
  background-color: #338545;
  border-color: #338545;
}

.swal2-actions .swal2-styled.swal2-confirm:hover {
  background-color: #2a6b39;
  border-color: #2a6b39;
}

.oc-link-green {
  color: #338545 !important;
}

.btn-secondary {
  background-color: none !important;
}

.swiper-slide {
  height: auto !important;
}
</style>
