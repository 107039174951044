export default [
    {
        path: "/home-store/:slug",
        name: "HomeStore",
        component: () => import(/* webpackChunkName: "Product" */ "@/views/Product/views/HomeStore.vue"),
    },
    {
        path: "/categories-store/:slug/:family?",
        name: "CategoriesStore",
        component: () => import(/* webpackChunkName: "Product" */ "@/views/Product/views/CategoriesStore.vue"),
    },
    {
        path: "/:slug/:product",
        name: "ProductIntern",
        component: () => import(/* webpackChunkName: "Product" */ "@/views/Product/views/ProductIntern.vue"),
    },
    {
        path: "/cart",
        name: "Cart",
        component: () => import(/* webpackChunkName: "Product" */ "@/views/Product/views/Cart.vue"),
    },
]
