import Vue from "vue";
import axios from "axios";


Vue.use({
  install(Vue) {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', 'pt');
    }

    Vue.prototype.$http = axios.create({
      baseURL: "/api/v1",
    });

    axios.interceptors.request.use(function (config) {

      let hash = Math.floor(Math.random() * 1e16);
      if (!localStorage.getItem("visitor_id")) {
        localStorage.setItem("visitor_id", `G-${hash}`);
      }

      const token = localStorage.getItem("must_token");
      const visitor = localStorage.getItem("visitor_id");

      if (!token) {
        localStorage.setItem("visitor_token", visitor);
      }

      const locale = localStorage.getItem('locale') || 'pt';
      localStorage.setItem('locale', locale);

      config.headers.Authorization = token;
      config.headers["visitor"] = visitor;

      const userToken = localStorage.getItem('token');

      if(userToken){
        config.headers["x-access-token"] = userToken;
      }

      config.headers["X-locale"] = locale;

      return config;
    });
  },
});
