<template>
    <div class="content-pagamento container">
        <form
            class="paymentSPG"
            :spg-context="this.formContext"
            :spg-config="this.formConfig"
            spg-style="">
        </form>

        <form @submit.prevent="checkout()">
            <div class="modal-comments-itens-btns">
                <button class="btn-send" type="submit" @click="$bvModal.hide('modal-comments')">Pagar com Cartão</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            dados: JSON.parse('{"startdate":"2022-09-12T22:25:36.330Z","enddate":"2022-09-15T21:25:36.210Z","order":{"id":"000100","price":"1.00"},"user":{"name":"Renan NaNz"}}'),
            formContext: '',
            formConfig: '',
            transactionID:''
        }
    },
    mounted() {
        
    },
    methods: {
        checkout(){
            axios
            .post("/api/v1/payament/card", this.dados )
            .then((response) => {
                var scriptTag = document.createElement("script");
                scriptTag.src = `https://spg.qly.site1.sibs.pt/assets/js/widget.js?id=${response.data.transactionID}`;
                scriptTag.id = "my-datatable";
                document.getElementsByTagName('head')[0].appendChild(scriptTag);
                
                this.transactionID = response.data.transactionID;
                this.formContext = response.data.formContext;
                this.formConfig = response.data.formConfig;
            })
            .catch((error) => {
                console.log("ERROR", error);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.content-pagamento {
    margin-top: 300px;
}
</style>
