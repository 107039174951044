
import axios from "axios";
import { baseApiUrl } from "@/global.js";

export default {
	namespaced: true,
	state: {
		partners: [],
		partnersFilters: [],
		category: [],
	},
	getters: {
		partners(state) {
			return state.partners;
		},
		partnersFilters(state) {
			return state.partnersFilters;
		},
		category(state) {
			return state.category;
		},
	},
	mutations: {
		setPartners(state, partners) {
			state.partners = partners;
		},
		setPartnersFilters(state, partnersFilters) {
			state.partnersFilters = partnersFilters;
		},
		setCategory(state, category) {
			state.category = category;
		},
	},
	actions: {
		async getPartnersFilters({ commit, dispatch }, slug) {
			dispatch("setIsLoading", true, { root: true });

			axios.post(`${baseApiUrl}/partner/categorydata`, slug).then(resp => {
				// console.log('OPTIONS', resp.data);
				commit("setPartnersFilters", resp.data.filters);
				commit("setCategory", resp.data.category);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
		async getPartnersBySlug({ dispatch }, slug) {
			dispatch("setIsLoading", true, { root: true });
			return axios.post(`${baseApiUrl}/bannergetid`, slug)
		},
		async searchPartners({ commit, dispatch }, search) {
			dispatch("setIsLoading", true, { root: true });
			console.log('SEARCH', search);
			axios.post(`${baseApiUrl}/partner/partnerfiltered`, search).then(resp => {
				if (!resp.data.length) {
					dispatch('setAlert', {
						type: 'warning',
						title: 'Nenhum resultado encontrado',
						message: 'Não encontramos nenhum resultado para sua busca. Tente outros filtros.'
					}, { root: true }
					);
				}
				commit("setPartners", resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
	}
}
