<template>
  <div class="content-socialsharing">
    <button class="share" @click="copyUrl" id="copyUrl" :url="getUrl">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          d="M12.11,15.39,8.23,19.27a2.52,2.52,0,0,1-3.5,0,2.47,2.47,0,0,1,0-3.5l3.88-3.88a1,1,0,1,0-1.42-1.42L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33l3.89-3.88a1,1,0,0,0-1.42-1.42ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L10.47,7.19a1,1,0,1,0,1.42,1.42l3.88-3.88a2.52,2.52,0,0,1,3.5,0,2.47,2.47,0,0,1,0,3.5l-3.88,3.88a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l3.88-3.89A4.49,4.49,0,0,0,20.69,3.31ZM8.83,15.17a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.92-4.92a1,1,0,1,0-1.42-1.42L8.83,13.75A1,1,0,0,0,8.83,15.17Z"
        />
      </svg>
    </button>
    <ShareNetwork
      class="share"
      network="email"
      :url="getUrl"
      :title="title"
      :description="description"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
          fill="rgba(255,255,255,1)"
        />
      </svg>
    </ShareNetwork>
    <a
      class="share"
      :href="`https://www.facebook.com/sharer/sharer.php?u=${getUrl}`"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="32"
        height="32"
        viewBox="0 0 172 172"
        style="fill: #000000"
      >
        <g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
        >
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="#ffffff">
            <path
              d="M103.48974,10.75c-21.18825,0 -33.61474,11.19167 -33.61474,36.69067v22.43433h-26.875v26.875h26.875v64.5h26.875v-64.5h21.5l5.375,-26.875h-26.875v-17.88867c0,-9.60513 3.13237,-14.36133 12.14624,-14.36133h14.72876v-25.77271c-2.54775,-0.344 -9.97113,-1.10229 -20.13526,-1.10229z"
            ></path>
          </g>
        </g>
      </svg>
    </a>
    <a
      class="share"
      :href="`https://twitter.com/intent/post?text=${title}&url=${getUrl}`"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
          fill="rgba(255,255,255,1)"
        />
      </svg>
    </a>
    <a
      class="share"
      :href="`https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${getUrl}`"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
          fill="rgba(255,255,255,1)"
        />
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  computed: {
    getUrl() {
      return window.location.href;
    },
  },
  methods: {
    copyUrl() {
      const url = window.location.href;
      const input = document.createElement("input");
      input.style.position = "fixed";
      input.style.opacity = 0;
      input.value = url;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.content-socialsharing {
  button {
    background: none;
    border: none;
    outline: none;

    svg {
      fill: #fff;
      max-width: 26px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: flex;
  align-items: center;
  gap: 6px;

  svg {
    width: 100%;

    @media (max-width: 991px) {
      width: 70%;
    }
  }

  .share {
    width: 36px;
    height: 36px;

    @media (max-width: 991px) {
      width: 26px;
      height: 26px;
    }

    background: #878787;
    border-radius: 5px;

    &:hover {
      transform: scale(1.2);
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
