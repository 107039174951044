<template>
  <div class="content-filter-header-home" :class="{ 'active-filter-home': activeFilterHome }">
    <div class="content-filter-header-home-itens container">
      <div class="content-filter-header-home-itens-div">
        <div class="content-elements">
          <div class="content-elements-item" @click="toggleClick({ 'category': isCategory })">
            <button class="content-elements-item-btn">
              {{ $t('pages.hearth.categories') }}
              <svg :style="[
                isCategory
                  ? { transform: 'rotate(180deg)' }
                  : { transform: 'none' },
              ]" xmlns="http://www.w3.org/2000/svg" width="16" height="8.5" viewBox="0 0 16 8.5">
                <path d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z" transform="translate(0.5 0.5)" fill="none" stroke="#338545"
                  stroke-linejoin="round" stroke-width="1" />
              </svg>
            </button>
            <div class="content-elements-item-input">
              <input type="text" ref="inputCategories" name="input-search-categories"
                v-on:input="inputCategories($event)" :value="inputSearchCategory"
                :placeholder="$t('pages.hearth.select')" />
            </div>
          </div>
          <div class="content-elements-options" :class="{ 'active-filter': isCategory }">
            <div class="content-elements-options-div w-100">
              <div class="content-elements-options-div-title">
                <p>{{ $t('pages.product.categories') }}</p>
              </div>
              <div class="content-elements-options-div-body">
                <template v-for="category in categories">
                  <template>
                    <button class="content-elements-options-div-btn" :key="category.idCategory"
                      @click=" (inputSearchCategory = category.name, isInputSearchCategory = true, isInputSearchDemand = false, inputSearchDemand = '', inputSearchCounty = ''), (setIsCategory(!isCategory)), filterObject(category)">
                      {{ category.name }}
                    </button>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="content-elements" :class="{ 'desactive-element': !isInputSearchCategory }">
          <div class="content-elements-item" @click="toggleClick({ 'demand': isDemand })">
            <button class="content-elements-item-btn">
              {{ $t('pages.hearth.whatsearch') }}
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8.5" viewBox="0 0 16 8.5">
                <path d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z" transform="translate(0.5 0.5)" fill="none" stroke="#338545"
                  stroke-linejoin="round" stroke-width="1" />
              </svg>
            </button>
            <div class="content-elements-item-input">
              <input type="text" ref="inputDemand" name="input-search-demand" v-on:input="inputDemand($event)"
                :value="inputSearchDemand" :placeholder="$t('pages.hearth.search')" />
            </div>
          </div>
          <div class="content-elements-options" :class="{ 'active-filter': isDemand }">
            <div class="content-elements-options-div" v-for="demand in demands" :key="demand.id">
              <div class="content-elements-options-div-title">
                <p>{{ demand.name }}</p>
              </div>
              <template v-for="(typeDemand, index) in demand.types">
                <template>
                  <button class="content-elements-options-div-btn" :key="index"
                    @click=" (inputSearchDemand = typeDemand.name, isInputSearchDemand = true, inputSearchCounty = ''), (setIsDemand(!isDemand)), filterObjectTwo(typeDemand, demand.type)">
                    {{ typeDemand.name }}
                  </button>
                </template>
              </template>
            </div>
          </div>
        </div>
        <div class="content-elements" :class="{ 'desactive-element': !isInputSearchCategory || !isInputSearchDemand }">
          <div class="content-elements-item" @click="toggleClick({ 'county': isCounty })">
            <button class="content-elements-item-btn">
              {{ $t('pages.hearth.county') }}
              <svg :style="[
                isCounty
                  ? { transform: 'rotate(180deg)' }
                  : { transform: 'none' },
              ]" xmlns="http://www.w3.org/2000/svg" width="16" height="8.5" viewBox="0 0 16 8.5">
                <path d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z" transform="translate(0.5 0.5)" fill="none" stroke="#338545"
                  stroke-linejoin="round" stroke-width="1" />
              </svg>
            </button>
            <div class="content-elements-item-input">
              <input type="text" ref="inputCounty" name="input-search-county" v-on:input="inputCounty($event)"
                :value="inputSearchCounty" :placeholder="$t('pages.hearth.select')" />
            </div>
          </div>
          <div class="content-elements-options" :class="{ 'active-filter': isCounty }">
            <div class="content-elements-options-div w-100">
              <div class="content-elements-options-div-title">
                <p>{{ $t('pages.hearth.counties') }}</p>
              </div>
              <div class="content-elements-options-div-body">
                <template v-for="(county, index) in countys">
                  <template>
                    <button class="content-elements-options-div-btn" :key="index"
                      @click="(inputSearchCounty = county.name, isInputSearchCounty = true), (setIsCounty(!isCounty)), filterObjectThree(county)">
                      {{ county.name }}
                    </button>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-btns">
        <button class="btn-search" @click="sendFilter()" :class="{ 'desactive-element': !isInputSearchCategory }">
          {{ $t('pages.about.search') }}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M23.835,21.979l-5.69-5.69a.556.556,0,0,0-.4-.164h-.619a9.748,9.748,0,1,0-1,1v.619a.576.576,0,0,0,.164.4l5.69,5.69a.563.563,0,0,0,.8,0l1.059-1.059A.563.563,0,0,0,23.835,21.979ZM9.75,17.249a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,9.75,17.249Z" />
          </svg>
        </button>
        <button class="btn-close-filter d-lg-none" @click="setActiveFilterHome(false)">
          <p>{{ $t('pages.btn.btnClose') }}</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="15.5" viewBox="0 0 29 15.5">
            <path id="Union_24" data-name="Union 24" d="M27,0,13.5,13.5ZM0,0,13.5,13.5Z"
              transform="translate(28 14.5) rotate(180)" fill="none" stroke="#555" stroke-linejoin="round"
              stroke-width="2" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MixinFilterHeader from "@/mixins/filterHeader"

export default {
  mixins: [MixinFilterHeader],
  computed: {
    ...mapGetters(["isDesktop", "activeFilterHome", "isCategory", "isDemand", "isCounty"]),
    ...mapGetters({
      isCategory: 'isCategory',
      isDemand: 'isDemand',
      isCounty: 'isCounty',
    })
  },
  methods: {
    ...mapActions(["setActiveFilterHome", "setIsCategory", "setIsDemand", "setIsCounty"]),
  },
};
</script>

<style lang="scss">
.content-filter-header-home {
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 20px 0 0 20px;
  background: #f3f8f4;
  z-index: 13;

  @media (max-width: 991px) {
    position: absolute;
    top: 0;
    height: initial;
    left: 0;
    transition: 600ms ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 20;
  }

  &-itens {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px !important;

    @media screen and (max-width: 1500px) {
      margin-left: auto !important;
    }

    @media (max-width: 991px) {
      background: #f3f8f4;
      flex-direction: column;
      justify-content: inherit;
      align-items: flex-start;
    }

    &-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 330px);

      @media (min-width: 992px) and (max-width: 1199px) {
        width: calc(100% - 230px);
      }

      @media (max-width: 991px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      .content-elements {
        position: relative;
        text-align: left;

        &:first-child {
          max-width: 185px;
        }

        &:nth-child(2) {
          max-width: 255px;
        }

        &:last-child {
          max-width: 165px;

          .content-elements-options {
            max-width: 450px;

            @media (max-width: 991px) {
              width: 100%;
            }

            &-div-btn {
              @media (max-width: 991px) {
                width: 100%;
              }
            }
          }
        }

        @media (max-width: 991px) {
          height: 120px;
          width: 100%;
          display: flex;
          align-items: center;

          &:first-child,
          &:nth-child(2),
          &:last-child {
            max-width: 100%;
          }
        }

        &-item {
          cursor: pointer;
          width: 100%;

          @media screen and (max-width: 991px) {
            width: 100%;
          }

          &-btn {
            display: flex;
            text-align: left;
            align-items: center;
            width: 100%;
            font-size: 30px;
            font-family: "Font Bold";
            color: var(--green-medium);
            background: 0;
            border: 0;
            padding: 0;
            margin-bottom: 0;

            svg {
              margin-left: 10px;
              transition: 500ms;
            }

            @media screen and (max-width: 1200px) {
              font-size: 25px;
            }

            @media screen and (max-width: 1100px) {
              font-size: 20px;
            }

            @media screen and (max-width: 991px) {
              justify-content: space-between;
              font-size: 30px;
              padding: 2rem;
            }
          }

          &-input {
            input {
              width: 100%;
              outline: none;
              border: 0;
              background: 0;
              padding: 0;
              font-size: 20px;
              font-family: "Font Bold";
              color: var(--green-dark);

              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: var(--green-dark);
                font-family: "Font Regular";
                font-size: 18px;
              }

              &::-moz-placeholder {
                /* Firefox 19+ */
                color: var(--green-dark);
                font-family: "Font Regular";
                font-size: 18px;
              }

              &:-ms-input-placeholder {
                /* IE 10+ */
                color: var(--green-dark);
                font-family: "Font Regular";
                font-size: 18px;
              }

              &:-moz-placeholder {
                /* Firefox 18- */
                color: var(--green-dark);
                font-family: "Font Regular";
                font-size: 18px;
              }

              @media screen and (max-width: 991px) {
                width: 100%;
                padding-left: 30px;
              }
            }
          }

          &-selected {
            p {
              font-size: 18px;
              font-family: "Font Bold";
              color: var(--green-dark);
              margin-bottom: 0;
            }
          }
        }

        &-options {
          position: absolute;
          display: flex;
          /* flex-wrap: wrap; */
          top: 100%;
          max-width: 800px;
          max-height: 305px;
          background: #f3f8f4;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 0px 0px 10px 10px;
          padding-bottom: 25px;
          opacity: 0;
          pointer-events: none;
          z-index: 1;
          overflow-y: auto;
          transition: 500ms;

          @media (max-width: 991px) {
            width: 100%;
          }

          &-div {
            display: flex;
            flex-direction: column;
            padding-top: 25px;

            @media (max-width: 991px) {
              width: 100%;
            }

            &-title {
              padding: 0px 25px;
              margin-bottom: 10px;

              p {
                font-size: 18px;
                font-family: "Font Regular";
                color: var(--gray);
                margin-bottom: 0;
              }
            }

            &-body {
              column-count: 2;

              @media (max-width: 991px) {
                column-count: initial;
              }
            }

            &-btn {
              font-size: 18px;
              font-family: "Font Regular";
              color: var(--green-dark);
              background: 0;
              border: 0;
              padding: 10px 25px;
              white-space: nowrap;
              /*overflow: hidden;*/
              text-overflow: ellipsis;
              text-align: left;
              width: 100%;

              &:hover,
              &:focus {
                background: var(--green-medium);
                color: var(--white);
                width: 100%;
              }

              @media (max-width: 991px) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .content-btns {
      margin-left: 80px;

      @media (min-width: 992px) and (max-width: 1199px) {
        margin-left: 30px;
      }

      @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
        padding: 0px 20px;
      }

      .btn-search {
        width: 249px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--green-medium);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        font-size: 25px;
        font-family: "Font Regular";
        color: var(--white);
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--white);
          color: var(--green-medium);

          svg {
            fill: var(--green-medium);
          }
        }

        svg {
          margin-left: 10px;
          fill: var(--white);
          transition: 500ms;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          width: 200px;
        }

        @media (max-width: 991px) {
          width: 100%;
          max-width: 354px;
          height: 72px;
          background-color: var(--green-medium);
          margin: 30px auto 0px auto;
        }
      }

      .btn-close-filter {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: 0;
        border: 0;
        padding: 50px 0px;

        p {
          font-size: 18px;
          font-family: 'Font Regular';
          color: var(--gray-dark);
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.active-filter-home {
  @media (min-width: 992px) {
    transform: translateX(0) !important;
    opacity: 1 !important;
    pointer-events: initial !important;
  }

  @media (max-width: 991px) {
    opacity: 1;
    pointer-events: initial;
  }
}

.desactive-element {
  pointer-events: none;
  opacity: 0.8;
}
</style>
