import Vue from "vue";
import VueRouter from "vue-router";
import userRoutes from '@/views/User/router';
import partnerRoutes from '@/views/Partners/router';
import CommunitiesRoutes from '@/views/Communities/router';
import BusinesspartnersRoutes from '@/views/Businesspartners/router';
import HearthRoutes from '@/views/Hearth/router';
import BlogRoutes from '@/views/Blog/router';
import ProductRoutes from '@/views/Product/router';


import Home from "../views/Home";
import About from "../views/About.vue";
import Offline from "../views/Offline.vue";
import Faq from "../views/Faq/Faq.vue";
import Politica from "../views/Politica.vue";
import Contact from '../views/Contact.vue'
import Carrers from '../views/Carrers.vue'
import Newsletter from '../views/newsletter/Newsletter.vue';
import SafeFinancesIntern from '../views/SafeFinances/SafeFinancesIntern.vue';
import Payment from '../components/sibs/Payment.vue';
import SatisfactionSurvey from '../views/newsletter/SatisfactionSurvey.vue';


import Construction from "../views/Construction.vue";
import Page404 from "../views/404.vue";


Vue.use(VueRouter);

const baseRoutes = [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/offline",
      name: "Offline",
      component: Offline,
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/faq-partners",
      name: "FaqPartners",
      component: Faq,
    },
    {
      path: "/faq-clientes",
      name: "FaqClients",
      component: Faq,
    },
    {
      path: "/politic/:slug",
      name: "Politica",
      component: Politica,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/carrers',
      name: 'Carrers',
      component: Carrers
    },
    {
      path: "/construction",
      name: "Construction",
      component: Construction,
    },
    {
      path: "/404",
      name: "Page404",
      component: Page404,
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/newsletter",
      name: "Newsletter",
      component: Newsletter,
    },
    {
      path: "/SafeFinancesIntern",
      name: "SafeFinancesIntern",
      component: SafeFinancesIntern,
    },
    {
      path: "/payment",
      name: "Payment",
      component: Payment,
    },
    {
      path: "/satisfactionsurvey/:token",
      name: "SatisfactionSurvey",
      component: SatisfactionSurvey,
    },
];

// CONCAT ROUTES
const routes = baseRoutes.concat(userRoutes, partnerRoutes, CommunitiesRoutes, 
  BusinesspartnersRoutes, HearthRoutes, BlogRoutes, ProductRoutes);


const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
         
            window.scrollTo(0, 0);
        }
    },
});

export default router;
