<template>
  <div class="content-careers">
    <div class="content-careers-itens">
      <div class="content-careers-itens-banner">
        <div
          class="content-careers-itens-banner-bg"
          :style="{
            background: `url(${require('@/assets/img/contact/banner-careers.jpg')})`,
          }"
        >
          <div class="container">
            <h1 class="content-careers-itens-banner-bg-title">
              {{$t('pages.carrers.join')}}
            </h1>
          </div>
        </div>
      </div>
      <div class="content-careers-itens-body">
        <div class="container">
          <div class="content-brand">
            <GoToBackButton />
            <div class="content-brand-elements d-none d-lg-block">
              <router-link
                tag="a"
                :to="{ name: 'Home' }"
                class="content-brand-elements-links"
              >
                {{$t('pages.goToBackButton.start')}}
              </router-link>
              <span> > </span>
              <router-link
                tag="a"
                :to="{ name: 'Careers' }"
                class="content-brand-elements-links"
              >
                {{$t('pages.carrers.join')}}
              </router-link>
            </div>
          </div>
          <div class="content-text mt-lg-5" v-for="(i,k) in contentCarrers" :key="k">
            <h2 class="title">{{ i.titulo }}</h2>
            <p class="text-default">
              {{ i.texto }}
            </p>
            
          </div>
          <div class="content-form">
            <h2 class="content-form-title">
              {{$t('pages.carrers.apply')}}
            </h2>
            <form-contact :origin="'carreiras'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormContact from "@/components/form/FormContact.vue";
import axios from "axios";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
  components: {
    FormContact,
    GoToBackButton
  },
  data() {
    return {
      contentCarrers: {},
    };
  },
  async mounted() {
    axios.post("/api/v1/footertexts", this.$route.params)
      .then((resp) => {
        this.contentCarrers = { ...resp.data.description };
        console.log(this.contentCarrers);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.content-careers {
  margin-top: 170px;
	@media (max-width: 991px){
		margin-top: 62px;
	}
  &-itens {
    &-banner {
      width: 100%;
      height: 250px;
      @media (max-width: 991px) {
        height: 200px;
      }
      @media (max-width: 576px) {
        height: 150px;
      }
      &-bg {
        width: 100%;
        height: 100%;
        position: relative;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        &::after {
          content: "";
          display: block;
          background: rgba(51, 133, 69, 0.35);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }
        .container {
          position: relative;
          width: 100%;
          height: 100%;
        }
        &-title {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: left;
          font-size: 50px;
          font-family: "Font Bold";
          color: var(--white);
          margin-bottom: 0;
          z-index: 1;
          @media (max-width: 991px) {
            font-size: 30px;
          }
        }
      }
    }
    &-body {
      padding: 60px 0px;
      @media (max-width: 991px) {
        padding: 30px 0px;
      }
      .content-text {
        text-align: left;
        margin-bottom: 100px;
        @media (max-width: 991px) {
          margin-bottom: 50px;
        }
        .title {
          font-size: 40px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 30px;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 28px;
          }
          @media (max-width: 991px) {
            font-size: 24px;
          }
        }
        .text-bold {
          font-size: 40px;
          font-family: "Font Bold";
          color: var(--green-dark);
          margin-bottom: 0;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 28px;
          }
          @media (max-width: 991px) {
            font-size: 24px;
          }
        }
        .text-default {
          font-size: 28px;
          font-family: "Font Regular";
          color: var(--green-dark);
          margin-bottom: 0;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 20px;
          }
          @media (max-width: 991px) {
            font-size: 18px;
          }
        }
      }
      .content-form {
        text-align: left;
        &-title {
          font-size: 40px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 60px;
          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 28px;
            margin-bottom: 30px;
          }
          @media (max-width: 991px) {
            font-size: 24px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
</style>