
import axios from "axios";
import { baseApiUrl } from "@/global.js";

export default {
	namespaced: true,
	state: {
		communities: [],
		communitiesFilters: [],
	},
	getters: {
		communities(state) {
			return state.communities;
		},
		communitiesFilters(state) {
			return state.communitiesFilters;
		},
	},
	mutations: {
		setCommunities(state, communities) {
			state.communities = communities;
		},
		setcommunitiesFilters(state, communitiesFilters) {
			state.communitiesFilters = communitiesFilters;
		},
	},
	actions: {
		async getCommunities({ commit, dispatch } ) {
			dispatch("setIsLoading", true, { root: true });

			axios.get(`${baseApiUrl}/community/communities`).then(resp => {
				console.log('COMUNITIES', resp.data);
				commit("setCommunities", resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
		async getCommunitiesFilters({ commit, dispatch } ) {
			dispatch("setIsLoading", true, { root: true });

			axios.get(`${baseApiUrl}/community/filters`).then(resp => {
				// console.log('OPTIONS', resp.data);
				commit("setcommunitiesFilters", resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
		async getCommunitiesBySlug({ dispatch }, slug ) {
			dispatch("setIsLoading", true, { root: true });
			return axios.post(`${baseApiUrl}/bannergetid`, slug)
		},
		async searchCommunities({ commit, dispatch }, search) {
			dispatch("setIsLoading", true, { root: true });
			console.log('SEARCH COMMUNITIES', search);
			axios.post(`${baseApiUrl}/community/search`, search).then(resp => {
				console.log('COMMUNITY SEARCH', resp.data);
				if (!resp.data.length){
					dispatch('setAlert', { 
							type: 'warning', 
							title: 'Nenhum resultado encontrado', 
							message: 'Não encontramos nenhum resultado para sua busca. Tente outros filtros.' 
						}, { root: true }
					);
				}
				commit("setCommunities", resp.data);
				dispatch("setIsLoading", false, { root: true });
			}).catch(error => {
				dispatch("setIsLoading", false, { root: true });
				dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
			});
		},
	}
}