<template>
  <header
    class="content-header"
    :class="{ 'content-header-scrolled': isScrolled || isScrolledMobile }"
  >
    <div class="content-header-itens">
      <div class="content-header-itens-div">
        <router-link
          class="content-header-itens-div-logo"
          tag="a"
          :to="{ name: 'Home' }"
        >
          <img
            class="img-fluid"
            src="@/assets/img/logos/logo-geras.png"
            alt="logo geras"
            title="logo geras"
          />
        </router-link>
        <div class="content-header-itens-div-links d-none d-lg-flex">
          <router-link class="content-links" tag="a" :to="{ name: 'About' }">
            {{ $t("pages.links.about") }}
          </router-link>
          <router-link class="content-links" tag="a" :to="{ name: 'Hearth' }">
            {{ $t("pages.links.booking") }}
          </router-link>
          <router-link
            class="content-links"
            tag="a"
            :to="{ name: 'Communities' }"
          >
            {{ $t("pages.links.community") }}
          </router-link>
          <router-link class="content-links" tag="a" :to="{ name: 'BlogList' }">
            {{ $t("pages.links.blog") }}
          </router-link>
        </div>
      </div>
      <div class="content-header-itens-btns d-none d-lg-flex">
        <div class="content-language">
          <p class="content-language-selected">
            {{ currentLocaleName }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="8.5"
              viewBox="0 0 16 8.5"
            >
              <path
                d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z"
                transform="translate(0.5 0.5)"
                fill="none"
                stroke="var(--green-medium)"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </svg>
          </p>
          <div class="content-language-options">
            <button
              v-for="lang in filteredLanguages"
              :key="lang.code"
              class="content-language-options-btn"
              @click="setLocale(lang.code)"
            >
              {{ lang.name }}
            </button>
          </div>
        </div>
        <template v-if="userIsLogged">
          <router-link
            tag="a"
            :to="{ name: 'MyAccount' }"
            class="content-name-user"
          >
            <p>
              {{ $t("pages.hearth.wellcome") }}
              <span>{{ user.name }} {{ user.surname }}</span>
            </p>
          </router-link>
          <div class="dropdown">
            <button class="btn-login">
              {{ $t("pages.login.yourAccount") }}
            </button>
            <div class="dropdown-content">
              <router-link tag="a" :to="{ name: 'MyAccount' }" class="account">
                {{ $t("pages.login.yourAccount") }}
              </router-link>
              <button @click="logoff()" class="btn-logout">
                {{ $t("pages.myaccount.logoff") }}
              </button>
            </div>
          </div>
          <!-- <router-link class="btn-login" tag="a" :to="{name: 'MyAccount'}">
            A sua conta
          </router-link> -->
        </template>
        <template v-else>
          <router-link
            class="btn-partners"
            tag="a"
            :to="{ name: 'PartnersInfo' }"
          >
            {{ $t("pages.hearth.partners") }}
          </router-link>
          <router-link class="btn-login" tag="a" :to="{ name: 'Login' }">
            {{ $t("pages.login.session") }}
          </router-link>
        </template>
      </div>
      <div class="header-filter d-lg-none">
        <button
          class="content-header-bar-filter"
          @click="setActiveFilter(true)"
          v-if="arrayRoutes.indexOf(this.$route.name) === -1"
        >
          <img
            class="img-fluid"
            src="@/assets/img/icons/lupa.png"
            alt="icon filter"
            title="icon filter"
          />
        </button>
        <div class="content-menu" @click="setMenuOpen(true)">
          <div class="content-menu-item">
            <span></span>
            <p>{{ $t("pages.btn.menu") }}</p>
          </div>
        </div>
      </div>
    </div>
    <template
      v-if="
        routesUser.indexOf(this.$route.name) === -1 &&
        arrayRoutes.indexOf(this.$route.name) === -1
      "
    >
      <filter-header />
    </template>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FilterHeader from "./FilterHeader.vue";
import userRoutes from "@/views/User/router";

export default {
  name: "Header",
  data() {
    return {
      openMenuDesktop: false,
      routesUser: [],
      arrayRoutes: [
        "Home",
        "Cart",
        "CategoriesStore",
        "HomeStore",
        "ProductIntern",
      ],
      availableLanguages: [
        { code: "pt", name: "PT (Português)" },
        { code: "en", name: "EN (Inglês)" },
        { code: "es", name: "ES (Espanhol)" },
      ],
    };
  },
  components: {
    FilterHeader,
  },
  props: {
    isScrolled: Boolean,
    isScrolledMobile: Boolean,
  },
  computed: {
    ...mapGetters(["isDesktop"]),
    ...mapGetters("User", ["userIsLogged", "user"]),
    currentLocale() {
      return this.$i18n.locale;
    },
    currentLocaleName() {
      const language = this.availableLanguages.find(
        (lang) => lang.code === this.currentLocale
      );
      return language ? language.name : "";
    },
    filteredLanguages() {
      return this.availableLanguages.filter(
        (lang) => lang.code !== this.currentLocale
      );
    },
  },
  methods: {
    ...mapActions(["setMenuOpen", "setActiveFilter"]),
    ...mapActions("User", ["accountLogout"]),
    logoff() {
      this.accountLogout()
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setUserRoutes() {
      this.routesUser = userRoutes.map((routeUser) => {
        return routeUser.name;
      });
    },
    setLocale(locale) {
      this.$store.dispatch("changeLocale", locale);
      this.$i18n.locale = locale;
      location.reload();
    },
  },
  mounted() {
    this.setUserRoutes();
  },
};
</script>

<style lang="scss">
.content-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--white);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transition: 500ms;
  z-index: 1000;

  @media (max-width: 991px) {
    z-index: 30;
  }

  &-itens {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 100px;

    @media (min-width: 1200px) and (max-width: 1299px) {
      padding: 15px 50px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 15px 20px;
    }

    @media (max-width: 991px) {
      padding: 12px 20px;
    }

    &-div {
      height: 100%;
      display: flex;
      align-items: center;

      &-logo {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 50px;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin-right: 30px;
        }

        @media (max-width: 991px) {
          margin-right: 0;
        }

        img {
          max-width: 150px;

          @media (min-width: 992px) and (max-width: 1199px) {
            max-width: 120px;
          }
        }
      }

      &-links {
        height: 100%;
        display: flex;
        align-items: center;

        .content-links {
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: "Font Semibold";
          color: var(--green-dark);
          margin-right: 30px;
          margin-bottom: 0;
          transition: 500ms;

          @media (min-width: 992px) and (max-width: 1199px) {
            margin-right: 15px;
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }

    &-btns {
      height: 100%;
      display: flex;
      align-items: center;

      .content-language {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 30px;

        &:hover,
        &:focus {
          cursor: pointer;

          .content-language-selected {
            opacity: 0.6;
          }

          .content-language-options {
            opacity: 1;
            pointer-events: initial;
          }
        }

        &-selected {
          font-size: 14px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 0;
          transition: 500ms;

          span {
            font-family: "Font Regular";
            color: var(--green-dark);
          }

          svg {
            display: none;
            margin-left: 10px;
          }
        }

        &-options {
          position: absolute;
          width: 130px;
          top: 100%;
          border-radius: 0px 0px 5px 5px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          opacity: 0;
          pointer-events: none;
          transition: 500ms;
          z-index: 999;

          &-btn {
            display: flex;
            width: 100%;
            padding: 10px;
            font-size: 14px;
            font-family: "Font Bold";
            color: var(--green-medium);
            background: var(--white);
            border: 0;
            margin-bottom: 0;
            transition: 500ms;

            &:last-child {
              border-radius: 0px 0px 5px 5px;
            }

            span {
              font-family: "Font Regular";
              color: var(--green-dark);
              transition: 500ms;
            }

            &:hover,
            &:focus {
              background: var(--green-medium);
              color: var(--white);

              span {
                color: var(--white);
              }
            }
          }
        }
      }

      .content-name-user {
        display: block;
        text-align: left;
        margin-right: 30px;
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }

        p {
          font-size: 16px;
          font-family: "Font Regular";
          color: #878787;
          line-height: 1.2;
          max-width: 200px;
          margin-bottom: 0;

          span {
            display: block;
            font-family: "Font Bold";
            color: var(--green-medium);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .btn-partners {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--white);
        border: 1px solid var(--gray);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        font-family: "Font Regular";
        color: var(--gray);
        margin-right: 30px;
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.6;
        }
      }

      .btn-login {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gray);
        border: 1px solid var(--gray);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        font-size: 16px;
        font-family: "Font Regular";
        color: var(--white);
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }

  &-bar-filter {
    all: unset;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.content-menu {
  &-item {
    width: 35px;
    display: block;
    padding-top: 8px;
    text-decoration: none !important;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    span {
      width: 100%;
      height: 2px;
      background-color: var(--green-medium);
      display: block;
      position: relative;
      transform-origin: center;
      transition: 0.5s ease-in-out;
      margin-bottom: 5px;
      margin-right: 30px;

      &::before {
        content: "";
        transition: 0.5s ease-in-out;
        top: -9px;
        display: block;
        background-color: var(--green-medium);
        position: absolute;
        height: 100%;
        width: 100%;
      }

      &::after {
        content: "";
        transition: 0.5s ease-in-out;
        bottom: -9px;
        display: block;
        background-color: var(--green-medium);
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    p {
      font-size: 10px;
      color: var(--green-medium);
      font-family: "Font Light";
      margin-bottom: 0px;
      margin-top: 12px;
      text-align: center;
    }

    .active {
      background: 0;

      &::before {
        content: "";
        top: 0 !important;
        transform: rotate(45deg) !important;
      }

      &::after {
        content: "";
        bottom: 0 !important;
        transform: rotate(-45deg) !important;
      }
    }
  }

  &-desktop {
    margin-left: 30px;
    background: 0;
    border: 0;
    opacity: 0;
    pointer-events: none;
    // padding-top: 10px;
    transition: 500ms;

    img {
      max-width: 28px;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    .icon-arrow-down {
      margin-left: 5px;
      transition: 500ms;
    }
  }
}

.btn-open {
  .icon-arrow-down {
    transform: rotate(180deg);
  }
}

.open-menu {
  margin-top: 0 !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;

  .btn-logout {
    all: unset;
    color: #878787;
    margin-top: 10px;
    font-size: 15px !important;
  }

  .account {
    text-decoration: none;
    color: #878787;
    font-size: 15px;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.header-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  gap: 3rem;

  @media (max-width: 991px) {
    padding: 0;
  }
}
</style>
