<template>
  <section class="content-about">
    <!-- BRAND -->
    <div class="container">
      <div class="content-brand">
        <GoToBackButton />
        <div class="content-brand-elements d-none d-lg-block">
          <router-link
            tag="a"
            :to="{ name: 'Home' }"
            class="content-brand-elements-links"
          >
            {{ $t("pages.goToBackButton.start") }}
          </router-link>
          <span> > </span>
          <router-link
            tag="a"
            :to="{ name: 'About' }"
            class="content-brand-elements-links"
          >
            {{ $t("pages.about.about") }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- BLOCO QUEM SOMOS -->
    <div class="content-about-whoare container">
      <div class="content-about-whoare-title">
        <h1>{{ $t("pages.about.about") }}</h1>
      </div>
      <div class="content-about-whoare-text">
        <p v-html="about.quem_somos"></p>
      </div>
    </div>
    <!-- COMO FUNCIONA -->
    <div class="content-about-funcionalidade">
      <div class="container">
        <div class="content-about-funcionalidade-box">
          <div class="content-about-funcionalidades-title">
            <h4>{{ $t("pages.about.howitworks") }}</h4>
          </div>
          <div class="content-about-funcionalidade-img">
            <img
              class="img-fluid"
              name="Imagem Fucionalidade"
              src="@/assets/img/about/image-function.png"
              alt=""
            />
          </div>
          <div
            class="content-about-funcionalidades-search"
            v-for="(buttons, index) in about.button_list"
            :key="index"
          >
            <div class="content-about-funcionalidades-subtitle">
              <p>{{ buttons.descr }}</p>
            </div>
            <div class="content-about-funcionalidades-btn">
              <button class="btn btn-primary oc-icon-plus">
                {{ buttons.button_title }}
                <span
                  ><img src="@/assets/img/about/image-lupa.png" alt=""
                /></span>
              </button>
            </div>
          </div>
          <a :href="about.url_saiba_mais" class="btn-more">
            {{ $t("pages.about.more") }}
          </a>
        </div>
      </div>
    </div>
    <!-- VANTAGENS -->
    <div class="content-about-vantagens container">
      <div class="content-about-vantagens-title">
        <h3>{{ $t("pages.about.benefits") }}</h3>
      </div>
      <div class="content-about-vantagens-box">
        <div
          class="content-about-vantagens-box-item"
          v-for="(benefit, index) in about.vantagens"
          :key="index"
        >
          <div class="box">
            <div class="boxContent">
              <img
                class="img-fluid"
                :src="setPathImage(benefit.icone)"
                alt="image benefits"
                title="image benefits"
              />
            </div>
          </div>
          <div class="box-text">
            <p>{{ benefit.vantagens }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- FUNDADORES -->
    <div class="content-about-founder">
      <div class="content-about-main-founder container">
        <div class="content-about-founder-box">
          <div class="content-about-founder-title container">
            <h3>{{ $t("pages.about.team") }}</h3>
            <p v-html="about.equipa"></p>
          </div>
          <div class="content-about-founder-img">
            <img
              class="img-fluid"
              :src="setPathImage(about.imagem_equipa)"
              alt="image team"
              title="image team"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- SOCIAL -->
    <div class="content-about-social container">
      <div class="content-about-social-title">
        <h3>{{ $t("pages.about.followsocial") }}</h3>
      </div>
      <div class="content-about-social-box">
        <div class="row">
          <div
            class="col-6 col-md-4 col-lg-auto"
            v-for="(social, index) in sociais"
            :key="index"
          >
            <a
              v-if="social.social_media === 'facebook'"
              :href="social.social_url"
              target="_blank"
              rel="noopener"
              class="box"
            >
              <div class="boxContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.873"
                  height="53.91"
                  viewBox="0 0 28.873 53.91"
                >
                  <path
                    id="Caminho_61"
                    data-name="Caminho 61"
                    d="M49.871,30.324l1.5-9.756H42.007V14.237c0-2.669,1.308-5.271,5.5-5.271h4.256V.659A51.9,51.9,0,0,0,44.208,0C36.5,0,31.46,4.673,31.46,13.132v7.436H22.89v9.756h8.57V53.91H42.007V30.324Z"
                    transform="translate(-22.89)"
                  />
                </svg>
              </div>
            </a>
            <a
              v-if="social.social_media === 'instagram'"
              :href="social.social_url"
              target="_blank"
              rel="noopener"
              class="box"
            >
              <div class="boxContent">
                <svg
                  id="XMLID_13_"
                  xmlns="http://www.w3.org/2000/svg"
                  width="54.771"
                  height="54.771"
                  viewBox="0 0 54.771 54.771"
                >
                  <path
                    id="XMLID_17_"
                    d="M38.455,0H16.317A16.335,16.335,0,0,0,0,16.317V38.455A16.335,16.335,0,0,0,16.317,54.771H38.455A16.335,16.335,0,0,0,54.771,38.455V16.317A16.335,16.335,0,0,0,38.455,0ZM49.261,38.455A10.807,10.807,0,0,1,38.455,49.261H16.317A10.807,10.807,0,0,1,5.51,38.455V16.317A10.807,10.807,0,0,1,16.317,5.51H38.455A10.807,10.807,0,0,1,49.261,16.317V38.455Z"
                    fill=""
                  />
                  <path
                    id="XMLID_81_"
                    d="M147.166,133a14.166,14.166,0,1,0,14.166,14.166A14.182,14.182,0,0,0,147.166,133Zm0,22.822a8.656,8.656,0,1,1,8.656-8.656A8.656,8.656,0,0,1,147.166,155.822Z"
                    transform="translate(-119.78 -119.78)"
                    fill=""
                  />
                  <circle
                    id="XMLID_83_"
                    cx="3.394"
                    cy="3.394"
                    r="3.394"
                    transform="translate(38.184 9.932)"
                    fill=""
                  />
                </svg>
              </div>
            </a>
            <a
              v-if="social.social_media === 'linkedin'"
              :href="social.social_url"
              target="_blank"
              rel="noopener"
              class="box"
            >
              <div class="boxContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54.117"
                  height="54.116"
                  viewBox="0 0 54.117 54.116"
                >
                  <path
                    id="Caminho_63"
                    data-name="Caminho 63"
                    d="M12.114,54.126H.894V18h11.22ZM6.5,13.067A6.529,6.529,0,1,1,13,6.508,6.553,6.553,0,0,1,6.5,13.067ZM54.105,54.126H42.91V36.538c0-4.192-.085-9.567-5.833-9.567-5.833,0-6.727,4.554-6.727,9.265v17.89H19.142V18H29.9v4.929h.157A11.789,11.789,0,0,1,40.675,17.09c11.355,0,13.442,7.477,13.442,17.19V54.126Z"
                    transform="translate(0 -0.01)"
                  />
                </svg>
              </div>
            </a>
            <a
              v-if="social.social_media === 'youtube'"
              :href="social.social_url"
              target="_blank"
              rel="noopener"
              class="box"
            >
              <div class="boxContent">
                <svg
                  id="XMLID_822_"
                  xmlns="http://www.w3.org/2000/svg"
                  width="59.298"
                  height="41.731"
                  viewBox="0 0 59.298 41.731"
                >
                  <path
                    id="XMLID_823_"
                    d="M56.986,49.5c-2.14-2.544-6.092-3.582-13.64-3.582H15.95c-7.72,0-11.739,1.1-13.871,3.814C0,52.372,0,56.264,0,61.65V71.916C0,82.35,2.467,87.648,15.95,87.648h27.4c6.545,0,10.172-.916,12.518-3.161,2.406-2.3,3.433-6.062,3.433-12.571V61.65C59.3,55.97,59.137,52.055,56.986,49.5ZM38.069,68.2,25.629,74.7a1.913,1.913,0,0,1-2.8-1.7V60.045a1.913,1.913,0,0,1,2.794-1.7l12.441,6.46a1.913,1.913,0,0,1,0,3.393Z"
                    transform="translate(0 -45.917)"
                    fill=""
                  />
                </svg>
              </div>
            </a>
            <a
              v-if="social.social_media === 'twitter'"
              :href="social.social_url"
              target="_blank"
              rel="noopener"
              class="box"
            >
              <div class="boxContent">
                <svg
                  id="XMLID_826_"
                  xmlns="http://www.w3.org/2000/svg"
                  width="54.117"
                  height="44.308"
                  viewBox="0 0 54.117 44.308"
                >
                  <path
                    id="XMLID_827_"
                    d="M52.889,33.21a20.513,20.513,0,0,1-2.617.954,11.57,11.57,0,0,0,2.356-4.143A.873.873,0,0,0,51.35,29a20.6,20.6,0,0,1-6.089,2.406,11.622,11.622,0,0,0-19.749,8.3,11.851,11.851,0,0,0,.1,1.5A29.743,29.743,0,0,1,5.193,30.385a.873.873,0,0,0-1.431.112A11.634,11.634,0,0,0,4.954,43.869,9.841,9.841,0,0,1,3.4,43.175a.873.873,0,0,0-1.3.743c0,.051,0,.1,0,.155a11.659,11.659,0,0,0,5.687,9.991c-.3-.03-.591-.072-.884-.128a.873.873,0,0,0-.995,1.124,11.614,11.614,0,0,0,8.51,7.787A20.553,20.553,0,0,1,3.434,65.981a21.017,21.017,0,0,1-2.461-.144.873.873,0,0,0-.574,1.6A31.33,31.33,0,0,0,17.341,72.4,30.14,30.14,0,0,0,40.693,62.148,32.509,32.509,0,0,0,48.785,40.96c0-.319,0-.641-.015-.962a22.584,22.584,0,0,0,5.2-5.505.873.873,0,0,0-1.079-1.283Z"
                    transform="translate(0.002 -28.097)"
                    fill=""
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BRAND -->
    <div class="container">
      <div class="content-brand">
        <router-link tag="a" :to="{ name: 'Home' }" class="btn-return">
          {{ $t("pages.goToBackButton.back") }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
  name: "About",
  components: {
    GoToBackButton,
  },
  data() {
    return {
      about: [],
      sociais: [],
      objBtnMore: [],
    };
  },
  methods: {
    setPathImage(nameImage) {
      let path = [];
      if (nameImage) {
        var host = window.location.origin;
        path = `${host}/storage/app/media${nameImage}`;
      }
      return path ? path : "";
    },
  },
  mounted() {
    axios
      .get("/api/v1/company/data")
      .then((resp) => {
        if (!resp.data) {
          return;
        }
        this.about = resp.data.about[0];
        this.sociais = resp.data.social_media;
        this.objBtnMore = resp.data.footer.filter((obj) => {
          if (
            obj.url === "/politic" &&
            obj.slug === "como-funciona-nosso-site"
          ) {
            return obj;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.content-about {
  margin-top: 220px;
  padding-bottom: 70px;

  @media (max-width: 991px) {
    margin-top: 100px;
    padding-bottom: 10px;
  }

  &-itens {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      font-family: "Font Semibold";
      font-size: 25px;
      color: #112d23;
      margin-left: 20px;

      @media (max-width: 425px) {
        font-size: 20px;
        align-items: center;
      }

      @media (max-width: 602px) {
        font-size: 20px;
        align-items: center;
      }
    }

    &-btn {
      display: flex;

      button {
        position: relative;
        display: inline-block;
        height: 47px;
        width: 115px;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1px;
        text-decoration: none;
        color: var(--green-medium);
        background: transparent;
        cursor: pointer;
        transition: ease-out 0.5s;
        border-radius: 30px;
        border: 2px solid var(--green-medium);
        border-radius: 10px;
        box-shadow: inset 0 0 0 0 var(--green-medium);

        @media (max-width: 520px) {
          height: 40px;
          width: 90px;
        }

        &:hover {
          color: white;
          box-shadow: inset 0 -100px 0 0 var(--green-medium);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  &-whoare {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 991px) {
      margin-top: 0px;
    }

    &-title {
      margin-bottom: 50px;

      @media (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: 30px;
      }

      @media (max-width: 991px) {
        margin-bottom: 15px;
      }

      h1 {
        font-family: "Font Bold";
        font-size: 50px;
        color: var(--green-dark);
        margin-bottom: 0;

        @media (min-width: 991px) and (max-width: 1199px) {
          font-size: 28px;
        }

        @media (max-width: 991px) {
          font-size: 24px;
        }
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        text-align: left;
        font-family: "Font Regular";
        font-size: 28px;
        color: var(--green-dark);

        @media (min-width: 991px) and (max-width: 1199px) {
          font-size: 20px;
        }

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
    }
  }
}

/* COMO FUNCIONA CSS*/

.content-about-funcionalidade {
  position: relative;
  overflow: hidden;
  display: flex;
  margin-top: 210px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  & > .container {
    min-height: 577px;
  }

  &-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 620px;

    @media (max-width: 991px) {
      max-width: 100%;
    }

    .btn-more {
      width: 160px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--green-medium);
      color: var(--white);
      font-size: 22px;
      font-family: "Font Regular";
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      margin-top: 70px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      transition: 500ms;

      @media (max-width: 991px) {
        margin: 60px auto;
      }

      &:hover,
      &:focus {
        cursor: pointer;
        background: var(--white);
        color: var(--green-medium);
      }
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    margin-top: 10px;
  }
}

.content-about-funcionalidades-title {
  h4 {
    font-family: "Font Bold";
    font-size: 40px;
    color: var(--green-medium);
    margin-top: 30px;

    @media (max-width: 1550px) {
      max-width: 480px;
      text-align: left;
    }

    @media (min-width: 991px) and (max-width: 1199px) {
      font-size: 28px;
    }

    @media (max-width: 991px) {
      font-size: 24px;
      text-align: left;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
}

.content-about-funcionalidades-search {
  display: flex;
  align-items: flex-start;
  margin-top: 70px;

  @media (max-width: 1550px) {
    max-width: 480px;
  }

  @media (max-width: 1400px) {
    margin-top: 50px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
  }
}

.content-about-funcionalidades-subtitle {
  display: flex;
  width: 325px;
  margin-right: 30px;

  p {
    font-family: "Font Regular";
    font-size: 24px;
    color: var(--green-dark);
    margin-bottom: 0;
    width: 100%;
    text-align: left;

    @media (max-width: 991px) {
      text-align: left;
      width: 100%;
      font-size: 18px;
    }
  }
}

.content-about-funcionalidades-btn {
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 15px;
  }

  button {
    font-family: "Font Regular";
    font-size: 25px;
    border-radius: 12px;
    width: 190px;
    height: 60px;
    background-color: var(--green-medium);
    cursor: default;
    border: 1px solid var(--green-medium);

    @media (max-width: 1026px) {
      margin-right: 20px;
    }

    @media (max-width: 991px) {
      font-size: 22px;
      max-width: 194px;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      margin-left: 10px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.content-about-funcionalidade-img {
  position: absolute;
  top: 0;
  right: -210px;
  z-index: -1;

  @media (max-width: 1700px) {
    right: -290px;
  }

  @media (max-width: 991px) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: auto;
    width: auto;
    padding: 0;
    margin: 0;
    right: inherit;
  }

  @media screen and (max-width: 576px) {
    right: -25px;
  }

  &:hover,
  &:focus {
    img {
      opacity: 0.9;
      transform: scale(1.03);
    }
  }

  img {
    @media (max-width: 1400px) {
      max-width: 80%;
    }

    @media (max-width: 1200px) {
      margin-right: -125px;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      margin-top: 30px;
    }
  }
}

/* VANTAGENS CSS */
$hoverBgColor: #4db263;
$hoverBgColor2: #287539;

@mixin createBox($width, $height, $bRadius, $bgColor, $margin) {
  width: $width;
  height: $height;
  border-radius: $bRadius;
  background-color: $bgColor;
  margin: $margin;
  transition: 0.3s all ease-in-out;
}

.content-about-vantagens {
  margin-top: 100px;
  margin-bottom: 65px;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 1400px) {
    margin-top: 30px;
  }

  @media (max-width: 991px) {
    margin-top: 0;
    margin-bottom: 80px;
  }

  h3 {
    font-family: "Font Bold";
    font-size: 40px;
    color: var(--green-medium);
    margin-bottom: 0;

    @media (min-width: 991px) and (max-width: 1199px) {
      font-size: 28px;
    }

    @media (max-width: 991px) {
      font-size: 24px;
      text-align: left;
    }
  }
}

.content-about-vantagens-box {
  margin-top: 70px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    margin-top: 15px;
  }

  .content-about-vantagens-box-item {
    width: 363px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    @media screen and (max-width: 1199px) {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 30px;
    }

    @media (max-width: 991px) {
      justify-content: flex-start;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .box {
    @include createBox(200px, 200px, 100%, var(--white), 0px);
    position: relative;
    border: 1px solid var(--green-medium);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 992px) and (max-width: 1199px) {
      @include createBox(150px, 150px, 100%, var(--white), 0px);
    }

    @media (max-width: 991px) {
      @include createBox(81px, 81px, 100%, var(--white), 0px);
    }

    .boxContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      img {
        // fill: var( --green-medium);
        max-width: 100px;
        transition: 500ms;

        @media (min-width: 992px) and (max-width: 1199px) {
          max-width: 80px;
        }

        @media (max-width: 991px) {
          max-width: 41px;
        }
      }
    }

    /* Link */
    &:hover {
      .boxContent img {
        transform: scale(1.1);
      }

      // background: linear-gradient(130deg,
      // $hoverBgColor 0%,
      // $hoverBgColor2 100%);
      // box-shadow: none;
      // svg {
      //   fill: var(--white);
      // }
    }

    &-text {
      width: 363px;
      justify-content: center;
      align-items: center;

      @media (max-width: 1199px) {
        text-align: left;
      }

      @media (max-width: 991px) {
        width: calc(100% - 91px);
      }

      p {
        margin-bottom: 0;
        font-family: "Font Regular";
        font-size: 24px;
        padding: 30px;

        @media screen and (max-width: 991px) {
          font-size: 18px;
          padding: 10px;
          text-align: left;
        }
      }
    }
  }
}

/* SOCIAL CSS */
$hoverBgColor: #4db263;
$hoverBgColor2: #287539;

@mixin createBox($width, $height, $bRadius, $bgColor, $margin) {
  width: $width;
  height: $height;
  border-radius: $bRadius;
  background-color: $bgColor;
  margin: $margin;
  transition: 0.3s all ease-in-out;
}

.content-about-social {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 1400px) {
    margin-top: 30px;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
    align-items: flex-start;
  }

  h3 {
    font-family: "Font Bold";
    font-size: 40px;
    color: var(--green-medium);
    margin-bottom: 0;

    @media (min-width: 991px) and (max-width: 1199px) {
      font-size: 28px;
    }

    @media (max-width: 991px) {
      font-size: 24px;
    }
  }
}

.content-about-social-box {
  margin-top: 75px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    gap: 1rem;
  }

  @media (max-width: 991px) {
    margin-top: 40px;
  }

  .box {
    @include createBox(135px, 135px, 100%, var(--white), 0px);
    position: relative;
    border: 1px solid var(--green-medium);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 991px) {
      @include createBox(135px, 135px, 100%, var(--white), 0px);
      margin: 0 auto 30px auto;
    }

    .boxContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      svg {
        fill: var(--green-medium);
      }
    }

    /* Link */
    &:hover {
      background: linear-gradient(
        130deg,
        $hoverBgColor 0%,
        $hoverBgColor2 100%
      );
      box-shadow: none;

      svg {
        fill: #fff;
        transition: 0.3s all ease-in-out;
      }
    }
  }
}

.content-about-founder {
  margin-top: 190px;

  @media (max-width: 991px) {
    margin-top: 30px;
  }

  &-box {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  &-title {
    display: flex;

    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 991px) {
      width: 100%;
      padding: 15px;
    }

    h3 {
      font-family: "Font Bold";
      font-size: 40px;
      color: var(--green-medium);
      margin-bottom: 23px;

      @media (min-width: 991px) and (max-width: 1199px) {
        font-size: 28px;
      }

      @media (max-width: 991px) {
        font-size: 24px;
      }
    }

    p {
      text-align: justify;
      font-family: "Font Regular";
      font-size: 28px;
      color: #112d23;
      max-width: 630px;

      @media (min-width: 1920px) {
        max-width: 58%;
      }

      @media (min-width: 991px) and (max-width: 1199px) {
        max-width: 60%;
        font-size: 20px;
      }

      @media (max-width: 991px) {
        font-size: 18px;
        max-width: 100%;
      }
    }
  }
}

.content-about-founder-img {
  position: absolute;
  right: 0;
  z-index: -1;
  /* width: 45%; */

  @media (max-width: 1400px) {
    max-width: 45%;
  }

  @media (max-width: 1700px) {
    max-width: 40%;
  }

  @media (max-width: 991px) {
    position: inherit;
    max-width: 100%;
    border: none;
  }
}

/* SOCIAL CSS*/

.content-about-social {
  margin-top: 240px;
  margin-bottom: 135px;

  @media (max-width: 991px) {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  &-box {
    h3 {
      font-family: "Font Bold";
      font-size: 40px;
      color: var(--green-medium);

      @media (min-width: 991px) and (max-width: 1199px) {
        font-size: 28px;
      }

      @media (max-width: 991px) {
        font-size: 24px;
      }
    }
  }
}

.content-about-social-box-img {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 160px;

  &-facebook {
    margin-right: 40px;
  }

  &-linkedin {
    margin-left: 40px;
  }
}

.content-about-vantagens-box-item {
  width: 363px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.content-about-main-founder {
  @media screen and (max-width: 991px) {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }
}
</style>
