import BlogList from "@/views/Blog/views/BlogList.vue";
import BlogDetail from "@/views/Blog/views/BlogDetail.vue";

export default [
	{
		path: "/blog-list",
		name: "BlogList",
		component: BlogList,
	},
	{
		path: "/blog-list/:slug",
		name: "BlogListSlug",
		component: BlogList,
	},
	{
		path: "/blog-detail/:slug",
		name: "BlogDetail",
		component: BlogDetail,
	},
]