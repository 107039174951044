<template>
  <div class="content-terms-conditions container">
    <div class="content-brand container">
      <GoToBackButton />
      <div class="content-brand-elements d-none d-lg-block">
        <router-link tag="a" :to="{ name: 'Home' }" class="content-brand-elements-links">
          {{ $t('pages.goToBackButton.start') }}
        </router-link>
        <span> > </span>
        <a class="content-brand-elements-links">
          {{ contentTerms.title }}
        </a>
      </div>
    </div>
    <div class="container content-terms-conditions-elements">
      <h1>{{ contentTerms.title }}</h1>
      <div class="content-text" v-for="(terms, index) in contentTerms.description" :key="index">
        <h2>{{ terms.titulo }}</h2>
        <p><span v-html="terms.texto"></span></p>
      </div>
    </div>
    <div class="content-brand container">
      <GoToBackButton />
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
  data() {
    return {
      contentTerms: {}
    }
  },
  components: {
    GoToBackButton
  },
  async mounted() {
    axios.post('/api/v1/footertexts', this.$route.params).then(resp => {
      this.contentTerms = { ...resp.data }
      console.log(this.contentTerms)
    }).catch(error => {
      console.log(error);
    })
  }
}
</script>

<style lang="scss" scoped>
.content-terms-conditions {
  padding: 0;
  padding-top: 50px;
  margin-top: 170px;
  text-align: left;

  @media screen and (max-width: 991px) {
    padding-top: 30px;
    margin-top: 62px;
  }

  &-elements {
    h1 {
      font-family: 'Font Bold';
      font-size: 50px;
      color: var(--green-dark);
      padding: 0;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        font-size: 35px;
      }

      @media screen and (max-width: 767px) {
        font-size: 30px;
      }

      @media screen and (max-width: 425px) {
        font-size: 25px;
      }
    }

    .content-text {
      margin-bottom: 50px;

      h2 {
        font-size: 28px;
        font-family: 'Font Bold';
        color: var(--green-dark);
        margin-bottom: 10px;

        @media (max-width: 991px) {
          font-size: 20px;
        }
      }

      p {
        font-family: 'Font Regular';
        font-size: 28px;
        color: var(--green-dark);
        line-height: 2;

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }

      ;
    }
  }
}
</style>
