<template>
  <div class="content-404">
    <div class="content-404-itens container">
      <h2 class="content-404-itens-subtitle">Desculpe :/ !</h2>
      <h1 class="content-404-itens-title">Pagina em construção!</h1>
      <p class="content-404-itens-text">
          {{ $t('pages.contruction.return')}} 
        <router-link class="content-404-itens-link" to="/">
          {{ $t('pages.contruction.page')}} 
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.content-404 {
  padding: 200px 0px;
	@media (max-width: 576px){
		padding: 100px 0px;
	}
  &-itens {
    &-subtitle {
      color: var( --green-medium);
      font-size: 36px;
      font-family: "Font Bold";
      font-weight: 800;
      padding: 0;
      margin: 0;
			@media (max-width: 576px){
				font-size: 30px;
			}
    }
    &-title {
      font-size: 64px;
      font-family: "Font Bold";
      color: var( --green-dark);
      margin-top: 16px;
			@media (max-width: 576px){
				font-size: 40px;
			}
    }
    &-text {
			font-size: 20px;
			font-family: 'Font Light';
			color: #707070;
			margin-bottom: 0;
			max-width: 665px;
			margin: 20px auto 0px auto;
			@media (max-width: 576px){
				font-size: 16px;
			}
    }
    &-link {
			font-size: 20px;
			color: var( --green-medium) !important;
			font-family: 'Font Bold';
			margin-bottom: 0;
			text-decoration: underline;
			text-decoration-color: var( --green-medium);
			transition: 500ms;
			@media (max-width: 576px){
				font-size: 16px;
			}
			&:hover,&:focus {
				cursor: pointer;
				opacity: 0.6;
			}
    }
  }
}
</style>