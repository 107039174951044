<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    this.$fire({
      type: this.$store.getters.alert.type ? this.$store.getters.alert.type : "success",
      title: this.$store.getters.alert.title ? this.$store.getters.alert.title : "Sucesso",
      text: this.$store.getters.alert.message,
			timer: 3000
    }).then(() => {
      this.$store.dispatch("setAlert", { message: "" });
    });
  },
};
</script>