<template>
  <section class="content-highlighted" v-if="products && products.length > 0">
    <div class="content-highlighted-header">
      <div class="container">
        <div class="content-highlighted-header-title">
          <h2>
            <span>{{ $t("pages.hearth.indestaq") }}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="content-highlighted-body">
      <div class="container">
        <swiper :options="swiperProducts" class="content-highlighted-grid">
          <swiper-slide v-for="(p, i) in products" :key="i">
            <CardProduct :product="p" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

import CardProduct from "@/components/cards/CardProduct.vue";

export default {
  data() {
    return {
      swiperProducts: {
        slidesPerView: 1.4,
        spaceBetween: 20,
        speed: 800,
        watchOverflow: true,
        clickable: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.6,
          },
          992: {
            slidesPerView: 4,
          },
        },
      },
    };
  },
  directives: {
    swiper: directive,
  },
  components: {
    Swiper,
    SwiperSlide,
    CardProduct,
  },
  props: ["products"],
};
</script>
<style lang="scss">
.content-highlighted {
  padding: 80px 0px;
  background-color: #f3f8f4;
  @media (max-width: 991px) {
    padding: 43px 0 113px 0;
    margin-bottom: 0 !important;
  }
  &-header {
    margin-bottom: 30px;
    @media (max-width: 991px) {
      margin-bottom: 46px;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-title {
      text-align: left;
      span {
        display: block;
        font-family: "Font Bold";
        font-size: 50px;
        color: var(--green-medium);
        @media (max-width: 991px) {
          font-size: 40px;
        }
      }
    }
    &-btn {
      width: 115px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--white);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      font-size: 18px;
      font-family: "Font Regular";
      color: var(--green-medium);
      transition: 500ms;
      @media (max-width: 991px) {
        width: 90px;
        height: 35px;
        font-size: 16px;
      }
      &:hover,
      &:focus {
        cursor: pointer;
        background: var(--green-medium);
        color: var(--white);
      }
    }
  }
  &-body {
    @media (max-width: 991px) {
      .container {
        padding-right: 0;
      }
    }
    .swiper-container {
      .swiper-slide {
        .content-sugestion-grid-item {
          margin-bottom: 0;
        }
      }
    }
  }
  &-btn {
    margin-top: 45px;
    &-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 194px;
      height: 72px;
      background-color: var(--green-medium);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
      font-size: 25px;
      font-family: "Font Regular";
      color: var(--white);
      margin: 0 auto;
      transition: 500ms;
      &:hover,
      &:focus {
        background: var(--white);
        color: var(--green-medium);
      }
    }
  }
}
</style>
