<template>
    <div class="content-contact">
        <div class="content-contact-itens">
            <div class="content-contact-itens-banner">
                <div
                    class="content-contact-itens-banner-bg"
                    :style="{
                        background: `url(${require('@/assets/img/contact/banner-contact.jpg')})`,
                    }"
                >
                    <div class="container">
                        <h1 class="content-contact-itens-banner-bg-title">
                            {{ $t('pages.contact.contact')}} 
                        </h1>
                    </div>
                </div>
            </div>
            <div class="content-contact-itens-body">
                <div class="container">
                    <div class="content-brand">
                        <GoToBackButton />
                        <div class="content-brand-elements d-none d-lg-block">
                            <router-link
                                tag="a"
                                :to="{ name: 'Home' }"
                                class="content-brand-elements-links"
                            >
                                {{$t('pages.goToBackButton.start')}}
                            </router-link>
                            <span> > </span>
                            <router-link
                                tag="a"
                                :to="{ name: 'Contact' }"
                                class="content-brand-elements-links"
                            >
                            {{ $t('pages.contact.contact')}} 
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="content-text"
                        v-for="(text, index) in contactText"
                        :key="index"
                    >
                        <div v-for="(t, i) in text.texto" :key="i">
                            <h2 class="title" v-if="t.title">{{ t.title }}</h2>
                            <h3 class="text-bold" v-if="t.telemovel">
                                {{ t.telemovel }}
                            </h3>
                            <p class="text-default" v-if="t.description">
                                {{ t.description }}
                            </p>
                            <p class="text-default" v-if="t.short_text">
                                {{ t.short_text }}
                            </p>
                        </div>
                    </div>
                    <div class="content-form">
                        <h2 class="content-form-title">
                            {{ $t('pages.contact.form')}}
                        </h2>
                        <form-contact :origin="'contato'" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormContact from "@/components/form/FormContact.vue";
import axios from "axios";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
    data() {
        return {
            contactText: [],
        };
    },
    components: {
        FormContact,
        GoToBackButton,
    },
    mounted() {
        axios
            .get("/api/v1/contacttext")
            .then((response) => {
                this.contactText = response.data;
            })
            .catch((error) => {
                console.error(error);
                this.$store.dispatch("setAlert", {
                    type: "error",
                    title: "Erro",
                    message: error.response.data.message,
                });
            });
    },
};
</script>

<style lang="scss">
.content-contact {
    margin-top: 170px;
    @media (max-width: 991px) {
        margin-top: 62px;
    }
    &-itens {
        &-banner {
            width: 100%;
            height: 250px;
            @media (max-width: 991px) {
                height: 200px;
            }
            @media (max-width: 576px) {
                height: 150px;
            }
            &-bg {
                width: 100%;
                height: 100%;
                position: relative;
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                &::after {
                    content: "";
                    display: block;
                    background: rgba(51, 133, 69, 0.35);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                }
                .container {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
                &-title {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: left;
                    font-size: 50px;
                    font-family: "Font Bold";
                    color: var(--white);
                    margin-bottom: 0;
                    z-index: 1;
                    @media (max-width: 991px) {
                        font-size: 24px;
                    }
                }
            }
        }
        &-body {
            padding: 60px 0px;
            @media (max-width: 991px) {
                padding: 30px 0px;
            }
            .content-text {
                text-align: left;
                margin-bottom: 100px;
                @media (max-width: 991px) {
                    margin-bottom: 50px;
                }
                .title {
                    font-size: 40px;
										margin-top: 80px;
                    font-family: "Font Bold";
                    color: var(--green-medium);
                    margin-bottom: 30px;
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 28px;
                    }
                    @media (max-width: 991px) {
                        font-size: 24px;
												margin-top: 40px;
                    }
                }
                .text-bold {
                    font-size: 40px;
                    font-family: "Font Bold";
                    color: var(--green-dark);
                    margin-bottom: 0;
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 28px;
                    }
                    @media (max-width: 991px) {
                        font-size: 24px;
                    }
                }
                .text-default {
                    font-size: 28px;
                    font-family: "Font Regular";
                    color: var(--green-dark);
                    margin-bottom: 0;
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 20px;
                    }
                    @media (max-width: 991px) {
                        font-size: 18px;
                    }
                }
            }
            .content-form {
                text-align: left;
                &-title {
                    font-size: 40px;
                    font-family: "Font Bold";
                    color: var(--green-medium);
                    margin-bottom: 60px;
                    @media (min-width: 992px) and (max-width: 1199px) {
                        font-size: 28px;
                        margin-bottom: 30px;
                    }
                    @media (max-width: 991px) {
                        font-size: 24px;
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}
</style>
