<template>
  <div class="home">
    <Banner />
    <section class="content-search">
      <button
        class="btn-what-is-demand d-lg-none"
        @click="setActiveFilterHome(true)"
      >
        <p>{{ $t("pages.hearth.whatsearch") }}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="17.5"
          viewBox="0 0 33 17.5"
        >
          <path
            d="M31,0,15.5,15.5ZM0,0,15.5,15.5Z"
            transform="translate(1 1)"
            fill="none"
            stroke="#338545"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </button>
      <FilterHeaderHome />
    </section>
    <ListProducts />
    <template v-if="Object.keys(products).length">
      <Highlighted :products="products" />
    </template>
    <Services />
    <section class="content-news d-none d-lg-block">
      <div class="content-news-item" v-if="publicities.image_fullpage == null">
        <!-- <img class="img-fluid" src="../../assets/img/home/news.png" alt="news" /> -->
      </div>
      <a
        :href="publicities.image_fullpage_url"
        target="_blank"
        rel="noopener"
        class="content-news-item"
        v-else
      >
        <img
          class="img-fluid"
          :src="publicities.image_fullpage.path"
          alt="news"
        />
      </a>
    </section>
    <ListBlog />
    <div class="d-none d-lg-block" v-if="publicities.video_image">
      <Video :publicity="publicities.video_image" />
    </div>
  </div>
</template>

<script>
import Video from "../../components/video/Video.vue";
import FilterHeaderHome from "../../components/headers/FilterHeaderHome.vue";
import { mapActions, mapGetters } from "vuex";
import Banner from "./components/Banner.vue";
import ListProducts from "./components/ListProducts.vue";
import ListBlog from "./components/ListBlog.vue";
import Services from "./components/Services.vue";
import Highlighted from "./components/Highlighted.vue";
import axios from "axios";

export default {
  data() {
    return {
      sliding: null,
      text: [],
      about: [],
      products: [],
    };
  },

  components: {
    Video,
    FilterHeaderHome,
    Banner,
    ListProducts,
    ListBlog,
    Services,
    Highlighted,
  },
  methods: {
    ...mapActions(["setActiveFilter", "setActiveFilterHome"]),
  },
  computed: {
    ...mapGetters({
      publicities: "Publicity/publicities",
    }),
  },
  async mounted() {
    this.$store.dispatch("Publicity/getPublicities", this.$route.name);
    axios
      .get("/api/v1/alltexts")
      .then((response) => {
        if (response && response.data) {
          this.text = response.data;
          for (let i = 0; i < this.text.length; i++) {
            if (this.text[i].widget == 3) {
              this.about = this.text[i];
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("api/v1/site/producthighlighted")
      .then((resp) => {
        if (resp && resp.data) {
          this.products = resp.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.home {
  section {
    margin-bottom: 7rem;

    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-what-is-demand {
    width: 100%;
    height: 120px;
    background: #f3f8f4;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 35px;

    p {
      font-size: 30px;
      font-family: "Font Bold";
      color: var(--green-medium);
      width: calc(100% - 35px);
      text-align: left;
      margin-bottom: 0;
    }
  }

  .content-search {
    position: relative;
    width: 82%;
    height: 150px;
    margin: -150px 0 80px auto;

    @media (max-width: 991px) {
      width: 100%;
      height: initial;
      margin: 0px 0px 17px 0px;
    }

    .content-filter-header {
      height: 100%;
      border-radius: 20px 0px 0px 20px;
      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.16);

      @media (max-width: 991px) {
        z-index: 30;
      }

      &-itens {
        .content-elements-item-btn {
          @media (min-width: 1200px) {
            font-size: 30px;
          }

          @media (min-width: 991px) and (max-width: 1199px) {
            font-size: 24px;
          }

          @media screen and (max-width: 991px) {
            font-size: 30px;

            svg {
              width: 31px;
              height: 15px;
            }
          }
        }

        .btn-search {
          @media (min-width: 1200px) {
            width: 250px;
            height: 72px;
            font-size: 25px;
          }

          @media (min-width: 991px) and (max-width: 1199px) {
            height: 60px;
            font-size: 25px;
            width: 200px;
          }
        }
      }
    }
  }

  .content-whoweare {
    width: 100%;
    height: 519px;
    background-color: #f3f8f4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #112d23;

    @media screen and (max-width: 1200px) {
      height: auto;
      padding: 2rem 0;
    }

    &-text {
      max-width: 60%;

      @media screen and (max-width: 991px) {
        max-width: 100%;
      }

      h3 {
        text-transform: uppercase;
        font-size: 1.75rem;
        font-family: "Font Regular", sans-serif;
        margin: 1rem;

        @media screen and (max-width: 1200px) {
          font-size: 1.5rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }

      h1 {
        font-size: 3.5rem;
        font-family: "Font Bold", sans-serif;
        margin: 1rem;
        color: var(--green-medium);

        @media screen and (max-width: 1200px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 768px) {
          font-size: 1.5rem;
        }
      }

      p {
        font-size: 1.5rem;
        font-family: "Font Regular", sans-serif;
        margin: 2rem;

        @media screen and (max-width: 1200px) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: 991px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 768px) {
          font-size: 0.8rem;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        background-color: var(--green-medium);
        width: 174px;
        height: 60px;
        color: #fff;
        border: none;
        border-radius: 10px;
        font-size: 1.3755rem;
        font-family: "Font Regular", sans-serif;

        &:hover,
        &:focus {
          transition: all 0.5s ease;
          filter: drop-shadow(0 0 10px var(--green-medium));
        }

        @media screen and (max-width: 991px) {
          width: 150px;
          height: 50px;
          font-size: 1.2rem;
        }

        @media screen and (max-width: 768px) {
          width: 120px;
          height: 50px;
          font-size: 1rem;
        }

        @media screen and (max-width: 576px) {
          width: 100px;
          height: 40px;
          font-size: 0.8rem;
        }
      }
    }
  }

  .content-news {
    width: 100%;
    height: 258px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      height: auto;
      padding: 1.5rem 0;
    }

    &-item {
      height: 258px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1200px) {
        margin: 0.5rem;
      }

      @media screen and (max-width: 991px) {
        height: auto;
        margin: 0 1rem;
      }
    }
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .title {
    display: flex;
    width: 100%;

    &-simple {
      color: #112d23;
      font-size: 3.125rem;
      font-family: "Font Regular", sans-serif;
      margin-right: 0.8rem;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }

    &-strong {
      color: var(--green-medium);
      font-size: 3.125rem;
      font-family: "Font Bold", sans-serif;

      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }

      @media screen and (max-width: 576px) {
        font-size: 1.5rem;
      }
    }
  }
}
</style>
