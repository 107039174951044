export default {
	methods: {
		openModal: function (id) {
			this._vm.$bvModal.show(id);
			this.$bvModal.show(id);
		},
		closeModal: function (id) {
			this.$bvModal.hide(id);
		},
		async getAllData(form) {
			let formData = new FormData(form);
			const data = {};
			// need to convert it before using not with XMLHttpRequest
			for (let [key, val] of formData.entries()) {
				Object.assign(data, { [key]: val })
			}

			data.visitor = document.querySelector("meta[name=visitor]").getAttribute('content');
			data.user = document.querySelector("meta[name=user]").getAttribute('content');
			data.host = window.location.hostname;

			return data;
		},
	}
}

