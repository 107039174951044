<template>
    <form class="content-newsletter container">
        <div class="content-brand">
            <GoToBackButton />
            <div class="content-brand-elements d-none d-lg-block">
                <router-link
                    tag="a"
                    :to="{ name: 'Home' }"
                    class="content-brand-elements-links"
                >
                {{ $t('pages.goToBackButton.start') }}
                </router-link>
                <span> > </span>
                <router-link
                    tag="a"
                    :to="{ name: 'Newsletter' }"
                    class="content-brand-elements-links"
                >
                    {{ $t('pages.news.title') }}
                </router-link>
            </div>
        </div>
        <div class="content-newsletter-body">
            <div class="content-newsletter-body-title">
                <h1> {{ $t('pages.news.subscribe') }}</h1>
            </div>
            <div class="content-newsletter-form">
                <FormContactNoRegister
                    :origin="this.origin"
                    :categories="categories"
                />
            </div>

            <div class="content-brand">
                <GoToBackButton />
            </div>
        </div>
    </form>
</template>

<script>
import axios from "axios";
import FormContactNoRegister from "@/components/form/FormContactNoRegister.vue";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
    data() {
        return {
            categories: [],
            origin: "newsletter",
            forget: {
                token: this.$store.getters["User/userIsLogged"]
            }
        };
    },
    components: {
        FormContactNoRegister,
        GoToBackButton,
    },
    mounted() {
        axios.post("/api/v1/company/partnertypes", this.forget)
            .then((response) => {
                this.categories = response.data;
            })
            .catch((error) => {
                console.error(error);
                this.$store.dispatch("setAlert", {
                    type: "error",
                    title: "Erro",
                    message: error.response.data.message,
                });
            });
    },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
