<template>
  <b-modal id="modal-comments" centered class="modal-comments">
    <div class="modal-comments-itens">
      <button
        class="modal-comments-itens-close"
        @click="$bvModal.hide('modal-comments')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.78"
          height="21.15"
          viewBox="0 0 18.78 21.15"
        >
          <g
            id="Grupo_1753"
            data-name="Grupo 1753"
            transform="translate(-411.263 -553.556)"
          >
            <g
              id="Grupo_1413"
              data-name="Grupo 1413"
              transform="translate(403.688 564.131) rotate(-45)"
            >
              <path
                id="União_2"
                data-name="União 2"
                d="M18.15,0,9.075,7.779ZM0,0,9.075,7.779Z"
                transform="translate(0 12.834) rotate(-45)"
                fill="none"
                stroke="var( --green-medium)"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
            <g
              id="Grupo_1593"
              data-name="Grupo 1593"
              transform="translate(437.617 564.131) rotate(135)"
            >
              <path
                id="União_2-2"
                data-name="União 2"
                d="M18.15,0,9.075,7.779ZM0,0,9.075,7.779Z"
                transform="translate(0 12.834) rotate(-45)"
                fill="none"
                stroke="var( --green-medium)"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
          </g>
        </svg>
      </button>
      <div class="modal-comments-itens-body">
        <div class="modal-comments-itens-body-title">
          <h2>Deixe o seu comentário</h2>
        </div>
      </div>
    </div>
    <form @submit.prevent="sendForm()">
      <div class="modal-comments-itens-input">
        <textarea
          placeholder="Escreva a sua mensagem"
          v-model="form.comments"
        ></textarea>
      </div>
      <div class="modal-comments-itens-btns">
        <button
          class="btn-send"
          type="submit"
          @click="$bvModal.hide('modal-comments')"
        >
          Enviar
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {
        comments: "",
        slug: this.$route.params.slug,
        access_token: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "User/user",
    }),
  },
  methods: {
    sendForm() {
      axios
        .post("/api/v1/blog/setcomment", this.form)
        .then((response) => {
          if (response.data.response.success === true) {
            Vue.fire({
              type: "success",
              title: "Success",
              text: "Sua avaliação foi enviada com sucesso!",
              timer: 3000,
              showConfirmButton: true,
            });
          } else {
            Vue.fire({
              type: "error",
              title: "erro",
              text: "Por favor, tente novamente.",
              timer: 3000,
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: "Erro",
            variant: "danger",
            solid: true,
            toast: true,
            autoHideDelay: 5000,
          });
        });
    },
  },
  mounted() {
    if (this.user?.access_token) {
      this.form.access_token = this.user.access_token;
    }
  },
};
</script>

<style lang="scss">
#modal-comments {
  .modal-header,
  .modal-footer {
    display: none;
  }
  .modal-dialog {
    max-width: 945px;
    margin: auto !important;
    @media screen and (max-width: 991px) {
      max-width: 384px;
    }
  }
  .modal-content {
    border-radius: 0;
    border: 0;
  }
  .modal-body {
    padding: 50px 100px 100px;
    height: 576px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding: 50px 20px;
    }
  }
}
.modal-comments {
  &-itens {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    &-close {
      width: 62px;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
      border: 1px solid var(--green-medium);
      border-radius: 10px;
      transition: 500ms;
      @media (max-width: 991px) {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        svg {
          max-width: 15px;
        }
      }
      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }
    &-body {
      max-height: 345px;
      overflow-y: auto;
      &-title {
        h2 {
          font-size: 30px;
          font-weight: bold;
          color: var(--green-dark);
          @media (max-width: 991px) {
            font-size: 22px;
          }
        }
      }
      .content-comments-user {
        padding: 30px 0px;
        border-bottom: 1px solid #b7b8b8;
      }
    }
    &-input {
      padding: 30px 0px;
      @media screen and (max-width: 991px) {
        height: calc(100% - 44px);
        padding: 40px 0;
      }
      textarea {
        display: block;
        width: 100%;
        height: 245px;
        background: var(--white);
        border: 1px solid #bebebe;
        border-radius: 10px;
        padding-top: 10px;
        padding-left: 20px;
        font-size: 20px;
        font-family: "Font Regular";
        color: var(--green-dark);
        outline: none;
        resize: none;
        @media (max-width: 991px) {
          font-size: 16px;
          height: 100%;
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgba(135, 135, 135, 0.5);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: rgba(135, 135, 135, 0.5);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgba(135, 135, 135, 0.5);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: rgba(135, 135, 135, 0.5);
        }
      }
    }
    &-btns {
      .btn-send {
        width: 122px;
        height: 45px;
        background: var(--green-medium);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        font-size: 18px;
        font-family: "Font Regular";
        color: var(--white);
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--white);
          color: var(--green-medium);
        }
      }
    }
  }
}
form {
  margin-top: 30px;
  @media screen and (max-width: 991px) {
    height: 100%;
    margin-top: 0px;
  }
  .content-element {
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      margin-bottom: 0px;
    }
  }
  .content-element-radio {
    div:first-of-type(1) {
      width: 100%;
    }
    &-input {
      display: flex !important;
      justify-content: space-between !important;
      height: 36px;
      @media screen and (max-width: 991px) {
        flex-direction: column !important;
        height: auto;
        gap: 25px;
      }
      [type="radio"]:checked,
      [type="radio"]:not(:checked) {
        position: absolute;
        left: 3px;
        opacity: 0;
      }
      [type="radio"]:checked + label,
      [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 40px;
        height: 36px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-family: "Font Regular";
        color: var(--green-dark);
        margin-bottom: 0px;
        line-height: 1;
      }
      [type="radio"]:checked + label:before,
      [type="radio"]:not(:checked) + label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100%;
        background: #fff;
        border: 1px solid #bebebe;
      }
      [type="radio"]:checked + label:after,
      [type="radio"]:not(:checked) + label:after {
        content: "";
        width: 14px;
        height: 14px;
        background: var(--green-medium);
        background-image: none !important;
        position: absolute;
        top: 11px;
        left: 11px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      .custom-control-input {
        width: 40px;
        height: 36px;
        margin-right: 10px;
      }
      .custom-control-inline {
        margin-right: 0px !important;
      }
      .custom-control-label::before,
      .custom-control-label::after {
        width: 36px;
        height: 36px;
      }
      label {
        height: 100%;
        padding-left: 15px;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 0px !important;
        span {
          svg {
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
}
</style>
