import Vue from 'vue'
import Vuex from 'vuex'

//MODULES
import User from "./modules/User";
import Publicity from "./modules/Publicity";
import FilterHeader from "./modules/FilterHeader";
import Communities from "@/views/Communities/store";
import Hearth from "@/views/Hearth/store";
import Partners from "@/views/Businesspartners/store";
import Products from "@/views/Product/store";
import Cart from "./modules/Cart";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuOpen: false,
    isDesktop: null,
    isLoading: false,
    activeBackdrop: false,
    activeFilterHearth: false,
    activeFilter: false,
    activeFilterHome: false,
    activeFilterCategories: false,
    activeCart: false,
    activeCategory: false,
    isCategory: false,
    isDemand: false,
    isCounty: false,
    token: localStorage.getItem('token') || null,
    user: localStorage.getItem('user') || null,
    locale: localStorage.getItem('locale') || 'pt',
    alert: {
      open: false,
      message: '',
    },
  },
  getters: {
    menuOpen(state) {
      return state.menuOpen;
    },
    isDesktop(state) {
      return state.isDesktop;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeBackdrop(state) {
      return state.activeBackdrop;
    },
    activeFilterHearth(state) {
      return state.activeFilterHearth;
    },
    activeFilter(state) {
      return state.activeFilter;
    },
    activeFilterHome(state) {
      return state.activeFilterHome;
    },
    activeFilterCategories(state) {
      return state.activeFilterCategories;
    },
    activeCart(state) {
      return state.activeCart;
    },
    activeCategory(state) {
      return state.activeCategory;
    },
    alert: state => {
      return state.alert;
    },
    isCategory(state) {
      return state.isCategory;
    },
    isDemand(state) {
      return state.isDemand;
    },
    isCounty(state) {
      return state.isCounty;
    },
  },
  mutations: {
    setMenuOpen(state, value) {
      state.menuOpen = value;
    },
    setIsDesktop(state, value) {
      state.isDesktop = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setActiveBackdrop(state, value) {
      state.activeBackdrop = value;
      if (value) {
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
      }
    },
    setActiveFilterHearth(state, value) {
      state.activeFilterHearth = value;
    },
    setActiveFilter(state, value) {
      state.activeFilter = value;
    },
    setActiveFilterHome(state, value) {
      state.activeFilterHome = value;
    },
    setActiveFilterCategories(state, value) {
      state.activeFilterCategories = value;
    },
    setActiveCart(state, value) {
      state.activeCart = value;
    },
    setActiveCategory(state, value) {
      state.activeCategory = value;
    },
    setAlert(state, value) {
      state.alert = value;
    },
    setIsCategory(state, value) {
      state.isCategory = value;
    },
    setIsDemand(state, value) {
      state.isDemand = value;
    },
    setIsCounty(state, value) {
      state.isCounty = value;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
      localStorage.setItem('locale', locale);
    },
  },
  actions: {
    setMenuOpen(context, value) {
      context.commit('setActiveBackdrop', value);
      context.commit('setMenuOpen', value);
    },
    setIsLoading(context, value) {
      context.commit('setIsLoading', value);
    },
    setActiveFilterHearth(context, value) {
      context.commit('setActiveBackdrop', value);
      context.commit('setActiveFilterHearth', value);
    },
    setActiveFilter(context, value) {
      context.commit('setActiveBackdrop', value);
      context.commit('setActiveFilter', value);
    },
    setActiveFilterHome(context, value) {
      context.commit('setActiveFilterHome', value);
    },
    setActiveFilterCategories(context, value) {
      context.commit('setActiveBackdrop', value);
      context.commit('setActiveFilterCategories', value);
    },
    setActiveCart(context, value) {
      context.commit('setActiveBackdrop', value);
      context.commit('setActiveCart', value);
    },
    setActiveCategory(context, value) {
      context.commit('setActiveBackdrop', value);
      context.commit('setActiveCategory', value);
    },
    setAlert({ commit }, { type, title, message }) {
      let opts = { open: true, key: (new Date().getTime()) };
      opts.type = type;
      opts.title = title;
      opts.message = message;

      if (opts.message != '') {
        commit('setAlert', opts);
      } else {
        opts.open = false;
        commit('setAlert', opts);
      }
    },
    setIsCategory(context, value) {
      context.commit('setIsCategory', value);
    },
    setIsDemand(context, value) {
      context.commit('setIsDemand', value);
    },
    setIsCounty(context, value) {
      context.commit('setIsCounty', value);
    },
    changeLocale({ commit }, locale) {
      commit('SET_LOCALE', locale);
    },
  },
  modules: {
    User,
    Publicity,
    FilterHeader,
    Communities,
    Hearth,
    Partners,
    Products,
    Cart,
  }
})
