<template>
  <div class="content-hearth">
    <div class="content-hearth-itens">
      <div class="content-hearth-itens-banner">
        <div
          class="content-hearth-itens-banner-bg"
          :style="{
            background: `url(${require('@/assets/img/hearth/banner-hearth.jpg')})`,
          }"
        >
          <template v-if="banner.title == null || banner.title == ''">
            <div class="container">
              <h1 class="content-hearth-itens-banner-bg-title">
                {{ $t("pages.hearth.title") }}
              </h1>
            </div>
          </template>
          <template v-else>
            <div class="container">
              <h1 class="content-hearth-itens-banner-bg-title">
                {{ banner.title }}
              </h1>
            </div>
          </template>
        </div>
      </div>
      <div class="content-hearth-itens-body">
        <div class="container">
          <div class="brand-filter">
            <div class="content-brand">
              <GoToBackButton />
              <div class="content-brand-elements d-none d-lg-block">
                <router-link
                  tag="a"
                  :to="{ name: 'Home' }"
                  class="content-brand-elements-links"
                >
                  {{ $t("pages.goToBackButton.start") }}
                </router-link>
                <span> > </span>
                <router-link
                  v-if="banner.title == null || banner.title == ''"
                  tag="a"
                  :to="{ name: 'Hearth' }"
                  class="content-brand-elements-links"
                >
                  {{ $t("pages.hearth.title") }}
                </router-link>
                <router-link
                  v-else
                  tag="a"
                  :to="{ name: 'Hearth' }"
                  class="content-brand-elements-links"
                >
                  {{ banner.title }}
                </router-link>
              </div>
            </div>
            <button
              class="btn-filter d-lg-none"
              @click="setActiveFilterHearth(true)"
            >
              <p>{{ $t("generic.filter") }}</p>
              <img
                class="img-fluid"
                src="@/assets/img/icons/filter.png"
                alt="icon filter"
                title="icon filter"
              />
            </button>
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div
                class="content-filter"
                :class="{
                  'active-filter-hearth': activeFilterHearth,
                }"
              >
                <div class="content-filter-btn d-lg-none">
                  <button
                    class="content-filter-btn-close"
                    @click="setActiveFilterHearth(false)"
                  >
                    <img
                      class="img-fluid"
                      src="@/assets/img/icons/close.png"
                      alt="icon close"
                      title="icon close"
                    />
                  </button>
                </div>
                <div class="content-filter-elements">
                  <div class="content-filter-elements-title">
                    <p>{{ $t("generic.filteroptions") }}</p>
                  </div>
                  <div
                    class="content-filter-elements-selected"
                    v-for="i in selected"
                    :key="i"
                  >
                    <div class="content-items-selected">
                      <button
                        @click="remove(i)"
                        class="content-items-selected-btn"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.793"
                          height="11.793"
                          viewBox="0 0 11.793 11.793"
                        >
                          <g transform="translate(-57.743 -531.984) rotate(45)">
                            <line
                              x2="15.677"
                              transform="translate(417.5 335.339)"
                              fill="none"
                              stroke="red"
                              stroke-width="1"
                            />
                            <line
                              x2="15.677"
                              transform="translate(425.339 327.5) rotate(90)"
                              fill="none"
                              stroke="red"
                              stroke-width="1"
                            />
                          </g>
                        </svg>

                        <p>{{ changeText(i) }}</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="content-filter-elements">
                  <div
                    class="content-filter-elements-collapse"
                    v-for="(obj, tf, index) in options"
                    :key="index"
                  >
                    <!-- btn to collapse -->
                    <button v-b-toggle="`text${index}`" class="btn-collapse">
                      <p>{{ tf }}</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="8.5"
                        viewBox="0 0 16 8.5"
                      >
                        <path
                          d="M15,0,7.5,7.5ZM0,0,7.5,7.5Z"
                          transform="translate(15.5 8) rotate(180)"
                          fill="none"
                          stroke="var( --green-medium)"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </svg>
                    </button>
                    <!-- Element to collapse -->
                    <b-collapse :id="`text${index}`">
                      <div class="content-collapse-input-check checkbox-group">
                        <div
                          class="content-collapse-input-check checkbox-group"
                          v-for="(obj, i) in obj"
                          :key="i"
                        >
                          <div class="content-check">
                            <input
                              type="checkbox"
                              :id="`checkbox${obj.id}`"
                              :value="`${obj.filter_name}-${obj.id}`"
                              v-model="selected"
                              @change="toggleItem(obj)"
                            />
                            <label class="" :for="`checkbox${obj.id}`">
                              <span class="content-collapse-input-check-text">{{
                                obj.filter_name
                              }}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <button class="btn-send-filter" @click="filterPage()">
                  {{ $t("generic.filter") }}
                </button>
              </div>
              <div
                class="content-ads d-none d-lg-flex"
                v-if="publicities.image_halfpage == null"
              >
                <img
                  class="img-fluid"
                  src="@/assets/img/hearth/image-ads.png"
                  alt="image ads"
                  title="image ads"
                />
              </div>
              <a
                :href="publicities.image_halfpage_url"
                target="_blank"
                rel="noopener"
                class="content-ads d-none d-lg-flex"
                v-else
              >
                <img
                  class="img-fluid"
                  :src="publicities.image_halfpage.path"
                  alt="image ads"
                  title="image ads"
                />
              </a>
            </div>
            <div class="col-lg-8">
              <div
                class="content-hearth-partner"
                v-for="(i, index) in hearth"
                :key="index"
              >
                <div>
                  <router-link
                    tag="a"
                    :to="{ name: 'HearthIntern', params: { slug: i.slug } }"
                    class="content-card"
                  >
                    <div class="content-card-image">
                      <div
                        class="content-card-image-bg"
                        :style="{
                          background: `url(${i.image}) center center no-repeat`,
                        }"
                      ></div>
                    </div>
                    <div class="content-card-div">
                      <div class="content-card-text-header-classification">
                        <p>{{ i.rate }}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.943"
                          height="16.216"
                          viewBox="0 0 16.943 16.216"
                        >
                          <path
                            d="M28.062.551,25.994,4.744l-4.627.675a1.014,1.014,0,0,0-.561,1.729l3.347,3.262-.792,4.608a1.013,1.013,0,0,0,1.469,1.067l4.139-2.176,4.139,2.176a1.014,1.014,0,0,0,1.469-1.067l-.792-4.608,3.347-3.262a1.014,1.014,0,0,0-.561-1.729l-4.627-.675L29.88.551a1.014,1.014,0,0,0-1.818,0Z"
                            transform="translate(-20.5 0.013)"
                          />
                        </svg>
                      </div>
                      <div class="content-card-text">
                        <div class="content-card-text-header">
                          <h2>{{ i.title }}</h2>
                        </div>
                        <div class="content-card-text-body">
                          <p v-html="i.description"></p>
                        </div>
                        <div class="content-card-text-location">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12.679"
                            height="16.905"
                            viewBox="0 0 12.679 16.905"
                          >
                            <path
                              d="M6.339,3.17a3.17,3.17,0,1,0,3.17,3.17A3.173,3.173,0,0,0,6.339,3.17Zm0,5.283A2.113,2.113,0,1,1,8.452,6.339,2.115,2.115,0,0,1,6.339,8.452ZM6.339,0A6.339,6.339,0,0,0,0,6.339C0,8.9.89,9.609,5.688,16.564a.793.793,0,0,0,1.3,0c4.8-6.955,5.688-7.669,5.688-10.225A6.339,6.339,0,0,0,6.339,0Zm0,15.648C1.74,9,1.057,8.469,1.057,6.339A5.283,5.283,0,0,1,10.075,2.6a5.248,5.248,0,0,1,1.547,3.735C11.622,8.469,10.939,9,6.339,15.648Z"
                              fill="var( --green-medium)"
                            />
                          </svg>
                          <p>{{ i.distrito }}, {{ i.concelho.name }}</p>
                        </div>
                        <div class="content-tags-more" v-if="i.tags.length > 0">
                          <b-dropdown
                            id="dropdown-right"
                            right
                            down
                            text="Right align"
                            toggle-class="text-decoration-none"
                            no-caret
                            no-flip
                            block
                          >
                            <template #button-content>
                              <svg
                                id="Component_69_1"
                                data-name="Component 69 – 1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="33"
                                viewBox="0 0 35 33"
                              >
                                <rect
                                  id="Rectangle_2350"
                                  data-name="Rectangle 2350"
                                  width="35"
                                  height="24"
                                  rx="10"
                                  transform="translate(0 5)"
                                  fill="#b7b8b8"
                                />
                                <text
                                  id="_"
                                  data-name="+"
                                  transform="translate(11 26)"
                                  fill="#fff"
                                  font-size="24"
                                  font-family="OpenSans-Light, Open Sans"
                                  font-weight="300"
                                >
                                  <tspan x="0" y="0">+</tspan>
                                </text>
                              </svg>
                            </template>
                            <b-dropdown-item-button
                              class="content-tags-more-tags"
                            >
                              <div
                                class="content-tags-more-tags-itens"
                                v-for="(all, allIdx) in i.tags"
                                :key="allIdx"
                              >
                                <p>{{ all.name }}</p>
                              </div>
                            </b-dropdown-item-button>
                          </b-dropdown>
                        </div>
                      </div>
                      <div class="content-tags">
                        <div
                          class="content-tags-elements"
                          v-for="(tag, tagIdx) in i.tags"
                          :key="tagIdx"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                          >
                            <circle cx="4" cy="4" r="4" fill="#b7b8b8" />
                          </svg>
                          <p>{{ tag.name }}</p>
                        </div>
                      </div>
                      <router-link
                        tag="a"
                        :to="{ name: 'HearthIntern', params: { slug: i.slug } }"
                        class="content-btn mt-4"
                      >
                        <button class="btn-consult">
                          {{ $t("generic.consult") }}
                        </button>
                      </router-link>
                    </div>
                  </router-link>
                </div>
                <div
                  class="content-ads-banner d-none d-lg-flex"
                  v-if="index == 4"
                >
                  <div v-if="publicities.image_fullpage == null">
                    <img
                      class="img-fluid"
                      src="@/assets/img/hearth/banner-ads.png"
                      alt="banner hearth"
                      title="banner hearth"
                    />
                  </div>
                  <a
                    :href="publicities.image_fullpage_url"
                    target="_blank"
                    rel="noopener"
                    v-else
                  >
                    <img
                      class="img-fluid"
                      :src="publicities.image_fullpage.path"
                      alt="banner hearth"
                      title="banner hearth"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-lg-block" v-if="publicities.video_image">
        <Video :publicity="publicities.video_image" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Video from "@/components/video/Video.vue";
import GoToBackButton from "@/components/goToBackButton/GoToBackButton.vue";

export default {
  data() {
    return {
      selected: [],
      filters: [],
      banner: [],
      selected_filters: [],
      city_id: null,
      slug: this.$route.params.slug,
      target: [],
      slugFixed: "lares-e-servicos",
    };
  },
  components: {
    Video,
    GoToBackButton,
  },
  computed: {
    ...mapGetters(["activeFilterHearth"]),
    ...mapGetters({
      hearth: "Hearth/hearth",
      options: "Hearth/hearthFilters",
      publicities: "Publicity/publicities",
      filterHeader: "FilterHeader/filterHeader",
      filterDataHeader: "FilterHeader/filterDataHeader",
    }),
  },
  watch: {
    filterHeader: {
      async handler(newValue) {
        console.log("SLUG", this.slug);
        console.log("DATA FILTER HEADER", this.filterDataHeader);
        if (this.filterDataHeader && this.filterDataHeader.dataByHeader) {
          console.log("NEW VALUE", newValue);
          this.slug = this.filterDataHeader.slug;
          this.target = this.filterDataHeader.target;
          this.city_id = this.filterDataHeader.city_id;
          const arrayFilter =
            newValue && newValue.filter ? newValue.filter : [];
          if (arrayFilter.length > 0) {
            this.selected = arrayFilter.map(
              (item) => `${item.filter_name}-${item.id}`
            );
            this.selected_filters = arrayFilter.map((item) =>
              parseInt(item.id)
            );
            this.filterPageHeader();
          } else {
            this.selected = [];
            this.selected_filters = [];
            this.filterPageHeader();
          }
          // SET DATA HEADER
          const dataHeader = {
            target: null,
            slug: null,
            dataByHeader: false,
            city_id: null,
          };
          this.$store.dispatch("FilterHeader/setFilterDataHeader", dataHeader);
        } else {
          if (this.slug == undefined || this.slug == "" || this.slug == null) {
            this.$store.dispatch("Hearth/searchHearth", {
              slug: this.slugFixed,
            });
          } else {
            this.getHearthBySlug();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["setActiveFilterHearth"]),
    remove(obj) {
      this.selected.splice(this.selected.indexOf(obj), 1);
      const arrayObj = obj.split("-");
      const objFilter = { filter_name: arrayObj[0], id: parseInt(arrayObj[1]) };
      this.toggleItem(objFilter);
    },
    toggleItem(obj) {
      if (this.selected_filters != null || this.selected_filters != "") {
        const index = this.selected_filters.indexOf(obj.id);
        if (index > -1) {
          this.selected_filters.splice(index, 1);
        } else {
          this.selected_filters.push(obj.id);
        }
      } else {
        this.selected_filters.push(obj.id);
      }
    },
    filterPageHeader() {
      this.$store.dispatch("Hearth/searchHearth", {
        slug: this.slug,
        city_id: this.city_id,
        target: this.target,
      });
    },
    filterPage(value) {
      if (!value && !this.selected_filters.length) {
        this.$store.dispatch("Hearth/searchHearth", { slug: this.slugFixed });
        return;
      }
      // console.log('CITY ID', this.city_id);
      this.$store.dispatch("Hearth/searchHearth", {
        filters: this.selected_filters,
        slug: this.slug,
      });
    },
    // get filters by slug
    getHearthBySlug() {
      this.$store
        .dispatch("Hearth/getHearthBySlug", { slug: this.slug })
        .then((response) => {
          if (response && response.data) {
            this.banner = response.data;

            this.filters = response.data.filters;
            // create array with selected filters name
            this.selected = this.filters.map(
              (item) => `${item.filter_name}-${item.id}`
            );
            // create array with selected filters id
            this.selected_filters = this.filters.map((item) =>
              parseInt(item.id)
            );
          }

          const bySlug = true;
          this.filterPage(bySlug);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeText(text) {
      text = text.replace(/[-][0-9]*/g, "");
      return text;
    },
  },
  mounted() {
    this.$store.dispatch("Hearth/getHearthFilters");
    this.$store.dispatch("Publicity/getPublicities", this.$route.name);
  },
};
</script>

<style lang="scss">
.content-hearth {
  margin-top: 170px;

  @media (max-width: 991px) {
    margin-top: 62px;
  }

  &-itens {
    &-banner {
      width: 100%;
      height: 250px;

      @media (max-width: 991px) {
        height: 200px;
      }

      @media (max-width: 576px) {
        height: 150px;
      }

      &-bg {
        width: 100%;
        height: 100%;
        position: relative;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;

        &::after {
          content: "";
          display: block;
          background: rgba(27, 158, 136, 0.8);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
        }

        &-title {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: left;
          font-size: 50px;
          font-family: "Font Bold";
          color: var(--white);
          margin-bottom: 0;
          z-index: 1;

          @media (max-width: 991px) {
            font-size: 30px;
          }
        }
      }
    }

    &-body {
      padding: 50px 0px;

      @media (max-width: 991px) {
        padding: 30px 0px;
      }

      .content-brand {
        @media (max-width: 991px) {
          margin-bottom: 0;

          .btn-return {
            width: 96px;
            height: 45px;
          }
        }
      }

      .content-filter {
        padding: 30px;
        background: #f3f8f4;
        border-radius: 10px;

        @media (max-width: 991px) {
          position: fixed;
          width: 100%;
          max-width: 363px;
          top: 0;
          right: -100%;
          height: 100%;
          padding: 20px;
          overflow-y: auto;
          border-radius: 0;
          z-index: 30;
          transition: 800ms ease-in-out;
        }

        &-btn {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-bottom: 15px;
          margin-bottom: 15px;

          &-close {
            width: 62px;
            height: 53px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding-top: 10px;
            text-decoration: none !important;
            border: 1px solid var(--green-medium);
            border-radius: 10px;
            background: #f3f8f4;
            padding: 0;

            img {
              max-width: 24px;
            }

            p {
              font-size: 10px;
              color: var(--green-medium);
              font-family: "Font Light";
              text-align: center;
              margin-bottom: 0px;
            }
          }
        }

        &-elements {
          &-title {
            text-align: left;
            margin-bottom: 10px;

            p {
              font-size: 22px;
              font-family: "Font Bold";
              color: var(--green-dark);
              margin-bottom: 0;
            }
          }

          &-selected {
            .content-items-selected {
              display: flex;
              align-items: center;
              padding: 10px 0px;
              transition: 500ms;

              &:hover,
              &:focus {
                cursor: pointer;
                opacity: 0.8;
              }

              &-btn {
                all: unset;
                display: flex;
                align-items: center;

                p {
                  font-size: 18px;
                  font-family: "Font Regular";
                  color: var(--green-dark);
                  margin-left: 8px;
                  margin-bottom: 0;
                }
              }
            }
          }

          &-collapse {
            padding: 10px 0px;

            .btn-collapse {
              display: flex;
              align-items: center;
              width: 100%;
              background: 0;
              border: 0;
              padding-bottom: 15px;
              border-bottom: 1px solid #b7b8b8;
              padding-left: 0;
              padding-right: 0;
              text-align: left;

              p {
                font-size: 22px;
                font-family: "Font Bold";
                color: var(--green-medium);
                margin-right: 15px;
                margin-bottom: 0;
              }

              svg {
                transform: rotate(180deg);
                transition: 500ms;
              }

              &[aria-expanded="true"] {
                svg {
                  transform: rotate(0);
                }
              }
            }

            .content-collapse {
              //padding-bottom: 35px;
              &-input-check {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                padding: 15px 0px;

                &:last-child {
                  padding-bottom: 0;
                }

                input[type="checkbox"]:checked:before {
                  content: "";
                  height: 100%;
                  width: 100%;
                  background: url("~@/assets/img/icons/check.png");
                  background-position: center !important;
                  background-repeat: no-repeat !important;
                  background-color: var(--white);
                }

                input[type="checkbox"]:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  background-color: var(--white);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 5px;
                  border: 0;
                }

                input[type="checkbox"] {
                  position: relative;
                  height: 25px;
                  width: 25px;
                  border-radius: 5px;
                  border: 1px solid #707070;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  outline: 0 !important;
                  margin: 0;
                  margin-right: 10px;
                }

                label {
                  font-size: 16px;
                  font-family: "Font Regular";
                  color: #555555;
                  width: calc(100% - 42px);
                  text-align: left;
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .btn-send-filter {
          width: 160px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--green-medium);
          border: 1px solid var(--green-medium);
          border-radius: 10px;
          font-size: 22px;
          font-family: "Font Regular";
          color: var(--white);
          margin: 30px auto 0px auto;
          transition: 500ms;

          &:hover,
          &:focus {
            cursor: pointer;
            background: var(--white);
            color: var(--green-medium);

            svg {
              fill: var(--green-medium);
            }
          }

          @media (max-width: 991px) {
            width: 130px;
            height: 45px;
            font-size: 20px;
            border-radius: 5px;
            margin: 30px auto 0px auto;
          }
        }
      }

      .content-ads {
        padding: 30px;
        background: #f2f2f2;
        border-radius: 10px;
        margin-top: 30px;

        @media (max-width: 991px) {
          margin-top: 0;
          margin-bottom: 30px;
        }

        &-banner {
          margin-bottom: 40px;

          @media (max-width: 991px) {
            margin-bottom: 30px;
          }
        }
      }

      .content-card {
        display: flex;
        position: relative;
        padding: 20px;
        background: var(--white);
        border: 1px solid #e7e7e7;
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 40px;
        transition: 500ms;

        @media screen and (max-width: 991px) {
          border: none;
          box-shadow: none;
          padding: 20px 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 991px) {
          flex-direction: column;
          margin-bottom: 30px;
        }

        &:hover,
        &:focus {
          cursor: pointer;

          // opacity: 0.9;
          .content-card-image-bg {
            transform: scale(1.05);
          }

          .content-card-btns-consult {
            background: var(--green-medium);
            color: var(--white);
          }
        }

        &-image {
          width: 210px;
          overflow: hidden;
          margin-right: 30px;

          @media (max-width: 991px) {
            width: 100%;
            height: 150px;
            margin-right: 0;
            margin-bottom: 20px;
          }

          &-bg {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: cover !important;
            transition: 500ms;

            @media screen and (max-width: 991px) {
              height: 150px;
            }
          }
        }

        &-div {
          width: calc(100% - 240px);

          @media (max-width: 991px) {
            width: 100%;
          }
        }

        &-text {
          position: relative;
          padding-bottom: 20px;
          border-bottom: 1px solid #e7e7e7;
          &-header {
            height: 53px;
            display: flex;
            align-items: center;
            text-align: left;
            margin-bottom: 20px;
            h2 {
              font-size: 22px;
              font-family: "Font Bold";
              color: var(--green-dark);
              width: calc(100% - 83px);
              padding-right: 10px;
              margin-bottom: 0;

              @media (max-width: 991px) {
                font-size: 22px;
                width: 100%;
              }
            }

            &-classification {
              position: absolute;
              right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 83px;
              height: 36px;
              background: #e69c5a;
              border: 1px solid #e69c5a;
              border-radius: 8px;
              transition: 500ms;

              @media (max-width: 991px) {
                top: 3px;
                right: 45px;
              }

              p {
                font-size: 22px;
                font-family: "Font Bold";
                color: var(--white);
                margin-right: 11px;
                margin-bottom: 0;
                transition: 500ms;
              }

              svg {
                fill: var(--white);
                transition: 500ms;

                @media (max-width: 991px) {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }

          &-body {
            text-align: left;

            p {
              font-size: 18px;
              font-family: "Font Regular";
              color: #555555;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin-bottom: 0;
              height: 55px;

              @media (max-width: 991px) {
                font-size: 16px;
                height: 50px;
              }
            }
          }

          &-location {
            width: calc(100% - 45px);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 10px;
            p {
              font-size: 18px;
              font-family: "Font Regular";
              color: #555555;
              margin-left: 5px;
              margin-bottom: 0;

              @media (max-width: 991px) {
                font-size: 16px;
              }
            }
          }
        }

        &-btns {
          position: relative;
          min-height: 85px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          padding-top: 30px;
          border-top: 1px solid #e7e7e7;

          &-tags {
            display: flex;
            gap: 10px;
            width: calc(100% - 50px);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-height: 40px;
            max-height: 40px;

            &-element {
              display: flex;
              text-align: left;
              align-items: center;

              &:last-child {
                margin-bottom: 0;
              }

              svg {
                margin-right: 5px;

                @media (max-width: 991px) {
                  min-width: 18px !important;
                  width: 18px !important;
                  height: 16px !important;
                }
              }

              p {
                font-size: 18px;
                font-family: "Font Regular";
                color: var(--green-medium);
                margin-bottom: 0;

                @media (min-width: 992px) and (max-width: 1199px) {
                  font-size: 18px;
                }
              }
            }
          }

          &-consult {
            width: 160px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--white);
            border: 1px solid var(--green-medium);
            border-radius: 10px;
            font-size: 22px;
            font-family: "Font Regular";
            color: var(--green-medium);
            transition: 500ms;

            @media (max-width: 1199px) {
              font-size: 18px;
              width: 122px;
              height: 45px;
            }
          }
        }
      }
    }
  }

  &-partner {
    margin-bottom: 40px;
    position: relative;

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
}

.active-filter-hearth {
  right: 0 !important;
  opacity: 1 !important;
  pointer-events: initial !important;
}

.form-check {
  display: flex !important;
  padding: 10px 0 !important;
  width: 100% !important;
}

.content-check {
  display: flex;
  align-items: center;
}

.brand-filter {
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    margin-bottom: 45px;

    .btn-filter {
      all: unset;
      display: flex;
      cursor: pointer;
      width: 126px;
      height: 45px;
      border: none;
      border-radius: 10px;
      background: var(--green-medium);
      color: var(--white);
      display: flex !important;
      align-items: center;
      justify-content: center;
      gap: 10px;

      p {
        font-family: "Font Regular";
        font-size: 18px;
        text-transform: none;
        margin-bottom: 0;
        padding: 0;
        color: var(--white);
      }

      img {
        width: 18px;
        height: 15px;
      }
    }
  }
}

.content-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 25px;
  margin-top: 10px;
  &-elements {
    display: flex;
    align-items: center;

    p {
      font-size: 18px;
      font-family: "Font Medium";
      color: var(--green-medium);
      margin-left: 10px;
      margin-bottom: 0;
    }
  }

  &-more {
    position: absolute;
    right: 0;
    bottom: 16px;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    button {
      all: unset;
      cursor: pointer;
    }

    ul {
      border: none;
      background-color: #f3f8f4 !important;
      height: auto;
      transform: translate3d(-235px, 40px, 0px) !important;
      max-height: 245px;
      overflow-y: auto;
    }

    &-tags {
      background-color: #f3f8f4;
      border-radius: 10px !important;
      padding: 15px 20px 10px 20px;
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      min-width: 270px;

      p {
        text-align: left;
        font-family: "Font Medium";
        font-size: 18px;
        color: #6c757d;
      }
    }

    #dropdown-right__BV_toggle_ {
      background: none !important;
      border: none !important;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &-dropdown-item {
        color: none;
        text-decoration: none !important;
        background-color: none !important;
      }
    }
  }
}

.dropdown-item {
  &:active,
  &:hover,
  &:focus {
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.content-btn {
  display: flex;
  justify-content: flex-end;

  .btn-consult {
    width: 160px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    border: 1px solid var(--green-medium);
    border-radius: 10px;
    font-size: 22px;
    font-family: "Font Regular";
    color: var(--green-medium);
    transition: 500ms;

    @media (max-width: 1199px) {
      font-size: 18px;
      width: 122px;
      height: 45px;
    }
  }
}
</style>
