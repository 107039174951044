<template>
  <div class="content-cart-header" :class="{ 'active-cart': activeCart }">
    <div class="content-cart-header-itens">
      <div class="div-content-main">
        <div class="div-content">
          <div class="div-content-header">
            <div class="div-content-title">
              <div class="div-content-title-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="39.375"
                  height="35"
                  viewBox="0 0 39.375 35"
                >
                  <path
                    id="Caminho_2751"
                    data-name="Caminho 2751"
                    d="M37.734,4.375H8.837L8.268,1.338A1.641,1.641,0,0,0,6.656,0H.82A.82.82,0,0,0,0,.82v.547a.82.82,0,0,0,.82.82H6.2l4.78,25.494a4.375,4.375,0,1,0,7.025.756H27.93a4.375,4.375,0,1,0,6.838-.948,1.642,1.642,0,0,0-1.592-1.239H12.939l-.82-4.375H34.772a1.641,1.641,0,0,0,1.606-1.306L39.34,6.35A1.641,1.641,0,0,0,37.734,4.375Z"
                    fill="var( --green-medium)"
                  />
                </svg>
              </div>
              <div class="div-content-title-text">
                <h2>{{ $t("pages.product.cart") }}</h2>
              </div>
            </div>
            <button
              class="div-cart-btn-close d-lg-none"
              @click="setActiveCart(false)"
            >
              <img
                class="img-fluid"
                src="@/assets/img/icons/close.png"
                alt="icon close"
                title="icon close"
              />
            </button>
          </div>
          <div
            class="div-content-items"
            v-for="(item, i) in product.products"
            :key="i"
          >
            <div class="div-content-items-img">
              <img class="img-fluid" :src="item.image" alt="product cart" />
            </div>
            <div class="div-content-items-elements">
              <div class="div-content-items-text">
                <h4>
                  {{ item.name }}
                </h4>
                <div
                  class="div-content-items-text-price"
                  v-if="item.iva_promotional_price > 0"
                >
                  <p>{{ item.iva_price | money }}</p>
                  <h3>{{ item.iva_promotional_price | money }}</h3>
                </div>
                <div class="div-content-items-text-price" v-else>
                  <h3>{{ item.iva_price | money }}</h3>
                </div>
              </div>
              <div class="div-content-items-quantity">
                <div class="number">
                  <button class="minus" @click="decreaseQuantity(item)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.698"
                      height="5.536"
                      viewBox="0 0 22.698 5.536"
                    >
                      <path
                        d="M1.584-9.312v5.536h22.7V-9.312Z"
                        transform="translate(-1.584 9.312)"
                        fill="var( --green-medium)"
                      />
                    </svg>
                  </button>
                  <input type="text" v-model="item.pivot.quantity" />
                  <button class="plus" @click="increaseQuantity(item)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.704"
                      height="19.704"
                      viewBox="0 0 19.704 19.704"
                    >
                      <path
                        d="M8.768-12.912v7.594H1.224V-.8H8.768V6.792h4.566V-.8h7.594V-5.318H13.335v-7.594Z"
                        transform="translate(-1.224 12.912)"
                        fill="var( --green-medium)"
                      />
                    </svg>
                  </button>
                </div>
                <button class="btn-remove" @click="onRemove(item)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.762"
                    height="20.299"
                    viewBox="0 0 17.762 20.299"
                  >
                    <path
                      id="Caminho_2752"
                      data-name="Caminho 2752"
                      d="M17.127,1.269H12.37L12,.527A.952.952,0,0,0,11.145,0H6.613a.94.94,0,0,0-.848.527l-.373.741H.634A.634.634,0,0,0,0,1.9V3.172a.634.634,0,0,0,.634.634H17.127a.634.634,0,0,0,.634-.634V1.9A.634.634,0,0,0,17.127,1.269ZM2.109,18.515a1.9,1.9,0,0,0,1.9,1.784h9.745a1.9,1.9,0,0,0,1.9-1.784l.841-13.44H1.269Z"
                      transform="translate(0 0)"
                      fill="#b7b8b8"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="content-total">
          <div class="content-total-value">
            <div class="content-total-value-text">
              <p>{{ $t("pages.product.transport") }}:</p>
            </div>
            <div class="content-total-value-price">
              <h3>{{ product.total_shipping_price | money }}</h3>
            </div>
          </div>
          <div class="content-total-value">
            <div class="content-total-value-text">
              <p>{{ $t("pages.product.products") }}:</p>
            </div>
            <div class="content-total-value-price">
              <h3>{{ product.total_products_price | money }}</h3>
            </div>
          </div>
          <div class="content-total-value">
            <div class="content-total-value-text">
              <p>{{ $t("pages.product.total") }}</p>
            </div>
            <div class="content-total-value-price">
              <h3>{{ product.total_price | money }}</h3>
            </div>
          </div>

          <div class="content-total-buttons">
            <button
              class="content-total-buttons-keep"
              @click="setActiveCart(false)"
            >
              <p>{{ $t("pages.product.total") }}</p>
            </button>
            <button
              @click="goToCart()"
              class="content-total-buttons-finalize"
              :class="{ 'btn-disabled': cartIsEmpty() }"
            >
              <p>{{ $t("pages.product.purchase") }}</p>
            </button>
          </div>
        </div>
      </div>
      <button
        class="div-cart-btn-close d-none d-lg-flex"
        @click="setActiveCart(false)"
      >
        <img
          class="img-fluid"
          src="@/assets/img/icons/close.png"
          alt="icon close"
          title="icon close"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      access_token: "",
      visitor_token: "",
    };
  },
  computed: {
    ...mapGetters(["activeCart"]),
    ...mapGetters("User", ["userIsLogged"]),
    ...mapGetters({
      product: "Cart/cart",
    }),
    ...mapActions(["Cart/getCart"]),
    ...mapActions(["Cart/updateCart"]),
    ...mapActions(["Cart/removeCart"]),
  },
  methods: {
    ...mapActions({
      setActiveCart: "setActiveCart",
    }),
    cartIsEmpty() {
      return (
        !this.product ||
        !this.product.products ||
        this.product.products.length == 0
      );
    },
    goToCart() {
      if (this.cartIsEmpty()) {
        return;
      }

      if (this.userIsLogged) {
        this.setActiveCart(false);
        this.$router.push({ name: "Cart" });
      } else {
        this.setActiveCart(false);
        this.$fire({
          title: "Ops!",
          text: "Para finalizar a compra, faça o login ou cadastre-se.",
          type: "info",
          showCancelButton: true,
          confirmButtonText: "Login",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath },
            });
          }
        });
      }
    },
    onRemove(product, cart_id) {
      this.$store.dispatch("Cart/removeCart", {
        product: product.uuid,
        access_token: this.access_token,
      });
    },

    getProducCart() {
      this.$store.dispatch("Cart/getCart", { access_token: this.access_token });
    },

    onUpdate(item, newQuantity) {
      axios
        .post("/api/v1/ecommerce/update", {
          access_token: this.access_token,
          product: item.uuid,
          quantity: newQuantity,
        })
        .then((response) => {
          this.$store.dispatch("Cart/getCart", {
            access_token: this.access_token,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    increaseQuantity(item) {
      const newQuantity = item.pivot.quantity + 1;
      this.onUpdate(item, newQuantity);
    },
    decreaseQuantity(item) {
      if (item.pivot.quantity > 1) {
        const newQuantity = item.pivot.quantity - 1;
        item.pivot.quantity = newQuantity;
        this.onUpdate(item, newQuantity);
      } else {
        this.onRemove(item);
      }
    },
  },

  async mounted() {
    this.access_token =
      localStorage.getItem("token") ||
      localStorage.getItem("visitor_token") ||
      "";
    await this.$store.dispatch("Cart/getCart", {
      access_token: this.access_token,
    });
  },
};
</script>

<style lang="scss">
.content-cart-header {
  position: fixed;
  width: 100%;
  max-width: 750px;
  bottom: 0;
  right: 0;
  background: transparent;
  display: flex !important;
  z-index: 10001;
  height: 100%;
  transform: translateX(+100%);
  transition: 800ms ease-in-out;

  @media (max-width: 991px) {
    width: 90%;
  }

  @media (max-width: 359px) {
    width: 100%;
  }

  &-itens {
    height: 100%;
    width: 100%;
    padding: 75px 30px 100px 100px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--white);
    overflow-y: auto;

    @media (max-width: 991px) {
      padding: 25px 20px 45px 20px;
    }

    .div-cart {
      padding: 3rem 1rem;
      height: 100%;
      width: 100px;

      @media screen and (max-width: 576px) {
        width: 70px;
        padding: 3rem 0.5rem;
      }

      &-btn-close {
        width: 62px;
        height: 53px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        text-decoration: none !important;
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        background: var(--white);
        padding: 0;

        @media screen and (max-width: 991px) {
          width: 47px;
          height: 45px;
        }

        img {
          max-width: 35px;

          @media (max-width: 991px) {
            max-width: 24px;
          }
        }

        p {
          font-size: 10px;
          color: var(--green-medium);
          font-family: "Font Light";
          text-align: center;
          margin-bottom: 0px;
        }
      }
    }

    .div-content-main {
      height: 100%;
      width: calc(100% - 62px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    .div-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-header {
        margin-bottom: 20px;
        padding-top: 10px;

        @media (max-width: 991px) {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0;
          padding-top: 0;
        }
      }

      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media (max-width: 991px) {
          width: calc(100% - 50px);
        }

        &-icon {
          margin-right: 30px;

          @media screen and (max-width: 991px) {
            margin-right: 10px;

            svg {
              max-width: 24px;
            }
          }
        }

        &-text {
          padding: 0;
          margin: 0;

          h2 {
            font-size: 30px;
            font-family: "Font Regular";
            color: var(--green-dark);
            text-align: left;
            margin-bottom: 0;
            padding: 0;

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }

        h4 {
          font-size: 1.125rem;
          font-family: "Font Regular", sans-serif;
          color: #112d23;
          margin-bottom: 0;
          padding: 0;
        }
      }

      &-items {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #b7b8b8;
        padding: 25px 0px;

        &:last-child {
          border-bottom: 0;
        }

        &-img {
          max-width: 145px;
          margin-right: 35px;

          @media screen and (max-width: 991px) {
            margin-right: 10px;

            img {
              max-width: 90px;
            }
          }
        }

        &-elements {
          width: calc(100% - 190px);

          @media screen and (max-width: 991px) {
            width: calc(100% - 100px);
          }
        }

        &-text {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;

          h4 {
            font-family: "Font Regular";
            font-size: 22px;
            color: var(--green-dark);

            @media screen and (max-width: 991px) {
              font-size: 18px;
            }
          }

          &-price {
            display: flex;
            align-items: center;

            p {
              font-size: 18px;
              font-family: "Font Regular";
              color: var(--gray);
              margin-bottom: 0;
              padding: 0;
              text-decoration: line-through;
              margin-right: 30px;
              line-height: 1;
            }

            h3 {
              font-size: 22px;
              font-family: "Font Bold";
              color: var(--green-medium);
              margin-bottom: 0;
              padding: 0;
              line-height: 1;

              @media (max-width: 991px) {
                font-size: 18px;
              }
            }
          }
        }

        &-quantity {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 30px;

          @media (max-width: 576px) {
            padding-right: 0;
          }

          .number {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 123px;
            height: 45px;
            background: var(--white);
            border: 1px solid #d8d8d8;
            border-radius: 10px;
            padding: 0px 15px;

            .minus,
            .plus {
              border: 0;
              background: 0;
              padding: 0;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                max-width: 15px;
              }
            }

            input {
              height: 100%;
              width: 35px;
              text-align: center;
              font-size: 25px;
              font-family: "Font Regular";
              color: var(--green-dark);
              background: var(--white);
              border: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
              margin: 0px 10px;
              outline: none;
              pointer-events: none;
            }
          }

          .btn-remove {
            display: block;
            background: 0;
            border: 0;
            transition: 500ms;

            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }
    }

    .content-total {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      border-top: 1px solid #b7b8b8;
      padding-top: 50px;

      @media (max-width: 991px) {
        padding: 0;
      }

      &-value {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 15px 0px;
        align-items: center;
        justify-content: flex-end;

        &-text {
          margin-right: 30px;

          p {
            font-size: 22px;
            font-family: "Font Regular";
            color: #878787;
            margin-bottom: 0;
            padding: 0;
            text-align: right;
          }
        }

        &-price {
          h3 {
            font-size: 32px;
            font-family: "Font Bold";
            color: var(--green-medium);
            margin-bottom: 0;
            text-align: right;
          }
        }
      }

      &-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        @media screen and (max-width: 991px) {
          flex-direction: column;
          align-items: flex-end;
        }

        &-keep {
          width: 50%;
          max-width: 266px;
          height: 60px;
          background-color: var(--white);
          border: 1px solid var(--green-medium);
          border-radius: 10px;
          margin-right: 25px;
          transition: 500ms;

          @media (max-width: 991px) {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 25px;
          }

          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.6;
          }

          p {
            text-align: center;
            color: var(--green-medium);
            font-size: 22px;
            font-family: "Font Regular";
            margin-bottom: 0;
          }
        }

        &-finalize {
          width: 50%;
          max-width: 266px;
          height: 60px;
          background-color: var(--green-medium);
          border: 1px solid var(--green-medium);
          border-radius: 10px;
          transition: 500ms;

          &.btn-disabled {
            background-color: var(--gray) !important;
            border: 1px solid var(--gray);
            cursor: not-allowed !important;
          }

          @media (max-width: 991px) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 30px;
          }

          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }

          p {
            text-align: center;
            color: var(--white);
            font-size: 22px;
            font-family: "Font Regular";
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.active-cart {
  transform: translateX(0) !important;
  pointer-events: initial !important;
}
</style>
