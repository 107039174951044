<template>
  <form class="content-form-contact" @submit.prevent="formSubmit()">
    <div class="content-form-contact-itens">
      <div class="content-input">
        <div class="content-input-header">
          <h2>{{ $t('pages.register.yourData') }}</h2>
        </div>
        <div class="content-input-body">
          <div class="row">
            <div class="col-lg-6">
              <input type="text" name="name" v-model="formContact.name" value="" :placeholder="$t('pages.form.name')"
                required />
            </div>
            <div class="col-lg-6">
              <input type="text" name="nickname" v-model="formContact.nickname" value=""
                :placeholder="$t('pages.form.surname')" required />
            </div>
            <template v-if="formContact.origin == 'newsletter'">
              <div class="col-lg-6">
                <input type="email" name="email" v-model="formContact.email" value="" placeholder="Email" required />
              </div>
            </template>
            <template v-else>
              <div class="col-lg-6">
                <input type="text" name="phone" v-model="formContact.phone" value=""
                  :placeholder="$t('pages.form.phone')" />
              </div>
              <div class="col-lg-6">
                <input type="email" name="email" v-model="formContact.email" value="" placeholder="Email" required />
              </div>
            </template>
          </div>
        </div>
      </div>
      <template v-if="formContact.origin !== 'newsletter'">
        <div class="content-input">
          <div class="content-input-header">
            <h2>{{ $t('pages.myMessages.message') }}</h2>
          </div>
          <div class="content-input-body">
            <div class="row">
              <div class="col-lg-12">
                <textarea name="mensagem" v-model="formContact.mensagem"
                  :placeholder="$t('pages.form.messageText')"></textarea>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="formContact.origin === 'newsletter'">
        <div class="content-newsletter-form-categories">
          <div class="content-newsletter-form-categories-header">
            <h2>{{ $t('pages.categories.title') }}</h2>
          </div>
          <div class="content-newsletter-form-categories-body">
            <div class="row">
              <div class="col-12 col-lg-4" v-for="category in categories" :key="category.id">
                <div class="content-input-check-categories">
                  <input type="checkbox" v-model="category.selected" @change="
                    toggleCategories(category, $event)
                    " :id="category.id" :name="category.name" />
                  <label :for="category.id">{{
                    category.name
                    }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="content-input-check">
        <input type="checkbox" name="terms" required />
        <label>{{ $t('pages.contact.readterm') }}
          <router-link tag="a" :to="{
            name: 'Politica',
            params: {
              slug: 'termos-e-condicoes-de-vendautilizacao',
            },
          }">
            {{ $t('pages.contact.terms') }}</router-link>
          {{ $t('pages.contact.andThe') }}
          <router-link tag="a" :to="{
            name: 'Politica',
            params: { slug: 'politica-de-privacidade' },
          }">{{ $t('pages.contact.privacyPolicy') }}</router-link></label>
      </div>
      <div class="content-recaptcha">
        <vue-recaptcha ref="recaptcha" sitekey="6Ld-tb8pAAAAAAzuDktnRGysHO5ixbqA5F-jqwhl" />
      </div>
      <div class="content-btns">
        <button class="btn-confirm">
          {{ $t('pages.loyalty.btnConfirm') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      formContact: {
        name: "",
        nickname: "",
        phone: "",
        email: "",
        mensagem: "",
        category: "",
        custom: [],
        origin: this.origin,
        access_token: localStorage.getItem("token"),
        slug: this.slugParceiro,
      },
    };
  },
  props: ["origin", "categories", "slugParceiro"],
  components: {
    VueRecaptcha,
  },
  computed: {
    ...mapGetters("User", ["userIsLogged"]),
  },
  methods: {
    formSubmit() {
      if (!this.userIsLogged && this.origin != 'contato') {
        return this.checkIsLogged();
      }

      this.sendContact();
    },
    checkIsLogged() {
      this.setStorage();
      this.$fire({
        type: "info",
        title: "Atenção",
        text: "Para submeter o formulario, é necessário estar logado",
        timer: 3000,
        showConfirmButton: true,
      });
      this.$router.push({
        name: "Login",
        params: {
          page: this.$route.path,
        },
      });
    },
    sendContact() {
      this.$store.dispatch("setIsLoading", true);
      axios
        .post("/api/v1/setlead", this.formContact)
        .then((response) => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.response.success) {
            this.$fire({
              type: "success",
              title: "Sucesso",
              text: response.data.response.message.text,
              timer: 3000,
            });

            this.clearForm();
            sessionStorage.removeItem("formContact");
          } else {
            this.$fire({
              type: "error",
              title: "Erro",
              text: response.data.response.message.text,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("setIsLoading", false);
          this.$fire({
            type: "error",
            title: "Erro",
            text: error.response.data.response.message.text,
            timer: 5000,
          });
        });
    },
    setStorage() {
      sessionStorage.setItem(
        "formContact",
        JSON.stringify(this.formContact)
      );
    },
    getCategory() {
      axios
        .get("/api/v1/company/partnertypes")
        .then((response) => {
          this.category = response.data.response;
        })
        .catch((error) => {
          this.$store.dispatch("setAlert", {
            type: "error",
            title: "Erro",
            message: error.response.data.message,
          });
        });
    },
    toggleCategories(category, event) {
      // console.log('EVENT', event);
      if (!event.target.checked) {
        const index = this.formContact.custom.indexOf(category);
        if (index > -1) {
          this.formContact.custom.splice(index, 1);
        }
        // console.log('CUSTOM', this.formContact.custom);
        return;
      }
      this.formContact.custom.push(category);
      // console.log('CUSTOM', this.formContact.custom);
    },
    clearForm() {
      for (let i in this.formContact) {
        if (i === "custom") {
          this.formContact[i] = [];
        } else if (i !== "origin") {
          this.formContact[i] = "";
        }
      }
    },
    clearSelectedCategories() {
      this.$router.go(0);
    },
  },
  mounted() {
    this.access_token = this.$store.state.token;
    if (sessionStorage.getItem("formContact")) {
      let formContact = JSON.parse(
        sessionStorage.getItem("formContact") || "{}"
      );
      this.formContact = formContact;

      // FORMAT DATE YYYY-MM-DD
      if (this.formContact.custom.data_checkin) {
        this.data_checkin = this.formContact.custom.data_checkin
          .split("/")
          .reverse()
          .join("-");
      }
      if (this.formContact.custom.data_checkout) {
        this.data_checkout = this.formContact.custom.data_checkout
          .split("/")
          .reverse()
          .join("-");
      }
    }
  },
};
</script>

<style lang="scss">
.content-form-contact {
  &-itens {
    .content-input {
      &-header {
        text-align: left;

        h2 {
          font-size: 28px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 30px;

          @media (max-width: 991px) {
            font-size: 18px;
          }
        }
      }

      &-body {
        input {
          width: 100%;
          height: 70px;
          background: var(--white);
          border: 1px solid #bebebe;
          border-radius: 10px;
          font-size: 25px;
          font-family: "Font Regular";
          padding-left: 25px;
          outline: none;
          margin-bottom: 40px;

          @media (max-width: 991px) {
            font-size: 18px;
            margin-bottom: 20px;
          }

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #b7b8b8;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #b7b8b8;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #b7b8b8;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: #b7b8b8;
          }
        }

        textarea {
          width: 100%;
          min-height: 270px;
          background: var(--white);
          border: 1px solid #bebebe;
          border-radius: 10px;
          font-size: 25px;
          font-family: "Font Regular";
          padding-top: 20px;
          padding-left: 25px;
          outline: none;
          resize: none;
          margin-bottom: 40px;

          @media (max-width: 991px) {
            font-size: 18px;
            min-height: 200px;
            margin-bottom: 20px;
          }

          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #b7b8b8;
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #b7b8b8;
          }

          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #b7b8b8;
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: #b7b8b8;
          }
        }
      }
    }

    .content-newsletter-form-categories {
      padding-top: 10px;
      padding-bottom: 90px;

      @media (max-width: 991px) {
        padding-top: 15px;
        padding-bottom: 40px;
      }

      &-header {
        text-align: left;
        margin-bottom: 15px;

        h2 {
          font-size: 28px;
          font-family: "Font Bold";
          color: var(--green-medium);
          margin-bottom: 0;

          @media (max-width: 991px) {
            font-size: 18px;
          }
        }
      }

      &-body {
        .content-input-check-categories {
          display: flex;
          align-items: center;
          padding: 20px 0px;

          input[type="checkbox"]:checked:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20px;
            height: 20px;
            background: #104f2d;
            border-radius: 100%;
          }

          input[type="checkbox"]:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: var(--white);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: 0;
          }

          input[type="checkbox"] {
            position: relative;
            height: 45px;
            width: 45px;
            border-radius: 10px;
            border: 1px solid #bebebe;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0 !important;
            margin: 0;
            margin-right: 20px;
            cursor: pointer;
          }

          label {
            font-size: 20px;
            font-family: "Font Regular";
            color: var(--green-dark);
            width: calc(100% - 65px);
            text-align: left;
            margin-bottom: 0;
            cursor: pointer;

            @media (max-width: 991px) {
              font-size: 18px;
            }
          }
        }
      }
    }

    .content-recaptcha {
      margin-bottom: 70px;

      @media (max-width: 991px) {
        margin-bottom: 50px;
      }
    }

    .content-btns {
      display: flex;
      flex-direction: column;
      margin-top: 70px;

      @media (max-width: 991px) {
        margin-top: 50px;
      }

      .btn-confirm {
        width: 246px;
        height: 72px;
        background: var(--green-medium);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.16);
        font-size: 25px;
        font-family: "Font Regular";
        color: var(--white);
        margin-bottom: 70px;
        transition: 500ms;

        @media (max-width: 991px) {
          margin-bottom: 50px;
        }

        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--white);
          color: var(--green-medium);
        }
      }

      .btn-return {
        width: 114px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white);
        border: 1px solid var(--green-medium);
        border-radius: 10px;
        font-size: 22px;
        font-family: "Font Regular";
        color: var(--green-medium);
        transition: 500ms;

        &:hover,
        &:focus {
          cursor: pointer;
          background: var(--green-medium);
          color: var(--white);
        }

        @media (max-width: 991px) {
          width: 80px;
          height: 35px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>
