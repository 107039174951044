<template>
  <router-link class="col others-services-items-element" :to="{ name: 'Partners', params: { slug: obj.slug }}">
    <div class="others-services-items-element-img" :style="{ backgroundImage: `url('${ obj.banner }')` }" id="card">
			<div class="others-services-items-element-text" :class="$style.buttonOtherServices" :style="{ backgroundColor: `${ obj.color }35`}" >
				<p>{{ obj.title }}</p>
				<h2 :style="{ color: `${ obj.color }`}">{{ obj.subtitle }}</h2>
			</div>
    </div>
  </router-link>
	
</template>

<script>
export default {
  props: ['obj'],
  style({ className }) {
    return [
      className('buttonOtherServices', {
				backgroundColor: `${ this.obj.color }`,
        '&:hover': {
          cursor: 'pointer',
          background: '#ffffff2d!important',
          border: `1px solid ${ this.obj.color }`
        }
      })
    ];
  }
}
</script>