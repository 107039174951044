import {protectedPage, hiddenPageLogin} from '@/utils';

export default [
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Login/Login.vue'),
        beforeEnter(to, from, next) {
            hiddenPageLogin(to, from, next);
        }
    },
    {
        path: "/register",
        name: "Register",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Register/Register.vue'),
    },
    {
        path: "/newpassword",
        name: "NewPassword",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Password/NewPassword.vue'),
    },
    {
        path: "/recupery-password",
        name: "RecuperyPassword",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Password/RecuperyPassword.vue'),
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Password/ResetPassword.vue'),
    },
    {
        path: "/my-account",
        name: "MyAccount",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/MyAccount.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/your-information",
        name: "YourInformation",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/YourInformation.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/orders",
        name: "Orders",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/Orders.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/my-messages",
        name: "MyMessages",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/MyMessages.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/activate-account",
        name: "ActivateAccount",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/Activate.vue'),
    },
    {
        path: "/address-management",
        name: "AddressManagement",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/AddressManagement.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/billing-data",
        name: "BillingData",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/BillingData.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/rgpd",
        name: "Rgpd",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/Rgpd.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/wishlist",
        name: "Wishlist",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/Wishlist.vue'),
        beforeEnter(to, from, next) {
            protectedPage(to, from, next);
        },
    },
    {
        path: "/forget",
        name: "Forget",
        component: () => import(/* webpackChunkName: "Register" */'@/views/User/views/Account/Forget.vue'),
    },
];
