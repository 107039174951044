import axios from 'axios';

export default {
  data() {
    return {
      inputSearchCategory: "",
      isInputSearchCategory: false,
      categories: {},
      inputSearchDemand: "",
      isInputSearchDemand: false,
      demands: {
        partners: {
          id: 1,
          name: "Parceiros",
          type: 'partner',
          types: {},
        },
        services: {
          id: 2,
          name: "Serviços",
          type: 'services',
          types: {},
        },
      },
      inputSearchCounty: "",
      isInputSearchCounty: false,
      countys: {},
      objFilter: {
        search: {
          category: null,
          target: {
            slug: null,
            type: null,
            id: null,
          },
          concelho: null
        },
      },
    };
  },
  methods: {
    sendFilter() {
      this.$store.dispatch('FilterHeader/setFilterHeader', this.objFilter).then((response) => {
        // redirect to search page
        this.$store.commit('FilterHeader/setFilterHeader', response.data);
        //SET DATA HEADER
        const city_id = this.objFilter.search.concelho ?? null;
        const target = this.objFilter.search.target ?? null;
        const dataHeader = { slug: this.objFilter.search.category, dataByHeader: true, city_id: city_id, target: target };
        this.$store.dispatch('FilterHeader/setFilterDataHeader', dataHeader);

        console.log('FILTER DATA', response.data.name);
        //REDIRECT TO PAGE
        if (response.data.name == 'Partners') {
          this.$router.push({ name: response.data.name, params: { slug: this.objFilter.search.category } }).catch(() => { });
          this.$store.dispatch('Partners/getPartnersFilters', { slug: this.objFilter.search.category })
        } else {
          this.$router.push({ name: response.data.name }).catch(() => { })
        }


        this.$store.dispatch('setIsLoading', false);
      }).catch((error) => {
        this.$store.dispatch('setAlert', { type: 'error', title: 'Erro', message: error });
        this.$store.dispatch('setIsLoading', false);
      });
    },
    getContent() {
      axios.get("/api/v1/header/content").then((response) => {
        this.categories = response.data.categorias;
        // console.log('DATA FIILTER', this.categories);
      }).catch((error) => {
        console.log(error);
      });
    },
    toType(obj) {
      return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
    },
    filterObject(obj) {
      this.objFilter.search.category = obj.slug;
      this.demands.partners.types = obj.parceiros;
      this.demands.services.types = obj.servicos;
      this.objFilter.search.target.id = obj.id;
    },
    filterObjectTwo(obj, type) {
      // console.log(obj, type);
      this.objFilter.search.target.type = type;
      this.objFilter.search.target.slug = obj.slug;
      this.objFilter.search.target.id = obj.id;

      if (this.toType(obj.concelho) === 'object') {
        let arrayCountys = [];
        arrayCountys.push(obj.concelho);
        this.countys = arrayCountys;
      } else {
        this.countys = obj.concelho;
      }
      // console.log('COUNTYS', this.countys);
    },
    filterObjectThree(obj) {
      // console.log('OBJ CONCELHO',  obj);
      this.objFilter.search.concelho = obj.autoid;
    },
    inputCategories(event) {
      this.inputSearchCategory = event.target.value.toLowerCase();
      if (!this.inputSearchCategory) {
        this.inputSearchDemand = '';
        this.inputSearchCounty = '';
        this.isInputSearchCategory = false;
        this.isInputSearchDemand = false;
        this.isInputSearchCounty = false;
        this.clearObjFilter();
      }
    },
    inputDemand(event) {
      this.inputSearchDemand = event.target.value.toLowerCase();
      if (!this.inputSearchDemand) {
        this.inputSearchCounty = '';
        this.isInputSearchDemand = false;
        this.isInputSearchCounty = false;
        this.objFilter.search.target.slug = null;
        this.objFilter.search.target.type = null;
        this.objFilter.search.concelho = null;
      }
    },
    inputCounty(event) {
      this.inputSearchCounty = event.target.value.toLowerCase();
      if (!this.inputSearchCounty) {
        this.isInputSearchCounty = false;
        this.objFilter.search.concelho = null;
      }
    },
    toggleClick(value) {
      let obj = Object.keys(value)[0];
      if (obj === 'category') {
        this.setIsCategory(!this.isCategory);
        this.setIsDemand(false);
        this.setIsCounty(false);
        this.$refs.inputCategories.focus()
      }
      if (obj === 'demand') {
        this.setIsDemand(!this.isDemand);
        this.setIsCategory(false);
        this.setIsCounty(false);
        this.$refs.inputDemand.focus()
      }
      if (obj === 'county') {
        this.setIsCounty(!this.isCounty);
        this.setIsCategory(false);
        this.setIsDemand(false);
        this.$refs.inputCounty.focus()
      }
    },
    clearObjFilter() {
      this.objFilter = {
        search: {
          category: null,
          target: {
            slug: null,
            type: null,
          },
          concelho: null
        }
      }
    }
  },
  mounted() {
    this.getContent();
  },
}

