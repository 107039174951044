export default [
  {
    path: "/partners/:slug/:slugb?",
    name: "Partners",
    component: () => import(/* webpackChunkName: "MyAccount" */ "@/views/Businesspartners/views/Partners.vue"),
  },
  {
    path: "/partner-landing-page/:slug",
    name: "PartnerLandingPage",
    component: () => import(/* webpackChunkName: "MyAccount" */ "@/views/Businesspartners/views/PartnerLandingPage.vue"),
  },
  {
    path: "/partnersother/:token?",
    name: "PartnersOther",
    component: () => import(/* webpackChunkName: "MyAccount" */ "@/views/Businesspartners/views/PartnersOther.vue"),
  },
]
