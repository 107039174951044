<template>
    <div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', indexFaq, indexFaqCategory)">
        <div v-html="faq.question" class="question"></div>
        <div v-html="faq.answer" class="answer"></div>
    </div>
</template>

<script>
export default {
    name: 'FAQ',
    props: ['faq', 'indexFaq', 'indexFaqCategory', 'open']
}
</script>
