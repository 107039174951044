export default {
    setUserLogged(state, {token, user}) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        state.token = token;
        state.user = user;
    },
    setUserData(state, payload){
        localStorage.setItem('user', JSON.stringify(payload));
        state.user = payload;
    },
    setUserLoggedOut(state) {
        state.token = null
        state.user = {}
        localStorage.removeItem('token'); 
        localStorage.removeItem('user');
    },
    setUserFavorite(state, value) {
        state.userFavorite.access_token = value.access_token;
        state.userFavorite.link = value.link;
    }
};
