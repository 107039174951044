<template>
  <div>
    <div class="content-video" v-if="publicity.type === 'video' && videoUrl">
      <iframe :src="videoUrl" title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="content-image" v-else-if="publicity.type === 'image'">
      <img :src="publicity.image" alt="Imagem da publicidade" class="img-fluid">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    publicity: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    videoUrl() {
      if (this.publicity.video) {
        const videoId = this.publicity.video.split('youtu.be/')[1] || this.publicity.video.split('watch?v=')[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
      return null;
    }
  },
}
</script>

<style lang="scss">
.content-video {
  width: 100%;
  height: 647px;
  margin-bottom: 0;

  @media screen and (max-width: 772px) {
    height: 500px;
  }

  @media screen and (max-width: 576px) {
    height: 410px;
  }

  iframe {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 576px) {
      height: 400px;
    }
  }
}

.content-image img {
  width: 100%;
  height: auto;
}
</style>
