import Hearth from "@/views/Hearth/views/Hearth.vue";
import HearthIntern from "@/views/Hearth/views/HearthIntern.vue";

export default [
	{
		path: "/hearth/:slug?",
		name: "Hearth",
		component: Hearth,
	},
	{
		path: "/hearth-intern/:slug",
		name: "HearthIntern",
		component: HearthIntern,
	},
]