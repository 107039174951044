<template>
    <button tag="a" @click="hasHistory()
        ? $router.go(-1)
        : $router.push('/')" class="btn-return">
        {{ $t('pages.btn.btnBack') }}
    </button>
</template>
<script>
export default {
    methods: {
        hasHistory() {
            return window.history.length > 1;
        }
    }
}
</script>
