import axios from "axios";
import { baseApiUrl } from "@/global.js";

export default {
  namespaced: true,
  state: {
    cart: [],
    address: {
      delivery:{
        code: 0
      },
      billing:{
        code: 0
      }
    }
  },
  getters: {
    cart(state) {
      return state.cart;
    },
    deliveryAddress(state){
      return state.address.delivery;
    },
    billingAddress(state){
      return state.address.billing;
    }
  },
  mutations: {
    setCart(state, cart) {
      state.cart = cart;
    },
    setDeliveryAddressCode(state, code) {
      state.address.delivery.code = code;
    },
    setBillingAddressCode(state, code) {
      console.log('act',code);
      state.address.billing.code = code;
    },
  },
  actions: {
    setBillingAddressCode({ commit }, code) {
      commit("setBillingAddressCode", code);
    },
    setDeliveryAddressCode({ commit }, code) {
      commit("setDeliveryAddressCode", code);
    },
    async setCart({ commit, dispatch }, product, access_token, quantity) {
      dispatch("setIsLoading", true, { root: true });
      axios.post(`${baseApiUrl}/ecommerce/cart`, product, access_token, quantity).then(resp => {
        if (resp && resp.data) {
          commit("setCart", resp.data);
          dispatch("setIsLoading", false, { root: true });
          dispatch('setAlert', {
            type: 'success', title: 'Success', message: 'Produto adicionado com sucesso!', customClass: {
              popup: 'my-swal-style' // sua classe CSS personalizada
            }
          }, { root: true });
        }
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async getCart({ commit, dispatch }, access_token) {
      dispatch("setIsLoading", true, { root: true });
      axios.post(`${baseApiUrl}/ecommerce/getcart`, access_token).then(resp => {
        commit("setCart", resp.data);
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async getCartByPartner({ commit, dispatch }, access_token) {
      dispatch("setIsLoading", true, { root: true });
      return axios.post(`${baseApiUrl}/ecommerce/getcartbypartner`, access_token).then(resp => {
        commit("setCart", resp.data);
        dispatch("setIsLoading", false, { root: true });
        return resp.data;
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },

    async updateCart({ commit, dispatch }, product, access_token, quantity, page) {
      dispatch("setIsLoading", true, { root: true });
      axios.post(`${baseApiUrl}/ecommerce/update`, product, access_token, quantity, page).then(resp => {
        commit("setCart", resp.data);
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async removeCart({ commit, dispatch }, product, access_token, page) {
      dispatch("setIsLoading", true, { root: true });
      axios.post(`${baseApiUrl}/ecommerce/remove`, product, access_token, page).then(resp => {
        if (resp.data) {
          commit("setCart", resp.data);
          dispatch("setIsLoading", false, { root: true });
        }
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },
    async clearCart({ commit, dispatch }, product, access_token, page) {
      dispatch("setIsLoading", true, { root: true });
      axios.post(`${baseApiUrl}/ecommerce/remove`, product, access_token, page).then(resp => {
        if (resp.data) {
          commit("setCart", resp.data);
          dispatch("setIsLoading", false, { root: true });
        }
        dispatch("setIsLoading", false, { root: true });
      }).catch(error => {
        dispatch("setIsLoading", false, { root: true });
        dispatch('setAlert', { type: 'error', title: 'Erro', message: error }, { root: true });
      });
    },

    async updateToken({ dispatch }, access_token, visitor_token) {
      dispatch("setIsLoading", true, { root: true });
      return axios.post(`${baseApiUrl}/ecommerce/updatetoken`, access_token, visitor_token)
    }
  },
}
